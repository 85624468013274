import { TTextLocalized, TLocale } from '@northladder/i18n';
import { getTextFromI18n } from '@northladder/utilities';

interface SiteMetaDataProps {
  appName: TTextLocalized;
  description: TTextLocalized;
  locale: TLocale;
  title: TTextLocalized;
}

/**
 * -----------------------------------------------------------------------------
 * This renders `SEO` friendly metadata and tags for `PWA` enhancements within
 * the head of the document.
 *
 * TODO:
 * - Expose props that will allow for other pages/components to customize this content.
 * - Update the Meta description and images with better marketable content.
 *
 * Reference Reads::
 * - [The Open Graph protocol](https://ogp.me/)
 * - [Open Graph Meta Tags: Everything You Need to Know](https://ahrefs.com/blog/open-graph-meta-tags/)
 * - [12 Essential Open Graph Meta Tags for Facebook and Twitter](https://neilpatel.com/blog/open-graph-meta-tags/)
 */
export function SiteMetaData({
  appName,
  description,
  locale,
  title,
}: SiteMetaDataProps) {
  const tTitle = getTextFromI18n({
    locale,
    text: title,
  });
  const tDescription = getTextFromI18n({
    locale,
    text: description,
  });
  const tAppName = getTextFromI18n({
    locale,
    text: appName,
  });

  return (
    <>
      <meta charSet="utf-8" />
      <meta content="text/html; charset=utf-8" httpEquiv="Content-Type" />
      <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
      <title>{tTitle}</title>
      <meta content={tAppName} name="author" />
      <meta content={tDescription} name="description" />
      <link
        href="/images/favicon.ico"
        rel="icon"
        sizes="16x16"
        type="image/ico"
      />
      <link
        href="/images/favicon-32x32.png"
        rel="shortcut icon"
        sizes="32x32"
        type="image/png"
      />
      <link
        href="/images/apple-touch-icon.png"
        rel="apple-touch-icon"
        sizes="180x180"
        type="image/png"
      />
      <meta
        content="minimum-scale=1, initial-scale=1.0, width=device-width, shrink-to-fit=no, viewport-fit=cover"
        name="viewport"
      />
      <meta content="width=device-width, user-scalable=no" name="viewport" />

      <meta
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        name="viewport"
      />
      <meta
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        name="viewport"
      />
      <meta
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        name="viewport"
      />
      <meta
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        name="viewport"
      />
      <meta content={tAppName} name="application-name" />

      {/*
      // Also:: "og:locale"
      // <html lang="ar" itemscope itemtype="https://schema.org/FAQPage">
       */}
      <meta content="en-AE" name="language" />

      <meta content="yes" name="apple-mobile-web-app-capable" />
      <meta content="default" name="apple-mobile-web-app-status-bar-style" />

      <meta content="telephone=yes" name="format-detection" />
      <meta content={tAppName} name="apple-mobile-web-app-title" />
      <meta content="yes" name="mobile-web-app-capable" />
      <meta content="#ffffff" name="theme-color" />
      <link href="/manifest.json" rel="manifest" />
      <link href="/site.webmanifest" rel="manifest" />
      <meta content="#fdfdfd" name="background-color" />

      {/* Pinned Sites   */}
      <meta content={tAppName} name="application-name" />
      <meta content={tDescription} name="msapplication-tooltip" />
      <meta content="/" name="msapplication-starturl" />
      <meta content="/browserconfig.xml" name="msapplication-config" />
      <meta content="#fdfdfd" name="msapplication-TileColor" />
      <meta content="no" name="msapplication-tap-highlight" />

      {/* Tap highlighting   */}
      <meta content="no" name="msapplication-tap-highlight" />

      {/* UC Mobile Browser   */}
      <meta content="yes" name="full-screen" />
      <meta content="application" name="browsermode" />

      {/* Disable night mode for this page   */}
      <meta content="enable/disable" name="nightmode" />

      {/* Layout mode  */}
      <meta content="fitscreen/standard" name="layoutmode" />

      {/* imagemode - show image even in text only mode   */}
      <meta content="force" name="imagemode" />

      {/* Orientation   */}
      <meta content="portrait" name="screen-orientation" />

      {/* ADD MORE SEO META TAGS.   */}
      <meta content="summary" name="twitter:card" />
      <meta content="https://twitter.com/northladder" name="twitter:url" />
      <meta content={tAppName} name="twitter:title" />
      <meta content={tDescription} name="twitter:description" />
      <meta
        content="https://pbs.twimg.com/profile_banners/1275369325432578050/1632996376/1500x500"
        name="twitter:image"
      />
      <meta content="@northladder" name="twitter:creator" />
      <meta content="website" property="og:type" />
      <meta content={tAppName} property="og:title" />
      <meta content={tDescription} property="og:description" />
      <meta content={tAppName} property="og:site_name" />
      <meta content="https://northladder.com" property="og:url" />
      <meta
        // Update this to use a proper URL for a valid og:image
        content="https://northladder/images/apple-touch-icon.png"
        property="og:image"
      />
    </>
  );
}
