import { TrashIcon } from '@heroicons/react/20/solid';

import { PreviewImageThumbnail } from './PreviewImageThumbnail';
import { PreviewContentContainer } from './styles';

interface IUploadPreviewContent {
  filePath: string;
  basePath: string;
  onPreview: (filePath: string) => void;
  onRemoveContent: (filePath: string) => void;
}

/**
 * -----------------------------------------------------------------------------
 * Displays that the image has already been uploaded.
 */
export function PreviewUploadContent({
  filePath,
  basePath,
  onPreview,
  onRemoveContent,
}: IUploadPreviewContent) {
  const handleOnClickRemove = () => {
    onRemoveContent(filePath);
  };

  return (
    <PreviewContentContainer className="group">
      <PreviewImageThumbnail
        basePath={basePath}
        filePath={filePath}
        onPreview={onPreview}
      />
      {/* <PreviewFileThumbnail /> */}

      <div
        className="
          absolute top-0 z-[2] grid gap-x-2 p-2 ltr:right-0 rtl:left-0
        "
      >
        <button
          className="
            rounded-lg border-2 bg-gray-50/90 p-1 text-gray-600
            transition-all duration-300 ease-in
            hover:!bg-violet-500 hover:text-white
            focus:!bg-violet-300 focus:outline-violet-200 focus:ring-violet-100
            group-hover:bg-violet-100
          "
          onClick={handleOnClickRemove}
          title="Remove this attachment"
          type="button"
        >
          <TrashIcon className="h-5 w-5" />
        </button>
      </div>
    </PreviewContentContainer>
  );
}
