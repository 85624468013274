import { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { IModalAction } from './types';
import { ModalActionButton } from './ModalActionButton';

interface IMapModalProps {
  actions?: IModalAction[];
  canCloseFromOverlay?: boolean;
  children: ReactNode;
  isOpen: boolean;
  onCloseModal?: () => void;
}

/**
 * -----------------------------------------------------------------------------
 * This provides a highly customizable modal component allowing the user to
 * customize the modal actions from the parent.
 */
export function SimpleModal({
  actions,
  canCloseFromOverlay = false,
  children,
  isOpen,
  onCloseModal,
}: IMapModalProps) {
  const handleCloseModal = () => {
    if (canCloseFromOverlay) {
      onCloseModal?.();
    }
  };

  return (
    <Transition.Root as={Fragment} show={isOpen}>
      <Dialog as="div" className="relative z-[65]" onClose={handleCloseModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div
            className="
              flex min-h-full w-full items-end justify-center p-4 text-center
              sm:items-center sm:p-0
            "
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="
                  relative min-h-fit transform overflow-hidden rounded-lg
                  bg-white text-left
                  shadow-xl transition-all duration-500  sm:w-full
                  sm:max-w-lg md:max-w-4xl xl:max-w-7xl
                "
              >
                {children}

                {actions ? (
                  <div
                    className="
                      absolute bottom-4 left-0 z-10 mt-5 flex w-full justify-end
                      gap-1 p-2 opacity-90 sm:mt-6
                    "
                  >
                    {actions.map((action) => (
                      <ModalActionButton key={action.id} action={action} />
                    ))}
                  </div>
                ) : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
