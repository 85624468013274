import { TSetGradeReviewListFilters } from '../types';

/**
 * This clears the grade review filter store
 */
export function onClearGradeReviewFilterStoreCallback(set: TSetGradeReviewListFilters) {
  return function onUpdateGradeFiltersSearchKey() {
    set(() => ({ selectedGradeFilters: [] }));
  };
}
