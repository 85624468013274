import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';

import '@northladder/design-system/src/theme/styles/css-imports.css';

import { SiteMetaData } from '@northladder/design-system';

import { LocaleProvider, useUpdateLocaleDir } from '@northladder/i18n';
import {
  ReactQueryProvider,
  useAuthentication,
  useDeviceInfo,
} from '@northladder/services';
import { usePAAS } from '@northladder/utilities';

/**
 * -----------------------------------------------------------------------------
 * This is the main entry point of our application. The root layout of all pages.
 */
function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const { isPaasEnabled } = usePAAS();
  const locale = router.locale as 'en' | 'ar';
  const accountId = (router.query.accountId || '0') as string;

  const deviceInfo = useDeviceInfo({ locale });

  useUpdateLocaleDir({ locale });
  useAuthentication({
    locale,
    accountId: parseInt(accountId, 10) || 0,
    deviceInfo,
  });

  return (
    <ReactQueryProvider>
      {isPaasEnabled ? (
        <>
          <Head>
            <SiteMetaData
              appName="NorthLadder Dealer"
              description="The Dealer App by NorthLadder, where your top competitors buy their mobile phones and tablets. Join their ranks"
              locale={locale}
              title="Dealer | NorthLadder"
            />
          </Head>
          <Component {...pageProps} />
        </>
      ) : (
        <LocaleProvider>
          <Head>
            <SiteMetaData
              appName="NorthLadder Dealer"
              description="The Dealer App by NorthLadder, where your top competitors buy their mobile phones and tablets. Join their ranks"
              locale={locale}
              title="Dealer | NorthLadder"
            />
          </Head>
          <Component {...pageProps} />
        </LocaleProvider>
      )}
    </ReactQueryProvider>
  );
}

export default App;
