import { isWeb } from '../common';

import { IEnv, TRegionId } from './types';
import { getMobileRegionEnvironment } from './getMobileRegionEnvironment';
import { getWebRegionEnvironment } from './getWebRegionEnvironment';

/**
 * This loads the web applications environment variables (`DEALER-WEB` in this case)
 * This indexes the regionEnvironment object to retrieve the config based on the
 * provided region id.
 *
 * @param regionId TRegionId - The  Id of the region.
 * @returns `IRegionEnv` - The region specific environment variables.
 */
export const getRegionEnvironment = (regionId: TRegionId) => {
  const isPaasEnabled =
    process.env.NEXT_PUBLIC_IS_PAAS_ENABLED &&
    process.env.NEXT_PUBLIC_IS_PAAS_ENABLED.toLowerCase() === 'true';

  const envLoader = (
    isWeb ? getWebRegionEnvironment() : getMobileRegionEnvironment()
  ) as IEnv;

  if (isPaasEnabled) {
    return envLoader.paas;
  }

  return envLoader[regionId];
};
