import { ChangeEvent } from 'react';
import { useRouter } from 'next/router';

import {
  cleanupDoubleSpace,
  validateAndCleanInputText,
  useDebounce,
  validate,
} from '@northladder/utilities';
import { TLocale } from '@northladder/i18n';

import { IInputElement } from '../../types';

import { TextInput } from './TextInput';

interface IInputTextItemProps extends IInputElement {
  // Add more `InputTextElement` props here
}

/**
 * -----------------------------------------------------------------------------
 * This is used to create the underlying debounced `TextInput` that...
 * - Captures user input, validates it based on provided constraints config.
 * - Updates the parent form the debounce delay (default 1s) with errors data
 *   and updated input.
 */
export function InputTextElement({
  autoFocus,
  className,
  classNameInput,
  classNameLabel,
  constraints,
  editable,
  error,
  hasClearButton,
  helpText,
  iconEnd,
  iconStart,
  isLabelEnabled,
  label,
  labelPostFix,
  name,
  onChangeCallback,
  onError,
  onBlurCallback,
  onFocus,
  placeholder,
  showIsRequired,
  subLabel,
  type,
  value = '',
  valuePrefix,
}: IInputTextItemProps) {
  const locale = useRouter().locale as TLocale;

  const {
    minLength,
    maxLength = 40,
    regExp,
    required,
    format,
  } = constraints || {};

  const handleDebounceOnChange = (updatedValue: string) => {
    onChangeCallback({
      name,
      value: updatedValue,
    });
  };

  const {
    debouncedValue: inputValue,
    onChangeDebounced,
    setDebouncedValue: setInputValue,
  } = useDebounce({
    initialValue: value,
    delay: 1000,
    onChangeCb: handleDebounceOnChange,
  });

  const handleValidateOnChange = (newValue: string) => {
    const updatedText = validateAndCleanInputText({
      text: newValue,
      format,
      regExp,
    });

    setInputValue(() => {
      onChangeDebounced(updatedText.trim());
      return updatedText;
    });

    return updatedText;
  };

  const handleInputChanged = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const text = event.target.value;
    handleValidateOnChange(text);
  };

  const handleInputFocus = () => {
    onError({ name, error: undefined });
    onFocus?.();
  };

  const handleInputBlur = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let updatedText = event.target.value;

    if (updatedText) {
      updatedText = cleanupDoubleSpace(updatedText);
    }

    const inputError = validate({
      target: event.target,
      constraints: constraints || {},
      locale,
    });

    onError({ name, error: inputError });
    onBlurCallback?.({ name, value: updatedText });
  };

  const handleClearInput = () => {
    handleValidateOnChange('');
  };

  return (
    <TextInput
      autoFocus={autoFocus}
      className={className}
      classNameInput={classNameInput}
      classNameLabel={classNameLabel}
      editable={editable}
      error={error}
      handleClearInput={handleClearInput}
      hasClearButton={hasClearButton}
      helpText={helpText}
      iconEnd={iconEnd}
      iconStart={iconStart}
      isLabelEnabled={isLabelEnabled}
      label={label}
      labelPostFix={labelPostFix}
      locale={locale}
      maxLength={maxLength}
      minLength={minLength}
      name={name}
      onBlur={handleInputBlur}
      onChange={handleInputChanged}
      onFocus={handleInputFocus}
      placeholder={placeholder}
      required={required}
      showIsRequired={showIsRequired}
      subLabel={subLabel}
      type={type}
      value={inputValue || ''}
      valuePrefix={valuePrefix}
    />
  );
}
