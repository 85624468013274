import {
  IActiveSession,
  TAllSessionsByIndex,
  TAllSessionsById,
  TSetAuthState,
} from '../types';

import { persistAuthStore } from './authStoreLocalStorageUtils';

/**
 * This removes the session instance from the application state & local storage.
 * NOTE: Currently since there is no logout API, the session is still active on
 * the backed and poses a risk of being copied is used.
 * TODO: Add a sign out API to all the remove sessions actions.
 */
export function removeSessionCallback(set: TSetAuthState) {
  return function updateAuthStore(session: IActiveSession) {
    set(
      ({
        activeSession,
        activityLog,
        allSessionsById,
        allSessionsByIndex,
        allSessionsIndices,
      }) => {
        const isSessionExisting = allSessionsById
          ? allSessionsById[session.userId]
          : null;

        let updatedActiveSession: IActiveSession | null = activeSession;
        let updatedAllSessionsIndices = [...allSessionsIndices];
        let updatedActivityLog = { ...activityLog };
        let updatedAllSessionsByIndex: TAllSessionsByIndex | null =
          allSessionsByIndex;
        let updatedAllSessionsById: TAllSessionsById | null = allSessionsById;

        if (isSessionExisting && activeSession) {
          updatedActivityLog = {
            ...activityLog,
            lastLogOutDate: new Date().getTime(),
          };

          /**
           * Filter out the target session that matches the index.
           */
          updatedAllSessionsIndices = allSessionsIndices.filter(
            (sessionIndex) => session.index !== sessionIndex
          );

          /**
           * The same applies here...since the `delete` keyword is destructive
           * on objects, use the object ...rest approach to ignore the target.
           */
          if (allSessionsById) {
            const {
              [isSessionExisting.userId]: targetSessionToOmit,
              ...restOfSessionsById
            } = allSessionsById;
            updatedAllSessionsById = restOfSessionsById;
          }

          /**
           * Since the `delete` keyword is destructive on objects, use the
           * object ...rest approach to ignore the target object.
           */
          if (allSessionsByIndex) {
            const {
              [isSessionExisting.index]: targetSessionToOmit,
              ...restOfSessionsByIdex
            } = allSessionsByIndex;
            updatedAllSessionsByIndex = restOfSessionsByIdex;
          }

          /**
           * If the removed session is the active, clear the active session.
           */
          if (activeSession.userId === isSessionExisting.userId) {
            // Reset the active session if it matches.
            updatedActiveSession = null;
          }
        }

        const updatedStoreFields = {
          activeSession: updatedActiveSession,
          activityLog: updatedActivityLog,
          allSessionsById: updatedAllSessionsById,
          allSessionsByIndex: updatedAllSessionsByIndex,
          allSessionsIndices: updatedAllSessionsIndices,
        };

        persistAuthStore(updatedStoreFields);

        return updatedStoreFields;
      }
    );
  };
}
