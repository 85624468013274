/**
 * NOTE: This uses repetitive indexing of process.env instead of destructing
 * them out because NextJs looks for the whole thread on the client side.
 * Meaning, dynamic look ups and assigning to variables won't work.
 *
 * Read More,...
 * [Exposing Environment Variables to the Browser](https://nextjs.org/docs/basic-features/environment-variables#exposing-environment-variables-to-the-browser)
 */

import { isWeb } from '../common';

import { IEnv, TNodeEnv, TInstance, TInstanceName } from './types';

/**
 * This extracts out the regions environments config from the ENV and makes it
 * ready for other parts of the app.
 * It loads the keys from the env and unifies them for region management.
 */
export const getWebRegionEnvironment = (): IEnv | null => {
  /**
   * For mobile applications that might accidentally try to load this file, exit
   * early to avoid doing so.
   */
  if (!isWeb) {
    return null;
  }

  // TODO: Extract out these variables in one line instead of inline indexing.
  // TODO: Look at `getMobileRegionEnvironment` for reference.
  return {
    NODE_ENV: (process?.env?.NODE_ENV as TNodeEnv) || 'development',
    INSTANCE: (process?.env?.INSTANCE as TInstance) || 'ALPHA',
    INSTANCE_NAME: (process?.env?.INSTANCE_NAME as TInstanceName) || 'alpha',
    MAPBOX_TOKEN: process?.env?.NEXT_PUBLIC_MAPBOX_TOKEN || '',
    GOOGLE_MAPS_API_KEY: process?.env?.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || '',
    paas: {
      ADMIN_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_ADMIN_SERVICE_BASE_URL_PAAS || '',
      CUSTOMER_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_CUSTOMER_SERVICE_BASE_URL_PAAS || '',
      IDENTITY_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_IDENTITY_SERVICE_BASE_URL_PAAS || '',
      MEMBER_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_MEMBER_SERVICE_BASE_URL_PAAS || '',
      CDN_S3_BUCKET_URL: process?.env?.NEXT_PUBLIC_CDN_S3_BUCKET_URL_PAAS || '',
      ACCOUNT_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_ACCOUNT_SERVICE_BASE_URL_PAAS || '',
      B2B_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_B2B_SERVICE_BASE_URL_PAAS || '',
    },
    ksa: {
      ADMIN_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_ADMIN_SERVICE_BASE_URL_KSA || '',
      CUSTOMER_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_CUSTOMER_SERVICE_BASE_URL_KSA || '',
      IDENTITY_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_IDENTITY_SERVICE_BASE_URL_KSA || '',
      MEMBER_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_MEMBER_SERVICE_BASE_URL_KSA || '',
      CDN_S3_BUCKET_URL: process?.env?.NEXT_PUBLIC_CDN_S3_BUCKET_URL_KSA || '',
      ACCOUNT_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_ACCOUNT_SERVICE_BASE_URL_KSA || '',
      B2B_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_B2B_SERVICE_BASE_URL_KSA || '',
    },
    uae: {
      ADMIN_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_ADMIN_SERVICE_BASE_URL_UAE || '',
      CUSTOMER_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_CUSTOMER_SERVICE_BASE_URL_UAE || '',
      IDENTITY_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_IDENTITY_SERVICE_BASE_URL_UAE || '',
      MEMBER_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_MEMBER_SERVICE_BASE_URL_UAE || '',
      CDN_S3_BUCKET_URL: process?.env?.NEXT_PUBLIC_CDN_S3_BUCKET_URL_UAE || '',
      ACCOUNT_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_ACCOUNT_SERVICE_BASE_URL_UAE || '',
      B2B_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_B2B_SERVICE_BASE_URL_UAE || '',
    },
    global: {
      ADMIN_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_ADMIN_SERVICE_BASE_URL_GLB || '',
      CUSTOMER_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_CUSTOMER_SERVICE_BASE_URL_GLB || '',
      IDENTITY_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_IDENTITY_SERVICE_BASE_URL_GLB || '',
      MEMBER_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_MEMBER_SERVICE_BASE_URL_GLB || '',
      CDN_S3_BUCKET_URL: process?.env?.NEXT_PUBLIC_CDN_S3_BUCKET_URL_GLB || '',
      ACCOUNT_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_ACCOUNT_SERVICE_BASE_URL_GLB || '',
      B2B_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_B2B_SERVICE_BASE_URL_GLB || '',
    },
    iq: {
      ADMIN_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_ADMIN_SERVICE_BASE_URL_GLB || '',
      CUSTOMER_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_CUSTOMER_SERVICE_BASE_URL_GLB || '',
      IDENTITY_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_IDENTITY_SERVICE_BASE_URL_GLB || '',
      MEMBER_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_MEMBER_SERVICE_BASE_URL_GLB || '',
      CDN_S3_BUCKET_URL: process?.env?.NEXT_PUBLIC_CDN_S3_BUCKET_URL_GLB || '',
      ACCOUNT_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_ACCOUNT_SERVICE_BASE_URL_GLB || '',
      B2B_SERVICE_BASE_URL:
        process?.env?.NEXT_PUBLIC_B2B_SERVICE_BASE_URL_GLB || '',
    },
  };
};
