import { Text } from '@northladder/i18n';
import Image from 'next/image';

import { translations } from '../../../translations';

/**
 * -----------------------------------------------------------------------------
 * Footer for the paas application.
 */
export function PaasFooter() {
  return (
    <footer className="overflow-hidden bg-purple-200">
      <div className="mx-auto max-w-[91rem] px-4 sm:px-6 lg:px-8">
        <div
          className="mx-auto my-auto flex flex-col items-center justify-center px-0 py-0
          pt-10 transition-all duration-500 md:max-w-[29rem] lg:max-w-[29rem]"
        >
          <Image
            alt="Footer Image"
            className="w-full object-cover object-center px-2"
            height={153}
            src="/images/paas-footer-img.png"
            width={448}
          />
        </div>
        <div
          className="
            flex flex-col items-center justify-center
            border-t border-slate-400/10 py-10 sm:flex-row
          "
        >
          <p className="mt-6 text-sm font-light text-gray-300 sm:mt-0">
            <span className="inline-flex">
              <Text text={translations.footer.copyright} />
            </span>
            <a
              className="px-2"
              href="https://northladder.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              NorthLadder.
            </a>
            <Text text={translations.footer.rightsReserved} />
          </p>
        </div>
      </div>
    </footer>
  );
}
