import tw, { styled } from 'twin.macro';

export const MapContainer = styled.div`
  ${tw`
    h-full
    w-full
    absolute
  `};

  .map-label {
    ${tw`
      absolute
      bg-white/90
      top-8
      left-4
      !text-violet-800
      !text-xs
      p-2
      rounded-lg
      border-2
      border-violet-50
      !flex
      !flex-wrap
      !max-w-[100px]
    `};
  }
`;

export const MapSearchInputWrapper = styled.div`
  ${tw`
    absolute
    flex

    top-14
    w-[calc(100%-4.5rem)]

    ltr:-mt-0.5
    rtl:-mt-[0.05rem]
    ltr:left-2.5
    rtl:left-2.5

    md:top-3
    md:mx-auto
    md:w-96
    md:px-1

    ltr:md:left-44
    rtl:md:left-[11.5rem]
  `};

  input {
    ${tw`
     rounded-none
     py-2

     bg-white/[.98]

     ltr:pl-12
     rtl:pr-12
    `};
  }

  .search-icon {
    ${tw`
      absolute
      top-2
      mx-2
      h-5
      w-5
      text-gray-600

      ltr:left-2
      rtl:right-2
    `};
  }
`;

export const GPSModalPickerContainer = styled.div``;

export const GPSTriggerContainer = styled.div`
  label {
    ${tw`
      block
      mb-1
      text-sm
      text-gray-700
    `};
  }

  &.has-error {
    ${tw`
      !text-red-800
    `};

    label {
      ${tw`
        text-red-800
      `};
    }

    button {
      ${tw`
        border-red-500/50
      `};
    }

    .placeholder {
      ${tw`
        !text-red-500/50
      `};
    }
  }
`;
