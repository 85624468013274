import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { TUIError } from '../../../types';

import { OTPInputCapture } from './OTPInputCapture';
import { OTPLoaderAndError } from './OTPLoaderAndError';

interface IOtpModalProps {
  errorInValidateOTP: TUIError;
  errorInSendingOTP: TUIError;
  isEnabled: boolean;
  isValidatingOTP: boolean;
  isSendingOTP: boolean;
  phoneNumber: string;
  otpLength?: number;
  onSendOTP: () => void;
  onValidateOTP: (otp: string) => void;
  /**
   * The component that opens the modal
   */
  renderModalTrigger: ({
    onOpenModal,
  }: {
    onOpenModal: () => void;
  }) => JSX.Element;
}

export function OtpModal({
  otpLength = 6,
  errorInValidateOTP,
  errorInSendingOTP,
  isEnabled,
  isSendingOTP,
  phoneNumber,
  isValidatingOTP,
  onSendOTP,
  onValidateOTP,
  renderModalTrigger,
}: IOtpModalProps) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(isEnabled);
  }, [isEnabled]);

  const handleOnCloseModal = () => {
    setIsOpen(false);
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      {renderModalTrigger({ onOpenModal: handleOpenModal })}

      <Transition appear as={Fragment} show={isOpen}>
        <Dialog
          as="div"
          className="relative z-[100]"
          onClose={handleOnCloseModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div
              className="
                flex min-h-full items-center justify-center p-4 text-center
              "
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className="
                    w-full max-w-sm transform overflow-hidden rounded-2xl
                    bg-white p-6 text-left align-middle shadow-xl transition-all
                  "
                >
                  {isSendingOTP || errorInSendingOTP?.message ? (
                    <OTPLoaderAndError
                      error={errorInSendingOTP}
                      onCloseModal={handleOnCloseModal}
                      onSendOTP={onSendOTP}
                      otpLength={otpLength}
                      phoneNumber={phoneNumber}
                    />
                  ) : (
                    <OTPInputCapture
                      error={errorInValidateOTP}
                      isLoading={isValidatingOTP}
                      onCloseModal={handleOnCloseModal}
                      onSendOTP={onSendOTP}
                      onValidateOTP={onValidateOTP}
                      otpLength={otpLength}
                      phoneNumber={phoneNumber}
                    />
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
