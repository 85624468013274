import { isWeb } from '@northladder/utilities';

import { TAmplitudeAnalytics } from './types';

/**
 * This initializes the amplitude analytics class for mobile.
 * TODO: Create a variant for web too.
 */
export const getMobileAmplitudeAnalytics = () => {
  if (isWeb) return;

  const { AmplitudeAnalytics } = require('./AmplitudeAnalytics');

  const ANALYTICS = AmplitudeAnalytics?.getClientInstance();

  // eslint-disable-next-line consistent-return
  return ANALYTICS;
};

/**
 * This creates and instance of the Mobile Amplitude Analytics to be used across
 * all React Native apps.
 */
export const ANALYTICS = getMobileAmplitudeAnalytics() as TAmplitudeAnalytics;
