import { useState, ChangeEvent, useEffect } from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';
import tw, { styled } from 'twin.macro';

import { useDebounce } from '@northladder/utilities';

import { CircularProgressLoader } from './CircularProgressLoader';

/**
 * Number type input field without update number trailing buttons
 */
const TextField = styled.input`
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ${tw`
    h-10 w-28
    border-gray-300
    pr-10 text-sm
    font-medium
  text-black
  `};
`;

interface IBidPriceTextField {
  /**
   * Initial Value of the input field
   */
  spotPrice: number;
  /**
   * Minimum update value (it will say need to increase at least this amount)
   */
  minUpdateValue: string;
  /**
   * Currency type or currency name eg: AED, USD etc..
   * See also `TCountryCurrency`.
   */
  currency: 'AED' | 'SAR' | 'IQD';
  /**
   * Callback supplied to the parent component to provide the updated value.
   */
  onUpdateSpotPrice: (newSpotPrice: string) => void;
  isUpdatingSpotPrice: boolean;
}

/**
 * A InputField. It will only accept number values It have one submit button for
 * sending the data to the parent or call the api in this component. it have the
 * validation to check the input field data is valid or not if it is truthy then
 * it will allow to click the button
 *
 * @param Expects `IBidPriceTextField`
 *
 * @returns JSX.Element
 */
export function BidPriceTextField({
  spotPrice = 0,
  currency = 'AED',
  minUpdateValue = '10',
  onUpdateSpotPrice,
  isUpdatingSpotPrice: loading,
}: IBidPriceTextField) {
  // state for capturing onchange data from input field
  const [inputFieldData, setInputFieldData] = useState('');

  useEffect(() => {
    // add initial price to field
    setInputFieldData(spotPrice.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // this function will interact with `useDebounce` and give the inputData after
  // the time interval
  const handleSubmitOnChangeData = (inputData: string) => {
    setInputFieldData(inputData);
  };

  // handling click event for check icon submit button
  const handleOnClick = () => {
    if (!inputFieldData) return;
    onUpdateSpotPrice(inputFieldData);
  };

  // object from debounce
  const {
    debouncedValue: inputValue,
    onChangeDebounced,
    setDebouncedValue: setInputValue,
  } = useDebounce({
    initialValue: inputFieldData,
    delay: 300,
    onChangeCb: handleSubmitOnChangeData,
  });

  // taking input from input field and updating the debounce values
  // `setInputValue` ,`debouncedHandleChange`
  const handleOnTextChange = (inputData: ChangeEvent<HTMLInputElement>) => {
    const onChangeData = inputData.target.value;
    setInputValue(onChangeData);
    onChangeDebounced(onChangeData);
  };

  return (
    <div className="flex w-fit flex-col">
      <div className="relative flex w-fit flex-row">
        <TextField
          autoFocus
          onChange={handleOnTextChange}
          placeholder="price"
          type="number"
          value={inputValue}
        />
        <button
          className="
          absolute right-[-5px]
          flex h-10 w-10 flex-row
          items-center justify-center
          rounded-md bg-black text-white"
          onClick={handleOnClick}
          type="submit"
        >
          {loading ? (
            <CircularProgressLoader strokecolor="#fff" />
          ) : (
            <CheckIcon className="h-7 p-1" />
          )}
        </button>
      </div>
      <p className="self-center text-xs font-normal text-slate-400">
        {`Min. update ${currency} ${minUpdateValue}`}
      </p>
    </div>
  );
}
