import { IInputElement } from '../../types';

import { InputTextElement } from '../../form-inputs';

type TTextInputFieldProps = Pick<
  IInputElement,
  | 'constraints'
  | 'error'
  | 'isLabelEnabled'
  | 'label'
  | 'name'
  | 'onBlurCallback'
  | 'onChangeCallback'
  | 'onError'
  | 'placeholder'
  | 'subLabel'
  | 'type'
  | 'value'
>;

/**
 * -----------------------------------------------------------------------------
 * This is used to capture generic input field without a custom dedicated
 * form-field.
 */
export function TextInputField({
  constraints = {},
  error,
  label,
  isLabelEnabled,
  name,
  onBlurCallback,
  onChangeCallback,
  onError,
  placeholder,
  subLabel,
  type = 'text',
  value,
}: TTextInputFieldProps) {
  return (
    <InputTextElement
      constraints={{
        feedbackLabel: label || 'The input',
        maxLength: 80,
        minLength: 2,
        required: true,
        format: 'title',
        ...constraints,
      }}
      error={error}
      hasClearButton
      isLabelEnabled={isLabelEnabled}
      label={label}
      name={name}
      onBlurCallback={onBlurCallback}
      onChangeCallback={onChangeCallback}
      onError={onError}
      placeholder={placeholder}
      showIsRequired={false}
      subLabel={subLabel}
      type={type}
      value={value}
    />
  );
}
