import { TSetAuthState } from '../types';

import { persistAuthStore } from './authStoreLocalStorageUtils';

/**
 * This flushes all the local session state currently being tracked by the app.
 * This has the resultant effect of logging out the user from all accounts.
 */
export function removeAllSessionsCallback(set: TSetAuthState) {
  return function updateAuthStore() {
    set(({ activityLog }) => {
      const initialState = {
        activeSession: null,
        activityLog: { ...activityLog, lastLogOutDate: new Date().getTime() },
        allSessionsById: null,
        allSessionsByIndex: null,
        allSessionsIndices: [],
      };

      persistAuthStore(initialState);

      return initialState;
    });
  };
}
