import { TLocale, TLocaleLabel } from './types';

/**
 * This is the worst case default. The value might be read from the browser
 * language setting later, picked from the backend, read from the url or
 * even load a previously cached value from local storage.
 */
export const DEFAULT_LOCALE: TLocale = 'en';

/**
 * This loosely translates to `Arabia` to be used indicating arabic language.
 */
export const LANGUAGE_ARABIC: TLocaleLabel = 'عربي';

/**
 * Label to be used in indicating english languages.
 */
export const LANGUAGE_ENGLISH: TLocaleLabel = 'English';
