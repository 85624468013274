/* eslint-disable indent */

import { UseQueryOptions, QueryKey } from '@tanstack/react-query';

import { useAuthStore, useRegionStore } from '../../stores';

import { useQuery } from './reactQueryHooks';

/**
 * `useQueryWithUserId` is a copy of reactQuery useQuery function with custom userId
 * for session api, state, cache management
 */
export const useQueryWithUserId = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData
>({
  queryKey = [],
  ...rest
}: UseQueryOptions<TQueryFnData, TError, TData>) => {
  const userId = useAuthStore.getState()?.activeSession?.userId || '';
  const appLanguage = useRegionStore.getState().appLanguage || 'en';
  const key: QueryKey = [...queryKey, userId, appLanguage];

  return useQuery({
    ...rest,
    queryKey: key,
  });
};
