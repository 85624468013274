import { ErrorInfo } from 'react';

import { TTextLocalized } from '../types';

export enum ErrorEventsEnum {
  ERROR_IN_APP = '🅰️ - Application Wide Error',
  ERROR_IN_APP_INITIALIZATION = '⭕️ - Error while initializing the application',
  ERROR_IN_SCREEN_OR_PAGE = '🚩 - Error Caught by the Screen or Page',
  ERROR_IN_ATTACHMENT = '📎 - Error Image/File Attachment',
  ERROR_IN_PAYMENT = '🏦 - Error in Payment Processing',
  ERROR_IN__LOCAL_OR_MMKV_STORAGE = '💾 - Error in Persisted Storage',
  ERROR_IN_PERMISSION = '🔐 - Error in Accessing Permissions',
  ERROR_IN_PDF = '📜 - Error in PDF',
  ERROR_IN_VIDEO_PREVIEW = '🎥 - Error in Video Preview',

  // Generic Errors Server or App Errors
  ERROR_IN_API_CALL = '🚨 - ERROR_IN_API_CALL',
  ERROR_CONNECTION_TIMED_OUT = 'ERROR_CONNECTION_TIMED_OUT',
  ERROR_INTERNET_CONNECTION = 'ERROR_INTERNET_CONNECTION',
  UNAUTHORIZED = 'UNAUTHORIZED',
  INVALID_SESSION_ID = 'INVALID_SESSION_ID',

  // Document upload failed
  DOCUMENT_UPLOAD_FAILED = 'DOCUMENT_UPLOAD_FAILED',
  DOCUMENT_PROCESSING_FAILED = 'DOCUMENT_PROCESSING_FAILED',
}

export type TErrorEventsKey = keyof typeof ErrorEventsEnum;

export interface IErrorLogToRemoteUtilOptions {
  error: Error | unknown;
  errorCode: ErrorEventsEnum;
  errorTitle?: string;
  message?: string | TTextLocalized;
  params?: Record<string, string | number>;
}

export interface ILogDevicePermissionErrorArgs {
  message?: string;
  shouldOpenSettings?: string;
}

export interface IErrorLoggerOptions {
  error: Error | unknown;
  errorCode: ErrorEventsEnum;
  errorInfo?: Record<string, unknown> | ErrorInfo;
  errorTitle?: string;
  message?: string;
  handleAsCrash?: boolean;
}
