/* global google */

import { useState, useEffect } from 'react';
import {
  useGeocodingService,
  useGoogleMap,
} from '@ubilabs/google-maps-react-hooks';

import { Text } from '@northladder/i18n';

import { translations } from '../../../../../translations';

import { CircularLoader } from '../../../../loaders';
import { IconTarget } from '../../../../icons';

import {
  getMapMarkerOptions,
  getLocationInfoFromGeocode,
  IGetDecodedLocationData,
} from '../../utilities';
import { ICoordinate } from '../../types';

interface IMapMarkerProps {
  /**
   * The list of locations for the markers to plot on the map.
   */
  location: ICoordinate;
  onUpdateLocation: (location: ICoordinate) => void;
}

/**
 * -----------------------------------------------------------------------------
 * This renders a CTA button that allows getting the user's current location
 * from the map and  plotting it.
 */
export function LocateMeOnMap({ location, onUpdateLocation }: IMapMarkerProps) {
  const map = useGoogleMap();
  const geocoder = useGeocodingService();

  const [isLoadingMyLocation, setIsLoadingMyLocation] = useState(false);
  const [infoWindow, setInfoWindow] =
    useState<google.maps.InfoWindow | null>(null);

  /**
   * This handler is called when google has successfully geodecoded the nearest
   * map location search results from the latitude and longitude provided.
   */
  const handleOnGeodecodeSuccess = ({
    geoCord,
    formattedAddress,
    position,
  }: IGetDecodedLocationData) => {
    if (!map || !infoWindow) return;
    if (!position || !formattedAddress) return;

    infoWindow.setPosition(position);
    infoWindow.setContent(formattedAddress);
    map.setCenter(position);

    onUpdateLocation({
      ...location,
      ...geoCord,
      title: formattedAddress,
    });

    setIsLoadingMyLocation(() => false);
  };

  const handleLocationError = (browserHasGeolocation: boolean) => {
    const error = browserHasGeolocation
      ? 'Error: The Geolocation service failed.'
      : "Error: Your browser doesn't allow geolocation.";

    // eslint-disable-next-line no-alert
    alert(error);

    setIsLoadingMyLocation(() => false);
  };

  const handleLocateMe = () => {
    setIsLoadingMyLocation(() => true);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          if (!geocoder) return;

          getLocationInfoFromGeocode({
            geocoder,
            location: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
            onSuccessCb: handleOnGeodecodeSuccess,
          });
        },
        () => {
          /**
           * Browser supports GeoLocation but an error occurred while fetching
           * the location...
           * TODO: Do something in to reload the map.
           */
          // handleLocationError(true, map.getCenter()!);
          handleLocationError(true);
        },
        {
          enableHighAccuracy: true,
          maximumAge: 10 * 1000, // Cache the position for 10 seconds.
          timeout: 30 * 1000, // Wait for 30 seconds while resolving location.
        }
      );
    } else {
      /**
       * Browser does not support GeoLocation.
       * TODO: Show a better fallback error
       */
      handleLocationError(false);
    }
  };

  /**
   * This effect plots the markers on the map every time the `map` object & the
   * `location` point changes.
   */
  useEffect(() => {
    if (!map) {
      return () => {
        // Do nothing here if there is no map.
      };
    }

    const initialBounds = new google.maps.LatLngBounds();

    const mapMakerOptions = getMapMarkerOptions({ location, map });
    const { position } = mapMakerOptions;
    initialBounds.extend(position);

    /**
     * Add the infoWindow to show the location tooltip above the map.
     */
    const newInfoWindow = new google.maps.InfoWindow({
      ariaLabel: 'Store Location',
      content: mapMakerOptions.title,
      position,
    });

    setInfoWindow(newInfoWindow);

    map.setCenter(initialBounds.getCenter());

    return () => {
      newInfoWindow?.close();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, location]);

  return (
    <button
      className="
        form-btn-secondary absolute bottom-10 z-[100] mb-8 hidden w-auto
        flex-row border border-gray-300 bg-white text-xs text-gray-900 opacity-90
        ltr:right-2 rtl:left-2 md:flex
      "
      disabled={isLoadingMyLocation}
      onClick={handleLocateMe}
      type="button"
    >
      {isLoadingMyLocation ? (
        <CircularLoader className="h-4 w-4 shrink-0 ltr:mr-3 rtl:ml-3" />
      ) : (
        <IconTarget className="h-4 w-4 shrink-0 stroke-red-500 ltr:mr-2 rtl:ml-2" />
      )}
      <Text
        className="width-auto block shrink-0"
        text={translations.gpsField.locateMeButton}
      />
    </button>
  );
}
