/**
 * This manages reusable translations common to `packages/i18n` components.
 * TODO: Move this to a Strapi API endpoint
 */
export const translations = {
  region: {
    en: 'Region',
    ar: 'منطقة',
  },
  selectARegion: {
    en: 'Select your Region',
    ar: 'حدد منطقتك',
  },
};
