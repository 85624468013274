import { CircularLoader } from '../../loaders';

import { TActionBarIcon } from './type';
import ActionBarIcons from './Icons';
import { ActionBarButtonWrapper } from './styles';

interface IActionBarButtonProps {
  /**
   * Classname for icon.
   */
  iconClassName?: string;
  /**
   * Classname for button.
   */
  className?: string;
  /**
   * For the icons in the button.
   */
  icon: TActionBarIcon;
  /**
   * Label for the button.
   */
  label: string;
  /**
   * The action carried out once the button is clicked.
   */
  onClick: () => void;
  /**
   * for checking loading state.
   */
  isLoading?: boolean;
  /**
   * For disabling the Download button when the brand isnt selected.
   */
  isDisabled?: boolean;
}

/**
 *------------------------------------------------------------------------------
 * Action bar button that displays download bid book or upload bid book button.
 *
 * @param `IActionBarButtonProps`- takes icon, label and onClick event.
 *
 * @returns JSX.Element
 */
function ActionBarButton({
  className,
  iconClassName = '',
  icon,
  label,
  isLoading,
  isDisabled,
  onClick,
}: IActionBarButtonProps) {
  const ButtonIcon = ActionBarIcons[icon];

  return (
    <ActionBarButtonWrapper
      className={className}
      disabled={isDisabled}
      onClick={onClick}
    >
      {isLoading ? (
        <CircularLoader className="mr-2" />
      ) : (
        <ButtonIcon
          className={`
            ${iconClassName || 'download-icon'}
            ${isDisabled && 'icon-disabled'}
          `}
        />
      )}
      <span>{label}</span>
    </ActionBarButtonWrapper>
  );
}

export default ActionBarButton;
