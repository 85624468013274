import Link from 'next/link';

import { INavLinkItemProps } from '../types';

/**
 * -----------------------------------------------------------------------------
 * Shows a clickable navigation link within the navigation bar.
 * To be use on public facing pages.
 *
 * @param INavLinkItemProps
 * @returns JSX.Element
 */
export function NavLinkItem({
  altTitle,
  className = '',
  isEnabled = true,
  label,
  labelExtra,
  to,
}: INavLinkItemProps) {
  return isEnabled ? (
    <Link
      className={`
        inline-block rounded-lg px-2 py-1 text-sm text-slate-700
        transition-all duration-100 hover:bg-purple-100 hover:text-purple-900
        focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-1
        focus-visible:outline-purple-100 active:bg-purple-100 active:text-purple-900
        ${className}
      `}
      href={to}
      title={altTitle || label}
    >
      {label}
      <span>
        {labelExtra ? (
          <span className="hidden pl-1 lg:inline">{labelExtra}</span>
        ) : null}
      </span>
    </Link>
  ) : null;
}
