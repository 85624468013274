import { forwardRef, RefObject, useRef } from 'react';
import { XMarkIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';

import { Text, TLocale } from '@northladder/i18n';
import { getTextFromI18n } from '@northladder/utilities';

import { IBaseInputTextItem } from '../../types';

import { TextInputContainer } from './styles';

interface ITextInputProps extends IBaseInputTextItem {
  /**
   * This provides options for translating the text input fields that cannot be
   * rendered by the Translation ready`i18n/Text`, such as the element title
   * attributes which only expect string.
   */
  locale: TLocale;
}

type IInput = Omit<IBaseInputTextItem, 'label'>;

// eslint-disable-next-line react/display-name
const Input = forwardRef((props: IInput, ref) => {
  const { type } = props;
  const placeholder = props.placeholder as string;

  return type === 'textarea' ? (
    <textarea
      {...props}
      ref={ref as RefObject<HTMLTextAreaElement>}
      className="resize-none pr-8"
      maxLength={200}
      placeholder={placeholder}
      rows={5}
    />
  ) : (
    <input
      {...props}
      ref={ref as RefObject<HTMLInputElement>}
      placeholder={placeholder}
    />
  );
});

/**
 * -----------------------------------------------------------------------------
 * Creates a theme-able TextInput component with support for error self container
 * error feedback,loading and animated labels.
 *
 * TODO: Add loading and animatable labels.
 */
export function TextInput({
  // autoFocus = false, TODO: Implement Auto Focus
  className = '',
  classNameInput = '',
  classNameLabel = '',
  editable = true,
  error,
  handleClearInput,
  hasClearButton = false,
  helpText,
  iconStart,
  iconEnd,
  isLabelEnabled = true,
  label: fieldLabel,
  labelPostFix = '',
  locale,
  onChange,
  placeholder = '',
  value = '',
  onBlur,
  onFocus,
  valuePrefix = '',
  maxLength,
  minLength,
  name,
  required,
  showIsRequired = true,
  subLabel,
  type,
}: ITextInputProps) {
  const inputRef: RefObject<HTMLInputElement | HTMLTextAreaElement> =
    useRef(null);

  const handleClear = () => {
    if (inputRef && handleClearInput) {
      inputRef.current?.focus();
      handleClearInput();
    }
  };

  const showRequiredStar = showIsRequired && required ? ' *' : '';
  const ariaDescription = error ? `${name}-error` : name;

  const inputClasses = [];
  if (hasClearButton) inputClasses.push('input-has-x-btn');
  if (iconStart || valuePrefix) inputClasses.push('input-has-leading-icon');

  const errorClass = error ? 'input-has-error' : '';
  const translatedPlaceholder = getTextFromI18n({
    text: placeholder,
    locale,
  });

  const iconsEndClassName = type === 'textarea' ? 'textarea-icons-end' : '';

  return (
    <TextInputContainer className={`${className} ${errorClass}`}>
      {isLabelEnabled ? (
        <label className={classNameLabel} htmlFor={name}>
          <Text text={fieldLabel} />
          <Text text={labelPostFix} />
          <Text text={showRequiredStar} />
        </label>
      ) : null}
      {subLabel ? (
        <label className="sub-label" htmlFor={name}>
          <Text text={subLabel} />
        </label>
      ) : null}
      <div className={`input-wrapper ${classNameInput}`}>
        <Input
          ref={inputRef}
          aria-describedby={ariaDescription}
          aria-invalid={!!error}
          className={inputClasses.join(' ')}
          disabled={!editable}
          id={name}
          maxLength={maxLength}
          minLength={minLength}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={translatedPlaceholder}
          required={required}
          type={type}
          value={value}
        />

        {valuePrefix ? (
          <div className="input-icons-start pointer-events-none items-center">
            <span className="text-gray-400">{valuePrefix}</span>
          </div>
        ) : null}

        <div className="input-icons-start">{iconStart || null}</div>
        <div className={`input-icons-end ${iconsEndClassName}`}>
          {iconEnd || null}
          {hasClearButton && value && editable ? (
            <XMarkIcon
              aria-hidden="true"
              className="icon-clear-input"
              onClick={handleClear}
            />
          ) : null}

          {error ? (
            <ExclamationCircleIcon
              aria-hidden="true"
              className="icon-error-indicator"
            />
          ) : null}
        </div>
      </div>
      <p className="input-error-msg" id={`${name}-error`}>
        <Text text={error || ''} />
      </p>

      {helpText ? (
        <p className="input-helper-msg">
          <Text text={helpText} />
        </p>
      ) : null}
    </TextInputContainer>
  );
}
