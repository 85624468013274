import tw, { styled } from 'twin.macro';

export const TradeInDeviceDropdownWrapper = styled.div`
  .label-class {
    ${tw`
      text-gray-700
      text-sm
    `};
  }
  .sub-label-class {
    ${tw`
      pt-1
      pb-2
      text-xs
      text-gray-500
    `};
  }

  &.has-error {
    ${tw`
      text-red-600/80
      text-xs
    `};

    p {
      ${tw`
        text-red-800/80
      `};
    }

    .border-class {
      ${tw`
        border-red-500/50
      `};
    }

    .placeholder-class {
      ${tw`
        text-red-500/50
      `};
    }
  }
`;
