import { ReactLoader } from '../../loaders';

import { InputFieldWrapper } from './styles';

interface IOtpInputFieldDummyProps {
  otpLength: number;
}

/**
 * -----------------------------------------------------------------------------
 * This is shown as a replacement when the OTP is loading.
 */
export function OtpInputFieldDummy({ otpLength }: IOtpInputFieldDummyProps) {
  return (
    <InputFieldWrapper>
      {Array.from(Array(otpLength).keys()).map((digit) => (
        <span key={digit} className="otp-input otp-loading cursor-wait">
          <ReactLoader />
        </span>
      ))}
    </InputFieldWrapper>
  );
}
