import { useState } from 'react';
import { MapPinIcon } from '@heroicons/react/20/solid';

import { Text, TTextLocalized } from '@northladder/i18n';

import { translations } from '../../../../../translations';
import { TUIError } from '../../../../../types';

import { IBaseGoogleMapLocationOptions, ICoordinate } from '../../types';
import { GPSModalPickerContainer, GPSTriggerContainer } from '../../styles';

import { GoogleMapModal } from '../GoogleMapModal';

interface IGoogleMapLocationPickerProps extends IBaseGoogleMapLocationOptions {
  gpsError: TUIError;
}

interface IGPSTriggerProps {
  classNameLabel?: string;
  error: TUIError;
  label?: TTextLocalized;
  onClickTrigger: () => void;
  placeholder: TTextLocalized;
  selectedPlace: ICoordinate | null;
}

/**
 * -----------------------------------------------------------------------------
 * This triggers the the map on click, and displays the selected location
 * coordinates if available.
 */
function GPSTrigger({
  classNameLabel,
  error,
  label,
  onClickTrigger,
  placeholder,
  selectedPlace,
}: IGPSTriggerProps) {
  const { title, lat, lng } = selectedPlace || {};
  const latitude = lat?.toPrecision(8);
  const longitude = lng?.toPrecision(8);

  const gpsCordClass = title ? '' : ' ltr:pr-8 rtl:pl-8';
  const errorClass = error ? ' has-error' : '';

  return (
    <GPSTriggerContainer className={errorClass}>
      {label ? (
        <label className={classNameLabel} htmlFor="gps-map-picker">
          <Text text={label} />
        </label>
      ) : null}

      <button
        className="
          form-input-field inline-flex flex-wrap justify-between text-sm
          text-gray-700
        "
        onClick={onClickTrigger}
        type="button"
      >
        {selectedPlace ? (
          <div className="flex w-full flex-col">
            {title ? (
              <p className="mb-1.5 border-b border-gray-100 pb-1.5 ltr:pr-8 rtl:pl-8">
                {title}
              </p>
            ) : null}

            <div
              className={`
                flex grow flex-col justify-start gap-1 gap-x-8
                ${gpsCordClass}
              `}
            >
              <div className="flex justify-between">
                <dt className="w-16 font-light sm:w-auto">
                  <Text
                    className="font-light"
                    text={translations.gpsField.latitude}
                  />
                </dt>
                <dd className="sm:mx-2">{latitude}</dd>
              </div>
              <div className="flex justify-between">
                <dt className="w-16 font-light sm:w-auto">
                  <Text
                    className="font-light"
                    text={translations.gpsField.longitude}
                  />
                </dt>
                <dd className="sm:mx-2">{longitude}</dd>
              </div>
            </div>
          </div>
        ) : (
          <Text className="placeholder font-light" text={placeholder} />
        )}

        <MapPinIcon
          className="
            absolute top-2 h-6 w-6 shrink-0 text-gray-500 ltr:right-2
            rtl:left-2
          "
        />
      </button>

      {error?.message ? (
        <p className="pt-2 text-xs text-red-500">
          <Text text={error.message} />
        </p>
      ) : null}
    </GPSTriggerContainer>
  );
}

/**
 * -----------------------------------------------------------------------------
 * This provides a component that picks the map displays the current map location
 * that also dubs as a trigger to open the map.
 */
export function GoogleMapLocationPicker({
  classNameContainer,
  classNameLabel,
  initLocation,
  label,
  onUpdateLocation,
  placeholder = 'Pick the location on the map',
  regionId,
  regionKey,
  gpsError,
}: IGoogleMapLocationPickerProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(() => true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <GPSModalPickerContainer className={classNameContainer}>
      <GPSTrigger
        classNameLabel={classNameLabel}
        error={gpsError}
        label={label}
        onClickTrigger={handleModalOpen}
        placeholder={placeholder}
        selectedPlace={initLocation}
      />
      <GoogleMapModal
        initLocation={initLocation}
        isModalOpen={isModalOpen}
        onCloseModal={handleModalClose}
        onUpdateLocation={onUpdateLocation}
        regionId={regionId}
        regionKey={regionKey}
      />
    </GPSModalPickerContainer>
  );
}
