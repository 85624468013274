import tw, { styled } from 'twin.macro';

/**
 * -----------------------------------------------------------------------------
 * This contains the styles for the form feedback section that either styles as
 * an info tip or an error block.
 */
export const FormFeedbackSectionContainer = styled.p`
  ${tw`
    font-normal
    text-sm
    text-purple-500
    // py-3
    transition-all
    duration-300
    ease-in-out
    dark:text-gray-400
  `};

  .message {
    ${tw`block text-purple-700`}
  }

  .description {
    ${tw`text-xs font-light text-purple-600`}
  }

  &.feedback-error,
  &.feedback-info {
    ${tw`!mt-0 text-center`}
  }

  &.feedback-error {
    .message {
      ${tw`text-red-600`}
    }

    .description {
      ${tw`text-red-500`}
    }
  }
`;
