import {
  TLanguagesConfigItem,
  TLanguagesConfigByLocale,
  TRegionInstanceById,
  TRegionInstance,
} from './types';
import { LANGUAGE_ARABIC, LANGUAGE_ENGLISH } from './localeConstants';

/**
 * A list of locales, grouped by english and arabic versions to be displayed in
 * the language selector menu.
 *
 * TODO: 1. Get these from the API
 * TODO: 2. Add additional flags specific to the country.
 * TODO: 3. Make the text language aware (Arabic ETC/great if done at config level).
 * */
export const languagesConfig: TLanguagesConfigItem[] = [
  {
    id: 'en',
    locale: 'en',
    icon: 'english',
    label: LANGUAGE_ENGLISH,
  },
  {
    id: 'ar',
    locale: 'ar',
    icon: 'arabic',
    label: LANGUAGE_ARABIC,
  },
];

/**
 * This creates a normalized list of the languages that can easily be indexed by
 * `locale` without having to refilter the array.
 */
export const languagesConfigByLocale = languagesConfig.reduce(
  (previousItem, currentItem) => {
    const langByIds = { ...previousItem, [currentItem.locale]: currentItem };

    return langByIds;
  },
  {} as TLanguagesConfigByLocale
);

/**
 * A list of locales, grouped by english and arabic versions to be displayed in
 * the language selector menu.
 *
 * TODO: 1. Get these from the API
 * TODO: 2. Add additional flags specific to the country.
 * TODO: 3. Make the text language aware (Arabic ETC/great if done at config level).
 * */
export const regionsConfig: TRegionInstance[] = [
  {
    id: 'uae',
    key: 'AE',
    currency: 'AED',
    icon: 'uae',
    label: {
      en: 'United Arab Emirates',
      ar: 'الإمارات العربية المتحدة',
    },
    countryCode: '+971',
    contactNumber: '+971551804804',
    whatsappNumber: '971551804804',
    email: 'mangesh.mohanan@northladder.com',
    address: 'One Central - The Offices 4 - Dubai - United Arab Emirates',
  },
  {
    id: 'ksa',
    key: 'SA',
    currency: 'SAR',
    icon: 'ksa',
    label: {
      en: 'Kingdom of Saudi Arabia',
      ar: 'المملكة العربية السعودية',
    },
    countryCode: '+966',
    contactNumber: '+966596466841',
    whatsappNumber: '966596466841',
    email: 'ismail.vora@northladder.com',
    address:
      'Office 19 , 2nd Floor , Al Jawhara Business Center, Tahlia Street, Riyadh 11643, Kingdom of Saudi Arabia',
  },
  // {
  //   id: 'global',
  //   key: 'GLOBAL',
  //   currency: 'IQD',
  //   icon: 'global',
  //   label: {
  //     en: 'Global',
  //     ar: 'عالمي',
  //   },
  //   countryCode: '+964',
  //   contactNumber: '+9647830130130',
  //   whatsappNumber: '9647830130130',
  //   email: 'dealerIQ@northladder.com',
  // },
  {
    id: 'iq',
    key: 'IQ',
    currency: 'IQD',
    icon: 'iraq',
    label: {
      en: 'Iraq',
      ar: 'العراق',
    },
    countryCode: '+964',
    contactNumber: '+9647830130130',
    whatsappNumber: '9647830130130',
    email: 'dealerIQ@northladder.com',
    address: 'Bghdad - Karkh - Qadisiyah - Alley 606 - St. 8 - Building 86',
  },
];

/**
 * This creates a normalized list of the regions that can easily be indexed by
 * `id` without having to refilter the array.
 */
export const regionsConfigById = regionsConfig.reduce(
  (previousItem, currentItem) => {
    const regionByIds = { ...previousItem, [currentItem.id]: currentItem };

    return regionByIds;
  },
  {} as TRegionInstanceById
);
