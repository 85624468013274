import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';

import { Text } from '@northladder/i18n';

import { translations } from '../../../translations';
import { TUIError } from '../../../types';

import { Dropzone } from '../../dropzone';

interface IUploadFileModalProps {
  onCloseModal: () => void;
  error: TUIError;
  isLoading: boolean;
  isUploaded: boolean;
  missingFileError: TUIError;
  onDrop: (file: File[]) => void;
  onHandleFileUpload: () => void;
  selectedFile: File;
  setSelectedFile: (selectedFile: File | null) => void;
}

/**
 * -----------------------------------------------------------------------------
 * This displays the contents in the modal for uploading a file.
 */
export function UploadFileModalContent({
  isLoading: isUploadingFile,
  error: fileUploadError,
  onCloseModal,
  isUploaded,
  selectedFile,
  setSelectedFile,
  onDrop,
  missingFileError,
  onHandleFileUpload: handleFileUpload,
}: IUploadFileModalProps) {
  const trDocUpload = translations.documentUpload;

  /**
   * Clear the selectedFile and close modal on clicking the `XMarkIcon`.
   */
  const handleClearModal = () => {
    setSelectedFile(null);
    onCloseModal();
  };

  return (
    <div className="w-full">
      <div className="flex justify-end p-2">
        <XMarkIcon className="h-6 w-6" onClick={handleClearModal} />
      </div>

      {isUploaded ? (
        <div className="flex flex-col items-center">
          <CheckCircleIcon className="my-4 h-10 w-10 fill-green-600" />
          <p>
            <Text text={trDocUpload.uploadDone} />
          </p>
          <button
            className="
              focus-visible:(ring-2 ring-offset-2) mr-4 rounded-md border
              border-transparent bg-violet-100 px-6 py-2 text-sm font-medium
              text-violet-900 ring-violet-500 hover:bg-violet-200 focus:outline-none
            "
            onClick={handleClearModal}
            type="button"
          >
            <Text text={trDocUpload.closeButton} />
          </button>
        </div>
      ) : (
        <Dropzone
          error={fileUploadError}
          file={selectedFile}
          isLoading={isUploadingFile}
          missingFileError={missingFileError}
          modalAcceptedFileText={trDocUpload.modalAcceptedFileType}
          onDrop={onDrop}
          onHandleUpload={handleFileUpload}
        />
      )}
    </div>
  );
}
