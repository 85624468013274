import { useRouter } from 'next/router';

import {
  TLocale,
  languagesConfig,
  languagesConfigByLocale,
} from '@northladder/services';
import { LocaleMenu } from './LocaleMenu';
import { SetLocaleCTA } from './SetLocaleCTA';

interface ILocaleSwitcherProps {
  /**
   * The class to be passed down the the container parent element.
   */
  className?: string;
  /**
   * This determines whether the switch is `inline` with other components or
   * floating absolutely in the view.
   */
  isInline?: boolean;
  /**
   * localCTAClassName to configure the localCTA class.
   */
  localCTAClassName?: string;
}

/**
 * -----------------------------------------------------------------------------
 * This renders a floating menu dropdown (incase multi-languages are supported),
 * or nav link (for just duo language support) that's used in selecting the
 * preferred language by the user.
 *
 * @returns JSX.Element
 */
export function LocaleSwitcher({
  className = '',
  isInline = false,
  localCTAClassName = '',
}: ILocaleSwitcherProps) {
  const { locale, pathname, query, asPath } = useRouter();

  const activeLanguage = languagesConfigByLocale?.[locale as TLocale];

  /**
   * To remove the already selected country and display the rest.
   */
  const selectableLanguages = languagesConfig.filter(
    (language) => language.locale !== locale
  );

  /**
   * For only 2 locales, will just toggle between, otherwise show a dropdown.
   */
  const isDuoLocale = selectableLanguages.length === 1;

  /**
   * If rendered inline besides other components, render it as as a smaller
   * pill/rounded, so it can fit in place.
   */
  const wrapperClass = isInline
    ? '!relative !right-auto !left-auto block 2xl:hidden'
    : 'hidden 2xl:block';

  /**
   * Reduce white space on the duo lang pill/button cta, as content is a bit
   * much less without the dropdown icon.
   */
  const duoLocaleClass = isDuoLocale ? 'w-20' : 'w-32';

  return (
    <div
      className={`
        font-primary-en ltr:font-primary-ar absolute right-20 z-[60]
        rtl:left-20 rtl:right-auto rtl:w-24 ${wrapperClass} ${duoLocaleClass} ${className}
      `}
    >
      {
        /**
         * Render a single nav link button to change the locale if only one extra
         * locale is present. Otherwise render a dropdown menu to choose from.
         */
        isDuoLocale ? (
          <SetLocaleCTA
            // asPath={asPath}
            className={localCTAClassName}
            href={{ pathname, query }}
            language={selectableLanguages[0]}
          />
        ) : (
          <LocaleMenu
            asPath={asPath}
            href={{ pathname, query }}
            items={selectableLanguages}
            value={activeLanguage}
          />
        )
      }
    </div>
  );
}
