import { createZustandStore } from '../../../zustand';

import { IDealsFilterState } from './types';
import { onClearDealsFilterStoreCallback } from './callbacks';

/**
 * This creates a local global deal filter state tracker and update the filter
 * values.
 */
export const useDealsFilterStore = createZustandStore<IDealsFilterState>()(
  (set) => ({
    dateRange: null,
    onUpdateDealsDateRange: (dateRange) => set(() => ({ dateRange })),
    selectedDealsFilters: [],
    updateSelectedFilters: (filters) =>
      set(() => ({ selectedDealsFilters: filters })),
    clearDealsStore: onClearDealsFilterStoreCallback(set),
  })
);
