import { TSetRegistration } from '../types';

import { persistRegistrationStore } from './registrationStoreLocalStorageUtils';

/**
 * This removes the registration session instance from the application state &
 * local storage.
 */
export function removeRegistrationSessionCallback(set: TSetRegistration) {
  return function updateRegistrationStore() {
    set(() => {
      const updatedRegistrationStoreFields = {
        registrationSession: null,
      };

      persistRegistrationStore(updatedRegistrationStoreFields);

      return updatedRegistrationStoreFields;
    });
  };
}
