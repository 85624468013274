import tw, { styled } from 'twin.macro';

/**
 * Styles for the `ActionBarButton` wrapper component.
 */
export const ActionBarButtonWrapper = styled.button`
  ${tw`
    relative
    inline-flex
    items-center
    border
    border-gray-200
    bg-white
    px-4
    py-2
    text-xs
    font-normal
    text-gray-600

    hover:(text-purple-600 bg-purple-50)
    focus:(z-10 outline-none ring-2 ring-purple-300)
  `};
  &.download-button {
    ${tw`rounded-l-md rtl:rounded-r-md`}
  }

  &.no-border-button {
    ${tw`
      flex
      flex-row-reverse
      justify-between
      border-none
      text-base
      pl-0
      pt-0
      pb-0
      text-purple-500
      bg-transparent

      hover:(text-purple-500)
      focus:(z-10 outline-none ring-0)
    `}
    span {
      ${tw`mr-2`}
    }
  }

  &.style-disabled {
    ${tw`border-gray-200 text-gray-400 bg-gray-50 cursor-not-allowed`};
  }

  .download-icon {
    ${tw`mr-2 h-5 w-5 shrink-0 stroke-purple-500 rtl:mr-0`}
  }
  .icon-disabled {
    ${tw`mr-2 h-5 w-5 shrink-0 stroke-gray-400 rtl:mr-0`}
  }

  span {
    ${tw`rtl:mr-2`};
  }
`;
