import { createZustandStore } from '../../../zustand';

import { IBidFilterState, ICategoryItem, IBrand } from './types';
import { onClearBidFilterStoreCallback } from './callbacks';

/**
 * This creates a local global bid filter state tracker and update the filter
 * values using its respective keys
 */
export const useBidFilterStore = createZustandStore<IBidFilterState>()(
  (set) => ({
    bidSearchKey: '',
    updateBidSearchKey: (newKey) => set(() => ({ bidSearchKey: newKey })),
    contextId: null,
    updateContextId: (newContextId) => set(() => ({ contextId: newContextId })),
    categoryBidFilterList: [],
    updateCategoryBidFilterList: (categories) =>
      set(() => ({ categoryBidFilterList: categories })),
    selectedBrandBidFilterList: [],
    updateSelectedBrandBidFilterList: (brands: IBrand[]) =>
      set(() => ({ selectedBrandBidFilterList: brands })),
    selectedCategoryBidFilter: null,
    updateSelectedCategoryBidFilter: (category: ICategoryItem | null) =>
      set(() => ({ selectedCategoryBidFilter: category })),
    hotDealsBidSearchKey: '',
    updateHotDealsBidsSearchKey: (newKey) =>
      set(() => ({ hotDealsBidSearchKey: newKey })),
    selectedHotDealsCategoryBidFilterIndex: -1,
    updateSelectedHotDealsCategoryBidFilterIndex: (categoryIndex: number) =>
      set(() => ({ selectedHotDealsCategoryBidFilterIndex: categoryIndex })),
    selectedHotDealsBrandIdBidFilterList: [],
    updateSelectedHotDealsBrandIdBidFilterList: (brandId) =>
      set(() => ({ selectedHotDealsBrandIdBidFilterList: [...brandId] })),
    selectedBidRankFilter: undefined,
    updateBidSelectedRankFilter: (rankType) =>
      set(() => ({ selectedBidRankFilter: rankType })),
    bidListSortFilterIndex: 0,
    updateBidListSortFilterIndex: (sortIndex: number) =>
      set(() => ({ bidListSortFilterIndex: sortIndex })),
    hotDealsBidListSortIndex: 1,
    updateHotDealsBidListSortFilterIndex: (sortIndex: number) =>
      set(() => ({ hotDealsBidListSortIndex: sortIndex })),
    clearBidsStore: onClearBidFilterStoreCallback(set),
  })
);
