import { Text } from '@northladder/i18n';

import { IModalAction } from './types';

interface IModalActionButtonProps {
  action: IModalAction;
}

/**
 * -----------------------------------------------------------------------------
 * This renders a button on the modal. That can either be highlighted in primary
 * colors for the primary action or white for the fallback tertiary action.
 */
export function ModalActionButton({ action }: IModalActionButtonProps) {
  const { className = '', Icon, label, onClick, tabIndex, type } = action;

  if (type === 'primary') {
    return (
      <button
        className={`
          form-btn z-[100] flex w-auto border border-gray-50 px-4 text-xs
          shadow-lg ${className}
        `}
        onClick={onClick}
        tabIndex={tabIndex}
        type="button"
      >
        {Icon ? <Icon className="h-5 px-1 text-white" /> : null}
        <Text text={label} />
      </button>
    );
  }

  return (
    <button
      className={`
        form-btn-secondary w-auto border border-gray-300 bg-white text-xs
        text-gray-900 ${className}
      `}
      onClick={onClick}
      tabIndex={tabIndex}
      type="button"
    >
      {Icon ? <Icon className="h-5 px-1 text-gray-900" /> : null}
      <Text text={label} />
    </button>
  );
}
