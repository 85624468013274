import { TSvgComponentProps } from '../../types';

/**
 * -----------------------------------------------------------------------------
 * IconBid component takes params to configure the IconBid SVG
 * @param {...props}- props support all the possible values for configuring
 * the svg element.
 *
 * TODO: Use the common SVGProps and move all icons to a common file
 *
 * @returns JSX.Element
 */
export function IconBid(props: TSvgComponentProps) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        className="stroke-white group-hover:fill-gray-400 group-hover:stroke-gray-400"
        d="M9.3 2.3c-.1 0-.1.1-.1.1-.4.3-.4.3-1 .9-.6.6-.6.6-.9 1l-.1.1c-1.8 1.9-1.6 4.9.1 6.8.7.8 1.4 1.6 2.7 2.9 1.3 1.3 2.1 2 2.9 2.7 1.9 1.7 4.9 1.8 6.9 0l.2-.2.8-.8.8-.8.2-.2c1.8-2 1.6-4.9 0-6.9C21 7.1 20.2 6.3 19 5c-1.3-1.3-2.1-2-2.9-2.7-1.9-1.6-4.9-1.8-6.8 0z"
        strokeWidth={1.5}
      />
      <path
        className="stroke-gray-400 group-hover:fill-gray-400 group-hover:stroke-purple-100"
        d="M2 18.8c1.5-1.5 6.7-5.9 6.7-5.9l2.4 2.4S6.6 20.6 5.2 22L2 18.8z "
      />
    </svg>
  );
}
