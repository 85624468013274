import { StrictMode, useState, useCallback, ReactNode } from 'react';
import { useRouter } from 'next/router';
import { GoogleMapsProvider as BaseGoogleMapsProvider } from '@ubilabs/google-maps-react-hooks';

import { TLocale } from '@northladder/i18n';

import { TMapNode, IBaseGoogleMapLocationOptions } from '../../types';
import { GOOGLE_MAPS_API_KEY, MAP_ORIGINS } from '../../constants';

type TNodeRef = (node: TMapNode) => void;

type TGoogleMapProviderProps = Pick<
  IBaseGoogleMapLocationOptions,
  'regionId' | 'regionKey'
> & {
  mapZoom?: number;
  renderMapContainer: (ref: TNodeRef) => ReactNode;
};

/**
 * -----------------------------------------------------------------------------
 * This is a wrapper around the base map provider component that abstract away
 * map setup logic.
 */
export function GoogleMapsProvider({
  renderMapContainer,
  regionKey,
  regionId,
  mapZoom,
}: TGoogleMapProviderProps) {
  const locale = useRouter().locale as TLocale;

  const [mapContainer, setMapContainer] = useState<TMapNode>(null);

  const mapRef = useCallback((node: TMapNode) => {
    if (node) {
      setMapContainer(node);
    }
  }, []);

  const coordinates = MAP_ORIGINS[regionId];
  const { lat, lng, zoom } = coordinates;

  /**
   * Add more map options here
   * `center` and `zoom` are required for every map to be displayed
   */
  const mapOptions = {
    center: { lat, lng },
    zoom: mapZoom || zoom,
    disableDefaultUI: false,
    zoomControl: true,
    zoomControlOptions: {
      position: 3, // Top right corner
    },
  };

  const googleMapsAPIParameters = {
    googleMapsAPIKey: GOOGLE_MAPS_API_KEY,
    language: locale, // 'ae', 'ksa' TODO: Revise this
    region: regionKey, // 'AE', 'SA', 'IQ', etc.
  };

  return (
    <BaseGoogleMapsProvider
      libraries={['places']}
      mapContainer={mapContainer}
      mapOptions={mapOptions}
      {...googleMapsAPIParameters}
    >
      <StrictMode>{renderMapContainer(mapRef)}</StrictMode>
    </BaseGoogleMapsProvider>
  );
}
