import { createZustandStore } from '../../../zustand';

import { IGradeRequestState } from './types';

/**
 * This creates a local global state for the grade change request
 */
export const useGradeChangeRequestStore =
  createZustandStore<IGradeRequestState>()((set) => ({
    gradeDefectIssues: [],
    selectedDealInfo: undefined,
    selectedGradeComponentReasons: [],
    updateSelectedGradeComponentReasons: (reasons) => {
      set(() => ({ selectedGradeComponentReasons: reasons }));
    },
    updateGradeDefectIssues: (reasons) => {
      set(() => ({ gradeDefectIssues: reasons }));
    },
    updateSelectedDealInfo: (dealInfo) => {
      set(() => ({ selectedDealInfo: dealInfo }));
    },
  }));
