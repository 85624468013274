import { useMemo, ChangeEvent, KeyboardEvent, FocusEvent } from 'react';

import { OTPValidate } from './constant';
import { InputFieldWrapper } from './styles';

export type TOtpInputFieldProps = {
  value: string;
  valueLength: number;
  onInputChange: (value: string) => void;
};

/**
 * -----------------------------------------------------------------------------
 * This is used to capture OTP.
 */
export function OtpInputField({
  value,
  valueLength,
  onInputChange,
}: TOtpInputFieldProps) {
  const valueItems = useMemo(() => {
    const valueArray = value.split('');
    const items: string[] = [];

    for (let i = 0; i < valueLength; i += 1) {
      const char = valueArray[i];

      if (OTPValidate.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }
    return items;
  }, [value, valueLength]);

  const focusOnNextInput = (target: HTMLElement) => {
    const nextElement = target.nextElementSibling as HTMLInputElement | null;

    if (nextElement) {
      nextElement.focus();
    }
  };

  const focusOnPrevInput = (target: HTMLElement) => {
    const previousElement =
      target.previousElementSibling as HTMLInputElement | null;

    if (previousElement) {
      previousElement.focus();
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>, idx: number) => {
    const { target } = e;
    let targetValue = target.value.trim();
    const isTargetValueDigit = OTPValidate.test(targetValue);

    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }

    const nextInputElement =
      target.nextElementSibling as HTMLInputElement | null;

    if (
      !isTargetValueDigit &&
      nextInputElement &&
      nextInputElement.value !== ''
    ) {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : ' ';

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue =
        value.substring(0, idx) + targetValue + value.substring(idx + 1);

      onInputChange(newValue);

      if (!isTargetValueDigit) {
        return;
      }

      focusOnNextInput(target);
    } else if (targetValueLength === valueLength) {
      onInputChange(targetValue);

      target.blur();
    }
  };

  const inputOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const target = e.target as HTMLInputElement;

    if (key === 'ArrowRight' || key === 'ArrowDown') {
      e.preventDefault();
      return focusOnNextInput(target);
    }

    if (key === 'ArrowLeft' || key === 'ArrowUp') {
      e.preventDefault();
      return focusOnPrevInput(target);
    }

    const targetValue = target.value;

    target.setSelectionRange(0, targetValue.length);

    if (e.key !== 'Backspace' || targetValue !== '') {
      return '';
    }

    focusOnPrevInput(target);
    return '';
  };

  const handleOnFocus = (e: FocusEvent<HTMLInputElement>) => {
    const { target } = e;
    // keep focusing back until previous input element has value
    const prevInputEl =
      target.previousElementSibling as HTMLInputElement | null;

    if (prevInputEl && prevInputEl.value === '') {
      return prevInputEl.focus();
    }

    target.setSelectionRange(0, target.value.length);
    return '';
  };

  return (
    <InputFieldWrapper>
      {valueItems.map((digit, index) => (
        <input
          // eslint-disable-next-line react/no-array-index-key
          key={`otp-key-${index}`}
          className="otp-input"
          inputMode="numeric"
          maxLength={valueLength}
          onChange={(event) => handleInputChange(event, index)}
          onFocus={handleOnFocus}
          onKeyDown={inputOnKeyDown}
          pattern="[0-9]*"
          type="text"
          value={digit}
        />
      ))}
    </InputFieldWrapper>
  );
}
