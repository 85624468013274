import {
  CloudArrowDownIcon,
  CloudArrowUpIcon,
} from '@heroicons/react/24/outline';

import IconArrowDown from './IconArrowDown';

const ActionBarIcons: any = {
  download: CloudArrowDownIcon,
  upload: CloudArrowUpIcon,
  iconArrowDown: IconArrowDown,
};

export default ActionBarIcons;
