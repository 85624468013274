import { TTextLocalized, Text } from '@northladder/i18n';

import { translations } from '../../../translations';
import { TUIError } from '../../../types';

import { PopoverMultiSelect } from '../popover-multi-select';
import { IPopoverMultiSelectProps } from '../popover-multi-select/PopoverMultiSelect';

import { TradeInDeviceDropdownWrapper } from './styles';

type ITradeInDevicesDropdownProps = Pick<
  IPopoverMultiSelectProps,
  'items' | 'error' | 'onPopoverItemSelect' | 'isModelsLoading' | 'id'
> & {
  isCategoriesLoading: boolean;
  label: TTextLocalized;
  subLabel: TTextLocalized;
  uiError?: TUIError;
};

/**
 * -----------------------------------------------------------------------------
 * This `TradeInDevicesDropdown` repurposes the PopoverMultiSelect dropdown.
 */
export function TradeInDevicesDropdown({
  id,
  items,
  error,
  onPopoverItemSelect,
  isCategoriesLoading,
  label,
  subLabel,
  uiError,
}: ITradeInDevicesDropdownProps) {
  const errorClass = uiError ? 'has-error' : '';

  return (
    <TradeInDeviceDropdownWrapper className={errorClass}>
      <div className="label-class">
        <label htmlFor={id || 'popover-multi-select'}>
          <Text text={label} />
        </label>
        <p className="sub-label-class">
          <Text text={subLabel} />
        </p>
      </div>

      <PopoverMultiSelect
        disabled={false}
        error={error}
        errorClassName={errorClass}
        id={id}
        isModelsLoading={isCategoriesLoading}
        items={items || []}
        onPopoverItemSelect={onPopoverItemSelect}
        placeholder={translations.tradeInDeviceDropdown.title}
        title={translations.tradeInDeviceDropdown.subtitle}
      />
      <div className="mt-2">
        {uiError ? <Text text={uiError.message} /> : null}
      </div>
    </TradeInDeviceDropdownWrapper>
  );
}
