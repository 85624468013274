import { useState } from 'react';
import { DocumentPlusIcon } from '@heroicons/react/24/outline';

import { Text, TTextLocalized } from '@northladder/i18n';

import { THeroIcon } from '../../types';
import { translations } from '../../translations';

import { ImagePickerModal } from './ImagePickerModal';

export interface IImageUploadOptions {
  className?: string;
  icon?: THeroIcon;
  iconClass?: string;
  id?: string;
  promptLabel?: TTextLocalized;
  subTitle?: TTextLocalized;
  title?: TTextLocalized;
  titlePostfix?: TTextLocalized;
}

interface IImageUploadProps extends IImageUploadOptions {
  isUploaded?: boolean;
  onResetUpload?: () => void;
  renderModalContent: ({
    onCloseModal,
  }: {
    onCloseModal: () => void;
  }) => JSX.Element;
}

/**
 * -----------------------------------------------------------------------------
 * This renders a single a section of UI that invites the user to start a document
 * upload process.
 */
export function ImageUploadPicker({
  className = '',
  icon,
  iconClass = '',
  id,
  promptLabel,
  subTitle,
  title,
  titlePostfix,
  isUploaded,
  onResetUpload,
  renderModalContent,
}: IImageUploadProps) {
  const Icon = icon || DocumentPlusIcon;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenPickerModal = () => {
    onResetUpload?.();
    setIsModalOpen(() => true);
  };

  const handleClosePickerModal = () => {
    setIsModalOpen(() => false);
  };

  return (
    <>
      <button
        className={`
          upload-trigger space-between xsm:flex-1 xsm:w-1/2 group relative block
          flex w-full flex-col items-center rounded-lg border border-dashed
          border-gray-200 bg-white text-center transition-all duration-300
          ease-in hover:border-purple-300 hover:bg-purple-50 focus:bg-purple-50
          focus:outline-none focus:ring-1 focus:ring-purple-300
          focus:ring-offset-2 sm:hover:scale-105
          ${className}
        `}
        id={id}
        onClick={handleOpenPickerModal}
        type="button"
      >
        <div className="flex flex-1 flex-col justify-center p-6">
          <Icon
            className={`
              mx-auto  h-10 w-10 self-center fill-transparent
              stroke-gray-300 stroke-1 group-hover:stroke-purple-300
              group-focus:stroke-purple-400 ${iconClass}
            `}
          />
          <span
            className="
              mt-2 block text-xs text-gray-400
              group-hover:text-purple-500 group-focus:text-purple-400
            "
          >
            <Text text={promptLabel || translations.documentUpload.uploadCTA} />
          </span>
        </div>
        <div
          className="
            flex w-full w-full flex-col px-4 py-2 text-sm text-gray-400
            group-focus:text-purple-400
          "
        >
          <p
            className="
              block flex w-full flex-col items-start pb-1 pt-2 text-sm
              text-gray-600 group-hover:text-purple-500 group-focus:text-purple-400
            "
          >
            {title ? (
              <span>
                <Text text={title} />
              </span>
            ) : null}

            {titlePostfix ? (
              <span className="text-xs font-light">
                <Text text={titlePostfix} />
              </span>
            ) : null}
          </p>

          <span
            className="
              flex  w-full flex-col text-start text-xs font-light
              text-gray-400 group-hover:text-purple-500 group-focus:text-purple-400
            "
          >
            {subTitle ? (
              <span className="py-1">
                <Text text={subTitle} />
              </span>
            ) : null}
          </span>
        </div>
      </button>

      {isUploaded ? null : (
        <ImagePickerModal
          isOpen={isModalOpen}
          onCloseModal={handleClosePickerModal}
          renderModalContent={renderModalContent}
        />
      )}
    </>
  );
}
