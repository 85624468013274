import { Switch } from '@headlessui/react';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export interface IToggleButtonProps {
  isActive: boolean;
  onHandleToggle: (value: boolean) => void;
}

/**
 * -----------------------------------------------------------------------------
 * A reusable headless ui toggle button.
 */
export function ToggleButton({ isActive, onHandleToggle }: IToggleButtonProps) {
  return (
    <Switch
      checked={isActive}
      className={classNames(
        isActive ? 'bg-purple-400' : 'bg-gray-200',
        '-z-1 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-1 focus:ring-purple-400 focus:ring-offset-2'
      )}
      onChange={onHandleToggle}
    >
      <span className="sr-only">Use setting</span>
      <span
        className={classNames(
          isActive
            ? 'translate-x-5 rtl:translate-x-0'
            : 'translate-x-0: rtl:-translate-x-5',
          'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            isActive
              ? 'opacity-0 duration-100 ease-out'
              : 'opacity-100 duration-200 ease-in',
            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
          )}
        >
          <svg
            className="h-3 w-3 text-gray-400"
            fill="none"
            viewBox="0 0 12 12"
          >
            <path
              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
            />
          </svg>
        </span>
        <span
          aria-hidden="true"
          className={classNames(
            isActive
              ? 'opacity-100 duration-500 ease-in'
              : 'opacity-0 duration-300 ease-out',
            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
          )}
        >
          <svg
            className="h-3 w-3 text-purple-700"
            fill="currentColor"
            viewBox="0 0 12 12"
          >
            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
          </svg>
        </span>
      </span>
    </Switch>
  );
}
