import { Fragment, useCallback, useRef, useState } from 'react';
import { MapPinIcon } from '@heroicons/react/20/solid';
import { Dialog, Transition } from '@headlessui/react';
import MapGL, { MapRef, NavigationControl } from 'react-map-gl';
import Geocoder from 'react-map-gl-geocoder';

import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';

import { Text } from '@northladder/i18n';

import { translations } from '../../../translations';

/**
 * Access token to use mapbox's map.
 */
const MAP_BOX_TOKEN = process.env.NEXT_PUBLIC_MAPBOX_TOKEN;

/**
 * Initial mapbox coordinates given to Map and displayed when the map is
 * rendered.
 */
export const INITIAL_MAP_COORDINATES = {
  latitude: 25.4241,
  longitude: 55.8478,
};

export interface IGpsCoordinates {
  latitude: number;
  longitude: number;
}

interface IGPSModalProps {
  initCoordinates: IGpsCoordinates;
  onUpdateCoordinates: (coordinates: IGpsCoordinates) => void;
}

interface IViewport extends IGpsCoordinates {
  zoom?: number;
}

/**
 * GPS Modal
 */
export function GPSModal({
  initCoordinates,
  onUpdateCoordinates,
}: IGPSModalProps) {
  const mapRef = useRef<MapRef>(null);

  const [isOpen, setIsOpen] = useState(false);

  const [viewport, setViewport] = useState<IViewport>({
    latitude: initCoordinates?.latitude || INITIAL_MAP_COORDINATES.latitude,
    longitude: initCoordinates?.longitude || INITIAL_MAP_COORDINATES.longitude,
    zoom: 8,
  });

  const handleViewportChange = useCallback(
    (newViewport: IViewport) => setViewport(newViewport),
    []
  );

  const handleGeocoderViewportChange = useCallback(
    (newViewport: IViewport) => {
      const geocoderDefaultOverrides = { transitionDuration: 1000 };

      return handleViewportChange({
        ...newViewport,
        ...geocoderDefaultOverrides,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleViewportChange]
  );

  const handleModalClose = () => {
    setIsOpen(false);
  };

  const handleModalOpen = () => {
    setIsOpen(true);
  };

  const handleModalConfirm = () => {
    onUpdateCoordinates(viewport);
    handleModalClose();
  };

  const handleLocationCancelled = () => {
    setViewport(initCoordinates);
    handleModalClose();
  };

  return (
    <>
      <h1 className="text-sm text-gray-700">
        <Text text={translations.gpsField.gpsLocation} />
      </h1>
      <button
        className="form-input-field !mt-1 inline-flex flex-wrap justify-between text-sm
          font-light text-gray-600"
        onClick={handleModalOpen}
        type="button"
      >
        {viewport.latitude !== INITIAL_MAP_COORDINATES.latitude ? (
          <>
            <p>{`Latitude: ${viewport.latitude}`}</p>
            <p>{`Longitude: ${viewport.longitude}`}</p>
          </>
        ) : (
          <p>
            <Text text={translations.gpsField.gpsPlaceholder} />
          </p>
        )}

        <MapPinIcon className="h-6 w-6 shrink-0" />
      </button>
      <Transition appear as={Fragment} show={isOpen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto border-4 border-purple-500"
          onClose={handleModalClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 min-h-screen">
            <div className="flex h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className="
                    h-full w-full transform overflow-hidden rounded-2xl bg-white
                    p-2 text-left align-middle shadow-xl transition-all
                  "
                >
                  <div>
                    <MapGL
                      ref={mapRef}
                      {...viewport}
                      height="80vh"
                      mapboxApiAccessToken={MAP_BOX_TOKEN}
                      mapStyle="mapbox://styles/mapbox/streets-v9"
                      onViewportChange={handleViewportChange}
                      width="100%"
                    >
                      <NavigationControl
                        className="
                          absolute bottom-0.5 left-auto right-2 z-10 rtl:bottom-0.5 rtl:left-2
                          rtl:right-auto
                        "
                      />
                      <Geocoder
                        mapboxApiAccessToken={MAP_BOX_TOKEN}
                        mapRef={mapRef}
                        onViewportChange={handleGeocoderViewportChange}
                        position="top-left"
                      />
                    </MapGL>
                  </div>

                  <div className="z-10 mt-4 flex justify-start">
                    <button
                      className="
                        inline-flex justify-center rounded-md border border-transparent
                        bg-purple-100 px-4 py-2 text-sm font-medium text-purple-900
                        hover:bg-purple-200 focus:outline-none focus-visible:ring-2
                        focus-visible:ring-purple-500 focus-visible:ring-offset-2 rtl:ml-2
                      "
                      onClick={handleModalConfirm}
                      type="button"
                    >
                      <Text text={translations.gpsField.confirmLocation} />
                    </button>
                    <button
                      className="ml-4 inline-flex justify-center rounded-md border
                      border-transparent bg-gray-100 px-4 py-2 text-sm font-medium
                      text-gray-900 hover:bg-gray-200
                    "
                      onClick={handleLocationCancelled}
                      type="button"
                    >
                      <Text text={translations.gpsField.cancelLocation} />
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
