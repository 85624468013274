import { TSvgComponentProps } from '../../types';

/**
 * -----------------------------------------------------------------------------
 * IconBank component takes params to configure the IconBank SVG
 * @param {...props}- props support all the possible values for configuring
 * the svg element.
 *
 * @returns JSX.Element
 */
export function IconBank(props: TSvgComponentProps) {
  return (
    <svg
      fill="none"
      height="53px"
      width="53px"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={25.5} cy={25.45} fill="#667085" r={25} />
      <path
        d="M15.5 21.45v4.001h1v6h-1v3h20v-3h-1v-6h1v-4l-10-6-10 6Zm4 10v-5.999h2v6h-2Zm5 0v-5.999h2v6h-2Zm7 0h-2v-5.999h2v6Zm-4-10a2.001 2.001 0 1 1-4.002-.001 2.001 2.001 0 0 1 4.002.001Z"
        fill="#fff"
      />
    </svg>
  );
}
