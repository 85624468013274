import { useState } from 'react';
import Image from 'next/image';
import { PhotoIcon } from '@heroicons/react/20/solid';

import { CircularLoader } from '../../loaders';

import { PreviewThumbnailContainer } from './styles';

interface IPreviewImageThumbnailProps {
  /**
   * The base url of the image hosting service.
   */
  basePath: string;
  /**
   * The url segment that resolved the image file.
   */
  filePath: string;
  /**
   * Callback to trigger a model that previews the file/image.
   */
  onPreview: (filePath: string) => void;
}

/**
 * -----------------------------------------------------------------------------
 * This shows a thumbnail preview of an image loaded from the path provided.
 */
export function PreviewImageThumbnail({
  basePath,
  filePath,
  onPreview,
}: IPreviewImageThumbnailProps) {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  if (!basePath) return null;

  const imageSrc = `${basePath}${filePath}`;

  const handleOnLoadImage = () => {
    setIsImageLoaded(true);
  };

  const handleOnClickPreview = () => {
    onPreview?.(imageSrc);
  };

  return (
    <PreviewThumbnailContainer
      className="group"
      onClick={handleOnClickPreview}
      role="button"
      tabIndex={0}
    >
      {!isImageLoaded ? (
        <>
          <PhotoIcon className="absolute h-16 w-16 text-gray-200" />
          <CircularLoader className="absolute h-8 w-8 text-gray-200 opacity-20" />
        </>
      ) : null}

      <Image
        alt=""
        height={200}
        onLoad={handleOnLoadImage}
        src={imageSrc}
        width={200}
      />
    </PreviewThumbnailContainer>
  );
}
