import { useState } from 'react';
import { FocusedInputShape, DateRangePicker as RangePicker } from 'react-dates';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { Moment, moment } from '@northladder/utilities';

import { DateRangePickerWrapper } from './styles';

/**
 * Interface of onDatesChange function.
 */
export interface IDatesRanges {
  /**
   * `startDate` is selected start date from the date range picker
   */
  startDate: Moment | null;
  /**
   * `endDate` is selected end date from the date range picker
   */
  endDate: Moment | null;
}

interface IDateRangePickerProps {
  selectedStartDate: Moment | null;
  selectedEndDate: Moment | null;
  onDatesChange: (selectedDates: IDatesRanges) => void;
}

/**
 * -----------------------------------------------------------------------------
 * This renders a range date-picker component for reuse across the app.
 * It accepts a start and end dates for the range.
 */
export function DateRangePicker({
  selectedEndDate,
  selectedStartDate,
  onDatesChange,
}: IDateRangePickerProps): JSX.Element {
  const [focusedInput, setFocusedInput] =
    useState<FocusedInputShape | null>(null);

  const handleOutsideRange = (day: Moment) => day.isAfter(moment());

  const handleOnFocusChange = (inputVal: FocusedInputShape | null) => {
    setFocusedInput(inputVal);
  };

  /**
   * This is to change the number of months the RangePicker displays. So on small
   * screens we will only show one calendar month as opposed to the two months we
   * display on the web.
   */
  const smallScreens = window.matchMedia('(max-width: 540px)').matches;
  const calendarMonthsDisplayed = smallScreens ? 1 : 2;

  return (
    <DateRangePickerWrapper>
      <RangePicker
        displayFormat="DD/MM/YYYY"
        enableOutsideDays
        endDate={selectedEndDate}
        endDateId="end_date"
        focusedInput={focusedInput}
        hideKeyboardShortcutsPanel
        inputIconPosition="after"
        isOutsideRange={handleOutsideRange}
        minimumNights={0}
        numberOfMonths={calendarMonthsDisplayed}
        onDatesChange={onDatesChange}
        onFocusChange={handleOnFocusChange}
        showClearDates
        showDefaultInputIcon
        small
        startDate={selectedStartDate}
        startDateId="start_date"
      />
    </DateRangePickerWrapper>
  );
}
