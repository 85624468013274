import { useCallback, useEffect, useState } from 'react';

import { TLocale } from '../types';

type TFlutterMessage =
  | { type: 'back'; language?: TLocale }
  | { type: 'otp'; value: string }
  | {
      type: 'whatsapp';
      number: string;
      message: string;
    }
  | {
      countryCode: '+971' | '+966' | '+964';
      email: string;
      language?: TLocale;
      number: string;
      type: 'login';
      status: 'EXISTING_MEMBER' | 'NEW_MEMBER';
    };

type TFlutterHandler = {
  postMessage: (message: string) => void;
} | null;

/**
 * This custom hook listens to changes on the windows object for the message
 * handler `flutterReactMessageHandler` assigned by the `Flutter` app and once
 * its detected and set, makes the app ready to communicate with the Flutter code.
 */
export function useFlutterMessageHandler() {
  const [isFlutterApp, setIsFlutterApp] = useState(false);

  let flutterReactMessageHandler: TFlutterHandler = null;

  if (typeof window !== 'undefined') {
    // @ts-ignore
    flutterReactMessageHandler = window?.flutterReactMessageHandler || null;
  }

  const handlePostMessageToFlutter = useCallback(
    (message: TFlutterMessage) => {
      if (flutterReactMessageHandler) {
        flutterReactMessageHandler.postMessage(JSON.stringify(message));
      }
    },
    [flutterReactMessageHandler]
  );

  useEffect(() => {
    if (flutterReactMessageHandler) {
      setIsFlutterApp(true);
    }
  }, [flutterReactMessageHandler]);

  return {
    isFlutterApp,
    postMessageToFlutter: handlePostMessageToFlutter,
  };
}
