import tw, { styled } from 'twin.macro';

export const StepContainer = styled.li`
  ${tw`
    relative
  `};

  &.is-last-item {
    ${tw`
      pr-12
      sm:pr-20

      rtl:(pr-0 pl-12)
      rtl:sm:(pr-0 pl-20)
    `};
  }

  .step-indicator-complete {
    ${tw`
      relative
      flex
      h-6
      w-6
      items-center
      justify-center
      rounded-full
      bg-purple-600
      hover:bg-purple-900
    `};
  }

  .step-indicator-current {
    ${tw`
      relative
      flex
      h-6
      w-6
      items-center
      justify-center
      rounded-full
      border-2
      border-purple-600
      bg-white
    `};
  }

  .step-indicator-upcoming {
    ${tw`
      relative
      flex
      h-6
      w-6
      items-center
      justify-center
      rounded-full
      border-2
      border-gray-300
      bg-white

      hover:border-gray-400
    `};
  }
`;
