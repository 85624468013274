import { VictoryPie, VictoryLabel } from 'victory';

interface IItem {
  title: string;
  desc: string;
}

/**
 * PieChart Props.
 */
interface IPieChartDataProps {
  items?: IItem[];
  label: string;
}
/**
 * -----------------------------------------------------------------------------
 * This renders a pie-chart component to display statistical data.
 */
export function PieChart({ items, label }: IPieChartDataProps) {
  const pieChartItems = items?.map((item) => {
    const desc = item.desc.split(' ')[0];
    const stats = Number(desc.replace('%', ''));
    return { x: item.title, y: stats };
  });

  return (
    <svg viewBox="0 0 400 400">
      <VictoryPie
        colorScale="blue"
        data={pieChartItems}
        height={400}
        innerRadius={98}
        labelRadius={120}
        standalone={false}
        style={{ labels: { fontSize: 10, fill: '#ccc' } }}
        width={400}
      />
      <VictoryLabel
        style={{ fontSize: 12 }}
        text={label}
        textAnchor="middle"
        x={200}
        y={200}
      />
    </svg>
  );
}
