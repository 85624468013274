import { TSvgComponentProps } from '../../../types';

/**
 * -----------------------------------------------------------------------------
 * This renders the badge that acts as the background element for the bid filter.
 *
 * @param TSvgComponentProps
 *
 * @returns JSX.Element
 */
export function IconBidBookBadge(props: TSvgComponentProps) {
  return (
    <svg
      className="h-6 w-6"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className="fill-purple-800 stroke-green-500"
        d="M14.1 22.5c-.1 0-.1 0-.2-.1l-1.1-.9c-.2-.2-.5-.3-.8-.3s-.6.1-.8.3l-1.1.9c-.1.1-.2.1-.2.1-.1 0-.2 0-.3-.2L9 21.1c-.2-.5-.7-.7-1.2-.7h-.4l-1.4.4c-.2 0-.4-.1-.4-.3V19c0-.6-.5-1.2-1.1-1.3l-1.4-.2c-.1 0-.2-.1-.2-.2s-.1-.2 0-.3l.5-1.3c.2-.6 0-1.3-.5-1.6l-1.2-.8c-.1-.1-.1-.2-.1-.2 0-.1 0-.2.1-.3l1-1c.5-.4.5-1.2.2-1.7l-.9-1c-.1-.1-.1-.2 0-.3 0 0 0-.1.2-.2l1.3-.5c.6-.2.9-.8.8-1.5l-.2-1.3c0-.1 0-.2.1-.3 0 0 .1-.1.2-.1l1.4.1c.7 0 1.2-.4 1.4-1l.3-1.4c0-.2.2-.2.3-.2h.1l1.2.7c.2.1.4.2.6.2.4 0 .8-.2 1-.5l.9-1.1c.1-.1.2-.1.3-.1s.2 0 .3.1l.9 1.1c.3.3.6.5 1 .5.2 0 .4-.1.6-.2l1.3-.7h.1c.1 0 .3.1.3.2l.4 1.4c.1.6.7 1 1.3 1l1.5-.1c.1 0 .2.1.3.1 0 0 .1.1.1.3l-.2 1.4c-.1.6.2 1.2.8 1.5l1.3.5c.1 0 .1.1.1.1v.3l-.8 1.2c-.4.5-.3 1.2.2 1.7l1 1c.1.1.1.2.1.3s0 .2-.1.2l-1.2.8c-.5.3-.8 1-.5 1.6l.5 1.3c.1.1 0 .2 0 .3s-.1.1-.2.2l-1.4.2c-.6.1-1.1.6-1.1 1.3l-.1 1.4c0 .2-.2.3-.3.3l-1.4-.4h-.4c-.5 0-1 .3-1.2.7l-.6 1.3h-.4z"
        fill="currentColor"
      />
      <path
        className="stroke-fill-purple-800 stroke-purple-900"
        d="m12 2.1.7.9c.3.4.9.7 1.4.7.3 0 .6-.1.8-.2L16 3l.3 1.1c.2.8.9 1.4 1.8 1.4h.1l1.2-.1-.2 1.2c-.1.9.3 1.7 1.2 2l1.1.4-.7 1c-.5.7-.4 1.7.2 2.3l.8.8-1 .6c-.7.5-1 1.4-.7 2.2l.4 1.1-1.2.2c-.9.1-1.5.9-1.6 1.7v1.2l-1.1-.3c-.2 0-.3-.1-.5-.1-.7 0-1.3.4-1.6 1l-.5 1.1-.9-.8c-.3-.3-.7-.4-1.2-.4s-.8.1-1.2.4l-.9.8-.5-1.1c-.3-.6-.9-1-1.6-1-.2 0-.3 0-.5.1l-1.1.3v-1.2c0-.9-.7-1.6-1.6-1.7L3.3 17l.5-1c.3-.8 0-1.7-.7-2.2l-1-.6.9-.9c.6-.6.7-1.6.2-2.3l-.7-1 1.1-.4c.8-.3 1.3-1.2 1.2-2l-.2-1.2 1.2.1h.1c.8 0 1.6-.6 1.8-1.4L8 3l1 .5c.3.1.6.2.8.2.6 0 1.1-.3 1.4-.7l.8-.9M12 1c-.2 0-.5.1-.6.3l-.9 1.1c-.2.2-.4.3-.6.3-.1 0-.3 0-.4-.1L8.2 2c-.1-.1-.2-.1-.4-.1-.3 0-.7.2-.8.6l-.3 1.4c-.1.3-.4.6-.8.6h-.1l-1.4-.1h-.1c-.5 0-.9.5-.8 1l.2 1.4c.1.4-.2.8-.5.9L2 8.2c-.5.1-.7.7-.4 1.2l.8 1.2c.2.3.2.8-.1 1l-1 1c-.4.4-.3 1 .1 1.3l1.2.8c.3.2.5.6.3 1L2.4 17c-.2.5.1 1 .6 1.1l1.4.2c.4.1.7.4.7.8l.1 1.4c0 .5.4.8.8.8h.2l1.4-.4h.2c.3 0 .6.2.7.5l.6 1.3c.2.1.5.3.8.3.2 0 .4-.1.5-.2l1.1-.9c.2-.1.3-.2.5-.2s.4.1.5.2l1.1.9c.2.1.3.2.5.2.3 0 .6-.2.7-.5l.6-1.3c.1-.3.4-.5.7-.5h.2l1.4.4h.2c.4 0 .8-.3.8-.8l.1-1.4c0-.4.3-.7.7-.8l1.4-.2c.5-.1.8-.6.6-1.1l-.5-1.3c-.1-.4 0-.8.3-1l1.2-.8c.4-.3.5-.9.1-1.3l-1-1c-.3-.3-.3-.7-.1-1l.8-1.2c.3-.4.1-1-.4-1.2l-1.3-.5c-.4-.1-.6-.5-.5-.9l.2-1.4c.1-.5-.3-1-.8-1h-.1l-1.4.1h-.1c-.4 0-.7-.3-.8-.6L17 2.5c-.1-.4-.4-.6-.8-.6-.1 0-.3 0-.4.1l-1.2.7c-.1.1-.3.1-.4.1-.2 0-.5-.1-.6-.3l-.9-1.1c-.2-.3-.5-.4-.7-.4z"
        fill="currentColor"
      />
    </svg>
  );
}
/**
 * -----------------------------------------------------------------------------
 * This renders the badge that acts as the background element for the bid filter.
 *
 * @param TSvgComponentProps
 *
 * @returns JSX.Element
 */
export function IconBidBookBadgeAlt(props: TSvgComponentProps) {
  return (
    <svg
      className="bid-badge-star"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <polygon
          points="7.9,22.1 5.5,21.7 4.2,19.6 2.1,18.2 1.8,15.8 0.5,13.6 1.2,11.3 0.9,8.8 2.5,7 3.1,4.7 5.4,3.5 7,1.7 9.4,1.6
            11.7,0.5 13.9,1.5 16.4,1.4 18.1,3.2 20.3,4.1 21.2,6.4 22.9,8.2 22.7,10.6 23.6,13 22.3,15.1 22.1,17.5 20.2,19.1 19.1,21.2
            16.7,21.8 14.7,23.4 12.3,22.9 9.9,23.5 "
        />
        <path
          d="M11.7,1.1l2.1,1l2.3-0.1l1.6,1.7l2.1,0.9l0.9,2.2l1.6,1.7l-0.2,2.2l0.9,2.2l-1.2,2l-0.2,2.3l-1.8,1.5l-1.1,2
            l-2.2,0.5l-1.9,1.5l-2.2-0.4L10,22.9L8,21.7l-2.2-0.4l-1.3-2l-1.9-1.3l-0.3-2.3l-1.3-2l0.7-2.2L1.4,9l1.5-1.7L3.6,5l2.1-1.1
            l1.5-1.7l2.3-0.1L11.7,1.1 M11.7-0.1l-0.4,0.2l-1.9,1L7.1,1.2l-0.4,0L6.4,1.6L5.1,3.1l-2,1L2.7,4.3L2.6,4.7L2,6.8L0.7,8.3L0.4,8.6
            l0.1,0.5l0.3,2.1l-0.7,2l-0.1,0.4l0.3,0.4l1.2,1.8l0.3,2.1l0.1,0.4L2,18.8l1.7,1.2L5,21.8l0.2,0.4l0.4,0.1l2,0.4l1.8,1.2L9.8,24
            l0.4-0.1l2.1-0.5l2,0.4l0.4,0.1l0.4-0.3l1.8-1.4l2-0.5l0.4-0.1l0.2-0.4l1-1.9l1.7-1.4l0.3-0.3l0-0.4l0.2-2.1l1.1-1.8l0.2-0.4
            l-0.2-0.4l-0.8-2l0.2-2l0-0.4l-0.3-0.3l-1.5-1.6l-0.8-2.1l-0.2-0.4l-0.4-0.2l-1.9-0.8l-1.5-1.5l-0.3-0.3l-0.5,0L14,1l-1.9-0.9
            L11.7-0.1L11.7-0.1z"
        />
      </g>
    </svg>
  );
}
/**
 * -----------------------------------------------------------------------------
 * This renders the badge that acts as the background element for the bid filter.
 *
 * @param TSvgComponentProps
 *
 * @returns JSX.Element
 */
export function Icon(props: TSvgComponentProps) {
  return (
    <svg
      className="h-6 w-6"
      fill="none"
      stroke="currentColor"
      strokeWidth={1.5}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
