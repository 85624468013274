import { TSvgComponentProps } from '../../types';

/**
 * -----------------------------------------------------------------------------
 * This renders a target icon used in location marker target CTAs.
 */
export function IconTarget(props: TSvgComponentProps) {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 12h3m14 0h3M12 2v3m0 14v3M12 19a7 7 0 1 0 0-14 7 7 0 0 0 0 14Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="fill-red-800"
        d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
}
