import tw, { styled } from 'twin.macro';

/**
 * Additional styles to display a floating whatsapp icon.
 */
export const WhatsAppIconWrapper = styled.div`
  ${tw`
    flex
    justify-end
    pt-2
  `};

  .floating-icon {
    ${tw`
      fixed
      bottom-2
      right-4
      z-20
      sm:(right-10 bottom-10)
    `}
  }
`;
