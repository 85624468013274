import { createContext, ReactNode, useState, useEffect, useMemo } from 'react';

import { DEFAULT_LOCALE, TLocale } from '@northladder/services';

import { useI18n } from './hooks/useI18n';
import { LocaleSwitcher } from './components/LocaleSwitcher';

const LOCAL_STORAGE_LOCALE_KEY = 'activeLocale';

interface ILocaleContextValue {
  /**
   * The currently active locale. Maybe passed as a config from the backend as
   * per target country, read from local storage or easily from the route path.
   */
  activeLocale: TLocale;
  /**
   * Function that can easily update the locale.
   *
   * @param locale - TLocale - The new locale to be updated to.
   */
  onUpdateLocale: (locale: TLocale) => void;
}

export const LocaleContext = createContext<ILocaleContextValue>({
  activeLocale: DEFAULT_LOCALE,
  onUpdateLocale: (locale: TLocale) => {
    localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, locale);
  },
});

interface ILocaleProviderProps {
  /**
   * The children components to consume this context. Usually rest of the app.
   */
  children: ReactNode;
  /**
   * Classes to be passed to the underlying `LocaleSwitcher` component.
   */
  className?: string;
  /**
   * Determines whether the switcher should be displayed as a pill or floating banner.
   */
  isLocaleSwitcherInline?: boolean;
  /**
   * Determines whether to show the floating pill in the top of the header
   */
  isGlobalLocaleSwitcherVisible?: boolean;
}

/**
 * This determines the initial local
 * - Checks for the locale from `cache` to see the most recent selected locale.
 * - Checks for the locale from the `pathname` if none is set.
 *
 * @returns TLocale
 */
const getInitialLocal = () => {
  const cachedLocale = (localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY) ||
    DEFAULT_LOCALE) as TLocale;
  return cachedLocale;
};

/**
 * -----------------------------------------------------------------------------
 * This wraps around the entire application to feed it with the active locale &
 * language context.
 *
 * @param options - ILocaleProviderProps
 * @returns JSX.Element
 */
export function LocaleProvider({
  children,
  className,
  isLocaleSwitcherInline,
  isGlobalLocaleSwitcherVisible = true,
}: ILocaleProviderProps) {
  const { locale } = useI18n();

  const [activeLocale, setActiveLocale] = useState<TLocale>(locale);

  const handleUpdateLocale = (targetLocale: TLocale) => {
    setActiveLocale(() => targetLocale);
  };

  useEffect(() => {
    const initialLocale = getInitialLocal();

    handleUpdateLocale(initialLocale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const memoizedValue = useMemo(
    () => ({ activeLocale, onUpdateLocale: handleUpdateLocale }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeLocale]
  );

  return (
    <LocaleContext.Provider value={memoizedValue}>
      {isGlobalLocaleSwitcherVisible ? (
        <LocaleSwitcher
          className={className}
          isInline={isLocaleSwitcherInline}
        />
      ) : null}
      {children}
    </LocaleContext.Provider>
  );
}
