import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { Text } from '@northladder/i18n';

import { TUIError } from '../../../types';
import { translations } from '../../../translations';

import { Button } from '../../buttons';
import { FormFeedbackSection } from '../../forms';

import { OtpInputField } from './OtpInputField';

interface IOTPInputCaptureProps {
  error: TUIError; // errorInValidateOTP
  isLoading: boolean; // isValidatingOTP
  onCloseModal: () => void;
  onSendOTP: () => void;
  onValidateOTP: (otp: string) => void;
  otpLength: number;
  phoneNumber: string;
}

export function OTPInputCapture({
  error,
  isLoading,
  onCloseModal,
  onSendOTP,
  onValidateOTP,
  otpLength,
  phoneNumber,
}: IOTPInputCaptureProps) {
  const [otp, setOtp] = useState('');
  const isFullLength = otp.replace(' ', '').length === otpLength;

  const onOtpChange = (value: string) => {
    setOtp(value);

    if (isFullLength) {
      onValidateOTP(otp);
    }
  };

  const handleValidateOTP = () => {
    onValidateOTP(otp);
  };

  const handleCloseModal = () => {
    setOtp('');
    onCloseModal();
  };

  const handleResendCode = () => {
    // TODO: Add count down timer to prevent spamming the OTP sending.
    onSendOTP();
  };

  return (
    <>
      <Dialog.Title
        as="h3"
        className="
          form-header flex justify-between py-4 text-center text-2xl
          font-light leading-6 text-purple-900
        "
      >
        <div />
        <Text text={translations.otpModal.inputCapture.title} />
        <XMarkIcon
          className="h-6 w-6 cursor-pointer"
          onClick={handleCloseModal}
        />
      </Dialog.Title>
      <div className="flex flex-col text-center" dir="ltr">
        <FormFeedbackSection
          className="text-center"
          description={translations.otpModal.inputCapture.description(
            phoneNumber
          )}
          isFormSubmitting={false}
          message={translations.otpModal.inputCapture.message}
          type="info"
        />

        <OtpInputField
          onInputChange={onOtpChange}
          value={otp}
          valueLength={6}
        />

        {error?.message ? (
          <FormFeedbackSection
            className="text-center"
            description={error.description || ''}
            isFormSubmitting={false}
            message={error.message}
            type="error"
          />
        ) : null}

        <p className="pb-4 pt-2 text-sm text-gray-400">
          <Text text={translations.otpModal.inputCapture.resendCodeMessage} />
          <button
            className="form-link-action px-2 text-purple-500"
            onClick={handleResendCode}
            type="button"
          >
            <Text text={translations.otpModal.inputCapture.resendCode} />
          </button>
        </p>
      </div>

      <Button
        isEnabled={isFullLength}
        isLoading={isLoading}
        label={translations.forms.validateOtp}
        loadingLabel={translations.forms.pleaseWait}
        onClick={handleValidateOTP}
        type="button"
      />
    </>
  );
}
