import { useEffect } from 'react';

interface IUseUpdateLocaleDirOptions {
  locale: 'en' | 'ar';
}

/**
 * -----------------------------------------------------------------------------
 * This provides re-usable logic for the `locale` and language config.
 * It retrieves the current locale from the `next router` path locale options
 * and uses it to determine the direction of the layout and active language and
 * forces the screen to update the layout when the locale `dir` changes.
 */
export const useUpdateLocaleDir = ({ locale }: IUseUpdateLocaleDirOptions) => {
  const dir = locale === 'ar' ? 'rtl' : 'ltr';

  useEffect(() => {
    /**
     * This effect forces the screen to update the `<html dir> every time the
     * value of `dir` is updated, forces the component to rerender and recompute
     * the layout.
     */
    document.documentElement.dir = dir;
  }, [dir]);
};
