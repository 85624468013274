import { IActiveSession, IUserTokenInfo, TSetAuthState } from '../types';

import { persistAuthStore } from './authStoreLocalStorageUtils';

/**
 * This updates the token information on the active session and matching userIds.
 * To be called on token revalidation/refresh.
 */
export function updateTokenInfoCallback(set: TSetAuthState) {
  return function updateAuthTokenInfo(userTokenInfo: IUserTokenInfo) {
    set(
      ({
        activeSession,
        activityLog,
        allSessionsById,
        allSessionsByIndex,
        allSessionsIndices,
      }) => {
        if (!activeSession || !allSessionsById) return { activeSession };

        const targetSession = allSessionsById[userTokenInfo.userId];

        /**
         * Get the session with matching `userId`. Note that we cannot rely on
         * the `activeSession`since it might be changed from a different browser
         * tab/window.
         */
        const updatedSession: IActiveSession = {
          ...targetSession,
          token: userTokenInfo.token,
          refreshToken: userTokenInfo.refreshToken,
          isExpired: userTokenInfo.isExpired,
        };

        /**
         * Update the normalized session info to update this to.
         */
        const updatedAllSessionsById = {
          ...allSessionsById,
          [userTokenInfo.userId]: updatedSession,
        };
        const updatedAllSessionsByIndex = {
          ...allSessionsByIndex,
          [updatedSession.index]: updatedSession,
        };

        /**
         * Since the token has been refreshed with a silent login, update this too.
         */
        const updatedActivityLog = {
          ...activityLog,
          lastLogOutDate: new Date().getTime(),
        };

        /**
         * Also update the active session if the userIds match.
         * Meaning, it's not been changed from another tab/window.
         */
        let updatedActiveSession = activeSession;

        if (updatedSession.userId === activeSession.userId) {
          updatedActiveSession = {
            ...activeSession,
            ...updatedSession,
          };
        }

        const persistState = {
          activityLog: updatedActivityLog,
          activeSession: updatedActiveSession,
          allSessionsById: updatedAllSessionsById,
          allSessionsByIndex: updatedAllSessionsByIndex,
          allSessionsIndices,
        };

        persistAuthStore(persistState);

        return {
          activityLog: updatedActivityLog,
          activeSession: updatedActiveSession,
          allSessionsById: updatedAllSessionsById,
          allSessionsByIndex: updatedAllSessionsByIndex,
        };
      }
    );
  };
}
