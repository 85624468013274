import { TSvgComponentProps } from '../../../types';

/**
 * -----------------------------------------------------------------------------
 * A sample logo for the NorthLadder Dealer app, made uniquely from the
 * NorthLadder logo elements.
 *
 * @param props TSvgComponentProps
 * @returns JSX.Element
 */
export default function LogoDealerNorthLadder(props: TSvgComponentProps) {
  return (
    <svg id="dealer-logo-light" viewBox="0 0 142 24" {...props}>
      <path
        className="fill-indigo-900"
        d="M77.3 3.3v17.3h12.1v-3h-8.9V3.3h-3.2z"
        id="L"
      />
      <g id="E-Ladder-2">
        <g id="Step1">
          <path
            className="fill-indigo-900"
            d="M23.6 20.5h12.6v-2.9H23.6v2.9z"
            id="Step1-Base"
          />
          <path
            className="fill-purple-300"
            d="M26.2 15.8h12.6l-2.6 1.6H23.6l2.6-1.6z"
            id="Step1-Top"
          />
        </g>
        <g id="Step-2">
          <path
            className="fill-indigo-900"
            d="M27.1 14.2h12.6v-2.9H27.1v2.9z"
            id="Step2-Base"
          />
          <path
            className="fill-purple-300"
            d="M29.7 9.7h12.6l-2.6 1.6H27.1l2.6-1.6z"
            id="Step2-Top"
          />
        </g>
        <g id="Step3">
          <path
            className="fill-indigo-900"
            d="M30.8 8.1h12.6v-3H30.8v3z"
            id="Step3-Base"
          />
          <path
            className="fill-purple-300"
            d="M33.4 3.4H46L43.4 5H30.8l2.6-1.6z"
            id="Step3-Top"
          />
        </g>
      </g>
      <path
        className="fill-indigo-900"
        d="m139.7 20.4-5.2-5.8c3.2-.6 4.3-3 4.3-5.4 0-3-2.2-5.7-6.2-5.7h-8.2v17.2h3.2V15h3.1l5 5.7h3.9l.1-.3c-.2 0 0 0 0 0zm-7.1-14.1c2.1 0 2.9 1.4 2.9 2.8s-.9 2.8-2.9 2.8h-5V6.3h5z"
        id="R"
      />
      <g id="A-NorthCompass">
        <path
          className="fill-indigo-900"
          d="m60.1 3.2 9.8 18.4-10.3-4.7-10.3 4.7L59.6 2.4l.5.8zm-.4 1.3-8 15 8-3.7V4.5z"
          id="A-Background"
        />
        <path
          className="fill-purple-300"
          d="M59.7 15.8V4.5l-8 15 8-3.7z"
          id="A-Highlight"
        />
      </g>
      <g id="E-Ladder-1">
        <g id="Step1">
          <path
            className="fill-indigo-900"
            d="M95.7 20.5h12.6v-2.9H95.7v2.9z"
            id="Step1-Base"
          />
          <path
            className="fill-purple-300"
            d="M98.3 15.8h12.6l-2.6 1.6H95.7l2.6-1.6z"
            id="Step1-Top"
          />
        </g>
        <g id="Step-2">
          <path
            className="fill-indigo-900"
            d="M99.2 14.2h12.6v-2.9H99.2v2.9z"
            id="Step2-Base"
          />
          <path
            className="fill-purple-300"
            d="M101.8 9.7h12.6l-2.6 1.6H99.2l2.6-1.6z"
            id="Step2-Top"
          />
        </g>
        <g id="Step3">
          <path
            className="fill-indigo-900"
            d="M102.9 8.1h12.6v-3h-12.6v3z"
            id="Step3-Base"
          />
          <path
            className="fill-purple-300"
            d="M105.5 3.4h12.6L115.5 5h-12.6l2.6-1.6z"
            id="Step3-Top"
          />
        </g>
      </g>
      <path
        className="fill-indigo-900"
        d="M8.4 3.3H1.6v17.3h6.8c6.1 0 8.7-4.4 8.7-8.8-.2-4.3-2.8-8.5-8.7-8.5zM4.8 6.4h3.6c3.8 0 5.3 2.7 5.4 5.4.1 2.8-1.5 5.6-5.4 5.6H4.8v-11z"
        id="D1"
      />
    </svg>
  );
}
