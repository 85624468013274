import { TRankIcon } from '../../../types';

import bidRankIcons from './icons';

interface IBidRankBadgeProps {
  icon: TRankIcon | (() => TRankIcon | null) | null;
}

/**
 * -----------------------------------------------------------------------------
 * Component Will accept icon string and return corresponding svg icon
 *
 * @param IBidRankBadgeProps - Expects icon name
 *
 * @returns JSX.Element
 */
export function BidRankBadge({ icon }: IBidRankBadgeProps) {
  const iconIndex = typeof icon === 'function' ? icon() : icon;
  if (iconIndex) {
    const RankIcon = bidRankIcons[iconIndex];
    return <RankIcon />;
  }
  return null;
}
