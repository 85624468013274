import { useRef } from 'react';

import { IDeviceInfo } from '../../types';

interface IUseDeviceInfoOptions {
  locale: 'en' | 'ar';
}

/**
 * -----------------------------------------------------------------------------
 * Retrieves the device info that is later posted alongside the auth requests.
 */
export const useDeviceInfo = ({ locale }: IUseDeviceInfoOptions) => {
  const deviceInfoRef = useRef<IDeviceInfo>();

  if (!deviceInfoRef.current?.make) {
    if (global?.navigator) {
      deviceInfoRef.current = {
        appVersion: '0.0.1',
        channel: 'DEALER_WEB_APP', // FCM
        locale,
        make: navigator.vendor,
        model: navigator.userAgent,
        platform: navigator.platform,
        token: 'fcm_token_not_set_for_web',
      };
    }
  }

  return deviceInfoRef.current;
};
