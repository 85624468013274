/* global google */

import { TMapOrigins } from '../types';

/**
 * This renders the Google Maps Business integration API key from the google
 * cloud console that was used to setup the project.
 *
 * - NOTE: Iam currently running this on a personal company account with my own
 * `credit card` details.
 * - TODO: Change this to the company subscriptions account.
 */
export const GOOGLE_MAPS_API_KEY =
  process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || '';

/**
 * This keeps track of the default map config origin like latitude and longitudes
 * that will be use to plot the base map based on the `regionId`.
 */
export const MAP_ORIGINS: TMapOrigins = {
  uae: {
    lat: 25.2253416,
    lng: 55.3947848,
    zoom: 11,
    title: 'United Arab Emirates',
  },
  ksa: {
    lat: 24.4452218,
    lng: 46.2827608,
    zoom: 5.5,
    title: 'Saudi Arabia',
  },
  iq: {
    lat: 32.9142938,
    lng: 45.1533847,
    zoom: 7,
    title: 'Iraq',
  },
  global: {
    lat: 32.9142938,
    lng: 45.1533847,
    zoom: 7,
    title: 'Global',
  },
};
