import Image from 'next/image';
import { useDropzone } from 'react-dropzone';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

import { TTextLocalized, Text } from '@northladder/i18n';
import { formatBytes } from '@northladder/utilities';

import { translations } from '../../translations';
import { TUIError } from '../../types';

import { CircularProgressLoader } from '../bid-book';

import { DropzoneWrapper, ModalButton } from './styles';

/**
 * Dropzone props
 */
export interface IDropzoneProps {
  /**
   * onDrop function to get the file that was dropped.
   */
  onDrop: (acceptedFiles: File[]) => void;
  /**
   * Loading state from the mutate hook.
   */
  isLoading: boolean;
  /**
   * error from the mutate hook.
   */
  error: TUIError;
  /**
   * Upload function.
   */
  onHandleUpload: () => void;
  /**
   * This is the user selected file for upload.
   */
  file: File | null;
  modalAcceptedFileText?: TTextLocalized;
  missingFileError?: TUIError;
}

/**
 * React-dropzone uses react hooks to create components for handling the
 * dragging and dropping of files.
 */
export function Dropzone({
  onDrop,
  isLoading,
  error,
  onHandleUpload,
  file,
  modalAcceptedFileText,
  missingFileError,
}: IDropzoneProps) {
  /**
   * The maximum size of the file, in MBs.
   *  - 15 * 1024 * 1024 (bytes to megabytes).
   */
  const MAX_UPLOAD_SIZE = 15728640;
  /**
   * The supported file types. Currently supports only (JPG/PNG) images.
   */
  const SUPPORTED_FILE_TYPES = ['.jpg', '.jpeg', '.png'];

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept: { 'image/png': SUPPORTED_FILE_TYPES },
    maxSize: MAX_UPLOAD_SIZE,
  });

  let convertedFileSize;

  if (file) {
    convertedFileSize = formatBytes(file.size);
  }

  return (
    <div>
      <div className="my-2 flex flex-col items-center">
        {isLoading ? (
          <div className="p-2">
            <CircularProgressLoader strokecolor="#904bbe" />
          </div>
        ) : null}

        {error ? (
          <div className="mx-4 flex flex-col items-center py-2 text-center">
            <ExclamationCircleIcon className="h-8 w-8 fill-red-500" />
            <Text className="text-red-500" text={error.message} />
            {error.description ? (
              <Text className="text-red-500" text={error.description} />
            ) : null}
          </div>
        ) : null}

        {file ? (
          <>
            <Image
              alt="uploaded image"
              height={200}
              src={URL.createObjectURL(file)}
              width={200}
            />
            <div className="flex justify-center text-center text-sm font-normal text-green-700">
              <p className="mx-5 my-4">{`${file.name} - ${convertedFileSize}`}</p>
            </div>
          </>
        ) : (
          <DropzoneWrapper>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <div className="text-center">
                {isDragActive ? (
                  <p>
                    <Text text={translations.documentUpload.modalCTA} />
                  </p>
                ) : (
                  <div>
                    <Text text={translations.documentUpload.modalDesc} />
                    {modalAcceptedFileText ? (
                      <p className="text-sm italic">
                        <Text text={modalAcceptedFileText} />
                      </p>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </DropzoneWrapper>
        )}
      </div>

      {isDragReject ? (
        <Text
          className="text-center text-red-500"
          text={translations.documentUpload.maxUploadSizeError}
        />
      ) : null}

      {fileRejections[0]?.errors ? (
        <Text
          className="flex justify-center px-6 text-sm text-red-500"
          text={translations.documentUpload.maxUploadSizeError}
        />
      ) : null}

      {missingFileError?.message ? (
        <p className="text-center text-sm text-red-500">
          <Text text={missingFileError.message} />
        </p>
      ) : null}

      <div className="flex justify-center p-4">
        <ModalButton onClick={onHandleUpload}>
          <Text text={translations.documentUpload.uploadButton} />
        </ModalButton>
      </div>
    </div>
  );
}
