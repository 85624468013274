import { TSvgComponentProps } from '../../../../types';

function LogoKSP({ width, height, ...props }: TSvgComponentProps) {
  return (
    <svg
      fill="none"
      height={height ?? '15em'}
      width={width ?? '15em'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M105.5 21.58s20.5 10.98 12.55 16.97C109.6 44.9 86.94 44.3 83.97 44a5.49 5.49 0 0 0-.58-.03c-2.09-.04-15.76-.5-35.6-5.45 0 0 19.32 6.03 35.97 7.56 15.42 1.42 30.05 1.64 38.05-2.84 5.2-2.9 4.52-6.46 3.42-8.6a8.38 8.38 0 0 0-1.97-2.45c-2.4-2.09-11.1-8.83-17.76-10.62Z"
          fill="#0076BB"
        />
        <path
          d="M22.8 25.98c0-4.93.04-9.86 0-14.79 0-.96.26-1.33 1.26-1.3 1.73.08 3.48.05 5.22.02.74-.02 1 .2 1 1-.02 3.81-.1 7.63.07 11.6l5.47-6.95c1.33-1.67 2.67-3.34 3.98-5.02.33-.42.7-.63 1.23-.63h7.65c.21 0 .5-.08.62.15.15.3-.18.44-.34.62-3.25 3.6-6.5 7.2-9.78 10.77-.52.56-.46.9-.06 1.47 3.26 4.55 6.5 9.13 9.71 13.71.44.62 1.88 1.6-.24 1.9-1.9.27-3.87 0-5.8.08-1.03.04-1.65-.32-2.21-1.18-2.28-3.5-4.63-6.96-6.94-10.44-.38-.58-.68-.7-1.16-.12a18.1 18.1 0 0 1-1.5 1.64c-.5.46-.63.97-.62 1.64.04 2.82 0 5.64.03 8.47 0 .9-.25 1.38-1.27 1.34a83.67 83.67 0 0 0-5.23 0c-.85.02-1.12-.3-1.1-1.14.03-2.89-.07-5.78.04-8.66.05-1.3-.03-2.48-.03-4.18Z"
          fill="url(#b)"
        />
        <path
          d="M113.44 25.25c-2.22 1.88-4.92 2.63-7.74 3.01-1.7.23-3.41.3-5.12.15-.68-.06-.83.18-.83.8.02 2.72-.02 5.45.03 8.17.02.94-.3 1.26-1.23 1.23-1.77-.06-3.55-.04-5.32 0-.72 0-1.06-.21-1.06-1 .02-8.8.02-17.59.01-26.39 0-.64.27-.95.89-1.02a68.4 68.4 0 0 1 12.26-.37c2.36.18 4.66.64 6.78 1.8 4.26 2.34 5.36 7.42 3.05 11.61a8.73 8.73 0 0 1-1.7 2h-.02Zm-4.75-5.45a3.79 3.79 0 0 0-2.4-4.54 11.5 11.5 0 0 0-5.82-.47c-.51.08-.72.32-.72.83l.01 6.9c0 .33-.01.72.4.79 2.55.4 5.02.3 7.2-1.33a3.8 3.8 0 0 0 1.33-2.18Z"
          fill="url(#c)"
        />
        <path
          d="M69.27 39a27.26 27.26 0 0 1-8.47-1.33c-.83-.28-1.17-.61-.82-1.53.4-1.03.7-2.1.92-3.18.2-.95.6-1 1.42-.67 2.52.98 5.15 1.42 7.86 1.36a6.95 6.95 0 0 0 3.35-.8c1.76-1 1.91-3.13.3-4.35a12.54 12.54 0 0 0-4.06-1.78 23.28 23.28 0 0 1-6.34-2.83c-4.88-3.27-4.21-9.5.73-12.34a15.34 15.34 0 0 1 6.63-1.96c3.27-.24 6.5 0 9.65 1.03.73.23.9.56.62 1.27a45.4 45.4 0 0 0-1.12 3.42c-.19.67-.49.77-1.12.53-2.2-.82-4.5-1.1-6.85-1.01-.98.04-1.92.24-2.78.74-1.76 1.02-1.83 2.82-.14 3.97 1.36.91 2.92 1.35 4.46 1.85 1.72.55 3.42 1.15 5 2.07 2.68 1.57 4.35 3.76 4.19 7.01-.17 3.25-2 5.4-4.78 6.8-2.72 1.36-5.65 1.75-8.65 1.74Z"
          fill="url(#d)"
        />
        <path
          d="M54.09 35.1a1.7 1.7 0 0 1 1.67 1.67 1.62 1.62 0 0 1-1.68 1.62c-.99 0-1.65-.68-1.64-1.64.01-.96.72-1.67 1.65-1.66Z"
          fill="url(#e)"
        />
        <path
          d="M84.52 34.92c.9 0 1.66.75 1.67 1.66.01.93-.7 1.63-1.67 1.63s-1.65-.68-1.66-1.63c0-.9.75-1.66 1.66-1.66Z"
          fill="url(#f)"
        />
        <path
          d="M25.09 28.08S0 14.45 7.95 8.45C16.4 2.1 39.07 2.7 42.03 3c.19.02.38.03.58.03 2.09.04 15.76.5 35.6 5.45 0 0-19.32-6.03-35.97-7.56C26.82-.51 12.19-.74 4.2 3.74-1 6.66-.33 10.21.77 12.34a8.4 8.4 0 0 0 1.97 2.46c2.4 2.09 15.69 11.5 22.35 13.28Z"
          fill="url(#g)"
        />
      </g>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="b"
          x1={36.29}
          x2={35.88}
          y1={41.45}
          y2={7.81}
        >
          <stop offset={0.06} stopColor="#F5A11D" />
          <stop offset={1} stopColor="#FFCA11" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="c"
          x1={104.41}
          x2={104}
          y1={40.62}
          y2={6.98}
        >
          <stop offset={0.06} stopColor="#F5A11D" />
          <stop offset={1} stopColor="#FFCA11" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="d"
          x1={71.37}
          x2={70.96}
          y1={41.02}
          y2={7.38}
        >
          <stop offset={0.06} stopColor="#F5A11D" />
          <stop offset={1} stopColor="#FFCA11" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="e"
          x1={54.15}
          x2={53.74}
          y1={41.23}
          y2={7.59}
        >
          <stop offset={0.06} stopColor="#F5A11D" />
          <stop offset={1} stopColor="#FFCA11" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="f"
          x1={84.58}
          x2={84.16}
          y1={40.86}
          y2={7.22}
        >
          <stop offset={0.06} stopColor="#F5A11D" />
          <stop offset={1} stopColor="#FFCA11" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="g"
          x1={2}
          x2={80.21}
          y1={14.04}
          y2={14.04}
        >
          <stop stopColor="#1E2757" />
          <stop offset={0.12} stopColor="#0076BA" />
        </linearGradient>
        <clipPath id="a">
          <path d="M0 0h126v47H0z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LogoKSP;
