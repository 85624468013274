import {
  TAppLanguage,
  TSetRegionState,
  TRegionInstance,
} from '../../region/types';

import { persistRegionStore } from './regionStoreLocalStorageUtils';

/**
 * This updates the active region based on the selected region in the UI.
 */
export function updateActiveRegionCallback(set: TSetRegionState) {
  return function updateRegionStore(updatedRegion: TRegionInstance) {
    set(() => {
      persistRegionStore({ region: updatedRegion });

      return { region: updatedRegion };
    });
  };
}

/**
 * This updates the app language based on the selected language in the UI.
 */
export function updateAppLanguageCallback(set: TSetRegionState) {
  return function updateRegionStore(updatedLanguage: TAppLanguage) {
    set(({ region }) => {
      persistRegionStore({ appLanguage: updatedLanguage, region });

      return { appLanguage: updatedLanguage };
    });
  };
}
