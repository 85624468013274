import { TRankIcons } from '../../../../types';

import IconFirstRank from './IconFirstRank';
import IconFlag from './IconFlag';

/**
 * Icons base for `BidRankBadge`. It will help to access icons.
 * eg( `bidRankIcons[firstRank]` )
 */
const bidRankIcons: TRankIcons = {
  firstRank: IconFirstRank,
  winningRange: IconFlag,
};

export default bidRankIcons;
