import tw, { styled } from 'twin.macro';

export const DateRangePickerWrapper = styled.div`
  .DateRangePickerInput {
    ${tw`py-0.5 rounded-md`};
  }

  .DateInput {
    ${tw`mx-2`};
    input {
      ${tw`p-1 font-normal`};
    }
  }

  .DateInput_input__focused {
    ${tw`border-purple-300 ring-0`};
  }

  .DateRangePickerInput_calendarIcon {
    ${tw`m-0`};
  }

  .CalendarDay__selected_span {
    ${tw`bg-purple-300 border border-gray-50`};
  }

  // Will edit selected date or the endpoints of a range of dates
  .CalendarDay__selected {
    ${tw`bg-purple-300 text-white border-gray-50`};
  }

  // Will edit when hovered over. _span style also has this property
  .CalendarDay__selected:hover {
    ${tw`bg-purple-400 text-white`};
  }

  // Will edit when the second date (end date) in a range of dates
  // is not yet selected. Edits the dates between your mouse and said date
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    ${tw`bg-purple-300 text-white border-gray-50`};
  }
`;
