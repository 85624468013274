import {
  loadLocalData,
  saveLocalData,
  STORAGE_KEY_REGISTRATION_STATE,
} from '@northladder/utilities';

import { IRegistrationState } from '../types';

export type IPersistRegistrationStoreTargetState = Pick<
  IRegistrationState,
  'registrationSession'
>;

/**
 * This persists the current registration session information to secure local
 * storage.
 */
export const persistRegistrationStore = (
  targetState: IPersistRegistrationStoreTargetState
) => {
  saveLocalData(STORAGE_KEY_REGISTRATION_STATE, targetState);
};

/**
 * This retrieves and decrypts the session information from secure local storage.
 */
export const loadPersistedRegistrationStore = () =>
  loadLocalData<IPersistRegistrationStoreTargetState>(
    STORAGE_KEY_REGISTRATION_STATE
  );
