import { TSvgComponentProps } from '../../../types';

/**
 * -----------------------------------------------------------------------------
 * This provides a light shader version of the North Ladder component is svg
 * for North Ladder logo which is customizable with some default values configured.
 * It is mainly used in Menu Items for navigation but also can be used elsewhere.
 *
 * TODO: Merge this with the dark version and only identify them by styles.
 *
 * @param props Supports all of the possible parameters for svg.
 *
 * @returns JSX.Element
 */
export default function LogoNorthladderLight({
  width,
  height,
  ...props
}: TSvgComponentProps) {
  return (
    <svg
      height={height ?? '15em'}
      id="North_Ladder_Logo"
      viewBox="0 0 264 24"
      width={width ?? '15em'}
      x={0}
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      y={0}
      {...props}
    >
      <style>
        {
          '.st1,.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#fff}.st2{fill:#d9c0ff}'
        }
      </style>
      <g id="North_Ladder_-_LOGO_-_Light_1_">
        <g id="North_1_">
          <path
            className="st1"
            d="M12.8 14 4.3 3.3H1.6v17.3h3.2V8.9L14 20.6h2V3.3h-3.2V14z"
            id="N_1_"
          />
          <path
            className="st1"
            d="M34.3 3c-6 0-8.9 4.5-8.9 9.1s2.8 8.9 8.9 8.9 8.8-4.4 8.9-8.9c0-4.6-3.1-9.1-8.9-9.1zm0 3c4.1 0 5.7 3.2 5.6 6.2 0 2.8-1.6 5.8-5.6 5.8s-5.6-3-5.6-5.8c-.1-3 1.5-6.2 5.6-6.2z"
            id="O_1_"
          />
          <path
            className="st1"
            d="m67.7 20.4-5.2-5.8c3.2-.6 4.3-3 4.3-5.4 0-3-2.2-5.7-6.2-5.7h-8.2v17.2h3.2V15h3.1l5 5.7h3.9c.1-.1.1-.3.1-.3zm-7-14.1c2.1 0 2.9 1.4 2.9 2.8s-.9 2.8-2.9 2.8h-5V6.3h5z"
            id="R_3_"
          />
          <path
            className="st1"
            d="M81.3 20.6h3.2V6.3H90v-3H75.8v2.9h5.5v14.4z"
            id="T_1_"
          />
          <path
            className="st1"
            d="M113.8 20.6V3.4h-3.2v7.2h-8.4V3.4H99v17.2h3.2v-6.9h8.4v6.9h3.2z"
            id="H_1_"
          />
        </g>
        <g id="Ladder_1_">
          <path
            className="st1"
            d="M128.8 3.3v17.3h12.1v-3H132V3.3h-3.2z"
            id="L_1_"
          />
          <g id="A-NorthCompass_1_">
            <path
              className="st1"
              d="m158.3 3.2 9.8 18.4-10.3-4.7-10.3 4.7 10.3-19.2.5.8zm-.4 1.3-8 15 8-3.7V4.5z"
              id="A-Background_1_"
            />
            <path
              className="st2"
              d="M157.9 15.8V4.5l-8 15 8-3.7z"
              id="A-Highlight_1_"
            />
          </g>
          <path
            className="st1"
            d="M182.9 3.3h-6.8v17.3h6.8c6.1 0 8.7-4.4 8.7-8.8-.2-4.3-2.8-8.5-8.7-8.5zm-3.6 3.1h3.6c3.8 0 5.3 2.7 5.4 5.4.1 2.8-1.5 5.6-5.4 5.6h-3.6v-11z"
            id="D1_1_"
          />
          <path
            className="st1"
            d="M207.1 3.3h-6.8v17.3h6.8c6.1 0 8.7-4.4 8.7-8.8-.2-4.3-2.8-8.5-8.7-8.5zm-3.5 3.1h3.6c3.8 0 5.3 2.7 5.4 5.4.1 2.8-1.5 5.6-5.4 5.6h-3.6v-11z"
            id="D2_1_"
          />
          <g id="E-Ladder_1_">
            <g id="Step1_1_">
              <path
                className="st1"
                d="M220.1 20.5h12.6v-2.9h-12.6v2.9z"
                id="Step1-Base_1_"
              />
              <path
                className="st2"
                d="M222.7 15.8h12.6l-2.6 1.6h-12.6l2.6-1.6z"
                id="Step1-Top_1_"
              />
            </g>
            <g id="Step-2_1_">
              <path
                className="st1"
                d="M223.6 14.2h12.6v-2.9h-12.6v2.9z"
                id="Step2-Base_1_"
              />
              <path
                className="st2"
                d="M226.2 9.7h12.6l-2.6 1.6h-12.6l2.6-1.6z"
                id="Step2-Top_1_"
              />
            </g>
            <g id="Step3_1_">
              <path
                className="st1"
                d="M227.3 8.1h12.6v-3h-12.6v3z"
                id="Step3-Base_1_"
              />
              <path
                className="st2"
                d="M229.9 3.4h12.6L239.9 5h-12.6l2.6-1.6z"
                id="Step3-Top_1_"
              />
            </g>
          </g>
          <path
            className="st1"
            d="m262.4 20.4-5.2-5.8c3.2-.6 4.3-3 4.3-5.4 0-3-2.2-5.7-6.2-5.7h-8.2v17.2h3.2V15h3.1l5 5.7h3.9v-.3zm-7.1-14.1c2.1 0 2.9 1.4 2.9 2.8s-.9 2.8-2.9 2.8h-5V6.3h5z"
            id="R_2_"
          />
        </g>
      </g>
    </svg>
  );
}
