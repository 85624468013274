import { useFlutterMessageHandler } from '@northladder/utilities';

import { IconWhatsapp } from '../../icons';

import { WhatsAppIconWrapper } from './styles';

interface IWhatsAppProps {
  mobileNumber: string;
}

/**
 * -----------------------------------------------------------------------------
 * This component shows a floating whatsapp icons.
 */
export function WhatsApp({ mobileNumber }: IWhatsAppProps) {
  const { isFlutterApp, postMessageToFlutter } = useFlutterMessageHandler();

  const message =
    'Hi NorthLadder Customer Happiness Team. I need your help with the dealer registration process.';

  const handleClick = () => {
    if (isFlutterApp) {
      postMessageToFlutter({
        type: 'whatsapp',
        number: mobileNumber,
        message,
      });
    } else {
      const whatsappUrl = `https://api.whatsapp.com/send?phone=${mobileNumber}&text=${message}&source=&data=`;
      const whatsappWindow = window.open(
        whatsappUrl,
        '_blank',
        'noopener,noreferrer'
      );
      if (whatsappWindow) whatsappWindow.opener = null;
    }
  };

  return (
    <WhatsAppIconWrapper>
      <button className="floating-icon" onClick={handleClick} type="button">
        <IconWhatsapp />
      </button>
    </WhatsAppIconWrapper>
  );
}
