import { createZustandStore } from '../../zustand';

import { IRegistrationState } from './types';

import {
  addRegistrationSessionCallback,
  loadPersistedRegistrationStore,
  updateRegistrationSessionCallback,
  removeRegistrationSessionCallback,
  updateRegistrationTokenInfoCallback,
} from './callbacks';

/**
 * This creates a local session state tracker and update callbacks to track all
 * the concurrent active registration sessions, the activity logs & device information.
 */
export const useRegistrationStore = createZustandStore<IRegistrationState>(
  (set) => {
    const localRegData = loadPersistedRegistrationStore();

    return {
      registrationSession: localRegData?.registrationSession || null,
      addRegistrationSession: addRegistrationSessionCallback(set),
      removeRegistrationSession: removeRegistrationSessionCallback(set),
      updateRegistrationSession: updateRegistrationSessionCallback(set),
      updateRegistrationTokenInfo: updateRegistrationTokenInfoCallback(set),
    };
  }
);
