import { IUserTokenInfo } from '../../authentication';

import { IActiveRegistration, TSetRegistration } from '../types';

import { persistRegistrationStore } from './registrationStoreLocalStorageUtils';

/**
 * This updates the token information on the session.
 * To be called on token revalidation/refresh.
 */
export function updateRegistrationTokenInfoCallback(set: TSetRegistration) {
  return function updateRegistrationTokenInfo(userTokenInfo: IUserTokenInfo) {
    set(({ registrationSession }) => {
      if (!registrationSession) {
        return { registrationSession };
      }

      /**
       * Updated token and refresh token is stored in `updatedRegistrationSession`.
       */
      const updatedRegistrationSession: IActiveRegistration = {
        ...registrationSession,
        token: userTokenInfo.token,
        refreshToken: userTokenInfo.refreshToken,
      };

      const persistState = {
        registrationSession: updatedRegistrationSession,
      };

      persistRegistrationStore(persistState);

      return {
        registrationSession,
      };
    });
  };
}
