/**
 * This custom hook listens to NEXT_PUBLIC_IS_REGISTRATION_ONLY in the env and once
 * its detected and set, we use it to hide the things not necessary for registration
 * screens.
 */
export function useRegistrationOnly() {
  const isRegistrationOnly =
    process.env.NEXT_PUBLIC_IS_REGISTRATION_ONLY === 'true';
  return isRegistrationOnly;
}
