import { TSvgComponentProps } from './types';

/**
 * -----------------------------------------------------------------------------
 * This renders a rectangular Global  Flag SVG component so it can easily be
 * customized at runtime. This will be used for international selections.
 *
 * @param {{className: string, size: number}}
 */
export function FlagGlobal({
  className,
  size = 32,
  ...rest
}: TSvgComponentProps) {
  const strokeClass =
    'fill-transparent stroke-purple-900 fill-none stroke-[10px] group-hover:stroke-[16px]';

  return (
    <svg
      className={`group ${className}`}
      height={size}
      id="FlagGlobal"
      viewBox="0 0 512 340"
      width={size * 1.5}
      {...rest}
    >
      <g id="global">
        <path
          className={`fill-purple-700 ${className}`}
          d="M0 0h512v340H0z"
          id="background"
        />
        <circle
          className={strokeClass}
          cx={256}
          cy={170}
          id="ellipse-outer"
          r={84.7}
        />
        <path
          className={strokeClass}
          d="M256 84.3v169.4c-26 0-46.9-37.8-46.9-84.7S230 84.3 256 84.3s46.9 37.8 46.9 84.7-20.9 84.7-46.9 84.7"
          id="ellipse-center"
        />
        <path
          className={strokeClass}
          d="M328.6 212.4H183.4"
          id="horizontal-bar-3"
        />
        <path
          className={strokeClass}
          d="M340.7 170H171.3"
          id="horizontal-bar-2"
        />
        <path
          className={strokeClass}
          d="M328.6 127.6H183.4"
          id="horizontal-bar-1"
        />
      </g>
    </svg>
  );
}
