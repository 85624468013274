import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';

/**
 * `TMenuOption` defines the option type for kebab menu.
 */
type TMenuOption = {
  /**
   * Title for the option.
   */
  title: string;
  /**
   * Callback for the option to give functionality such as redirect etc.
   */
  onClick: () => void;
};

/**
 * `IKebabMenuProps` defines the props for KebabMenu.
 */
interface IKebabMenuProps {
  /**
   * menuOptions refers to the definition of kebab menu options.
   */
  menuOptions: TMenuOption[];
}
/**
 * -----------------------------------------------------------------------------
 * This renders a simple kebab menu with onClick callback support.
 *
 * @param IKebabMenuProps - Refer the type for the props description.
 *
 * @returns JSX.Element
 */
export function KebabMenu({ menuOptions }: IKebabMenuProps) {
  return (
    <Menu as="div" className="relative">
      <Menu.Button
        key="extra-actions-dropdown"
        className="
          flex shrink-0 rounded-full p-1 text-sm text-gray-400
          transition-all duration-500 hover:bg-purple-50 hover:text-purple-400
          focus:outline-none focus:ring-2  focus:ring-purple-200 focus:ring-offset-2
        "
      >
        <span className="sr-only">View More Actions</span>
        <EllipsisVerticalIcon aria-hidden="true" className="h-6 w-6" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="
            absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white
            py-1 shadow-lg ring-1 ring-gray-600 ring-opacity-5
            focus:outline-none rtl:left-0 rtl:right-auto
          "
        >
          {menuOptions?.length > 0 ? (
            menuOptions.map((option: TMenuOption) => (
              <Menu.Item key={option.title}>
                <button
                  className="drop-down-item"
                  onClick={option.onClick}
                  type="button"
                >
                  {option.title}
                </button>
              </Menu.Item>
            ))
          ) : (
            <Menu.Item>
              <span>No data available</span>
            </Menu.Item>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
