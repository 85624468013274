import tw, { styled } from 'twin.macro';

/**
 * `DropdownWrapper` adds styling to the customized headless UI dropdown.
 *
 * @returns JSX.Element
 */
export const DropdownWrapper = styled.div`
  ${tw`w-full grow text-right`};
  .dropdown-menu {
    ${tw`inline-block text-left w-full`};
  }
  &.quantity-dropdown-menu {
    ${tw`w-20`};
  }
  .dropdown-button {
    ${tw`inline-flex w-full justify-between rounded-md bg-white px-4
      py-2.5 text-sm font-medium text-gray-600 border shadow-sm
      focus:text-purple-600 hover:(border-purple-400 text-purple-600)
      focus:border-purple-400 focus:outline-none  focus-visible:ring-2
      focus-visible:ring-purple-400 focus-visible:ring-opacity-75
      disabled:(border-gray-200 text-gray-300 bg-gray-50 cursor-not-allowed)
    `};

    .arrow-down {
      ${tw`shrink-0 ml-1 h-5 w-5 text-gray-400`};
    }
  }
`;

/**
 * `DropDownMenuItemsWrapper` adds styling to the customized headless UI
 * menu items.
 *
 * @returns JSX.Element
 */
export const DropDownMenuItemsWrapper = styled.div`
  ${tw`relative z-10`};

  .menu-item-list {
    ${tw`absolute right-0 w-full origin-top-right divide-y divide-gray-100
      rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5
      focus:outline-none overflow-y-scroll max-h-64
    `};

    .menu-item {
      ${tw`flex w-full items-center rounded-md px-2 py-2 text-sm text-gray-500
      hover:text-purple-600 font-medium`};

      .item-icon {
        ${tw`mr-2 h-5 w-5`};
      }
    }

    .active-item {
      ${tw`bg-purple-50`};
    }
  }

  .quantity-menu-item-list {
    ${tw`absolute z-10 right-0 w-full origin-top-right divide-y divide-gray-100
      rounded-md bg-white shadow-lg ring-2 ring-black ring-opacity-5
      focus:outline-none overflow-y-scroll max-h-32
    `}
    .menu-item {
      ${tw`relative z-10 flex w-full items-center rounded-md px-2 py-2 text-sm text-gray-500
      hover:text-purple-600 font-normal`};

      /* .item-icon {
        ${tw`mr-2 h-5 w-5`};
      } */
    }
    .active-item {
      ${tw`bg-purple-50`};
    }
  }
`;

/**
 * `MultiSelectDropdownWrapper` adds styling to the customized headless UI dropdown.
 *
 * @returns JSX.Element
 */
export const MultiSelectDropdownWrapper = styled.div`
  ${tw`w-full text-right z-20`};

  &.popover-wrapper {
    ${tw`w-full grow text-right z-20`};
  }

  .multi-select-dropdown {
    ${tw`relative inline-block text-left w-full rtl:text-right`};
  }

  .multi-select-button {
    ${tw`inline-flex w-full justify-between px-2 items-center rounded-md
     bg-white text-sm font-medium text-gray-600 border shadow-sm
     focus:text-purple-600 hover:border-purple-400
     focus:border-purple-400 focus:outline-none focus-visible:ring-2
     focus-visible:ring-purple-400 focus-visible:ring-opacity-75 z-10
   `};

    &.select-disabled {
      ${tw`border-gray-200 text-gray-300 bg-gray-50 cursor-not-allowed`};
    }

    .hide-button-outline {
      ${tw`focus-visible:outline-none`};
    }

    .arrow-down {
      ${tw`ml-2 h-5 w-5 text-gray-400`};
    }

    .list-box-label {
      ${tw`
        w-full
        py-2.5
        px-2
        text-start
        hover:text-purple-600
      `};

      &.disable-label-class {
        ${tw`
          border-gray-200
          text-gray-300
          bg-gray-50
        `};
      }
    }

    .selected-item {
      ${tw`mx-1 my-1.5 py-1 px-1 flex flex-row bg-gray-100 rounded
       hover:text-purple-600`};

      .cross-icon {
        ${tw`ml-2 h-5 w-5 text-black`};
      }
    }
  }
`;

/**
 * `ListBoxOptionsWrapper` adds styling to the customized headless UI dropdown
 *  list box options.
 *
 * @returns JSX.Element
 */
export const ListBoxOptionsWrapper = styled.div`
  &.popover-list,
  .list-box-items {
    ${tw`absolute left-0 mt-2 w-full origin-top-right divide-y
     divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black
     ring-opacity-5 focus:outline-none z-20
   `};

    &.popover-list {
      ${tw`mt-0 p-3 divide-none max-h-80 overflow-y-scroll`};
    }

    .list-item {
      ${tw`flex w-full items-center rounded-md px-2 py-3 text-sm
       text-gray-900 hover:text-purple-600`};
    }

    .popover-label {
      ${tw`my-2 text-gray-700 font-medium text-sm`};
    }

    .options-grid-layout {
      ${tw`
        grid
        grid-cols-2
      `};
    }

    .dropdown-checkbox-wrapper {
      ${tw`flex items-center my-2.5`}
      label {
        ${tw`px-3 text-gray-500 font-normal text-sm`}
      }
    }
  }
`;
