import tw, { styled } from 'twin.macro';

export const CropContainer = styled.div`
  ${tw`
    relative
    absolute
    left-0
    flex
    h-full
    w-full
    flex-col
  `};

  .ReactCrop__crop-selection {
    /* position: 'absolute'; */
    border: solid 5px red;
    /* height: '100%'; */
    margin-top: -1rem;
    margin-bottom: 1rem;
  }
`;
