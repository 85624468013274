import { IActiveRegistration, TSetRegistration } from '../types';

import {
  IPersistRegistrationStoreTargetState,
  persistRegistrationStore,
} from './registrationStoreLocalStorageUtils';

/**
 * This updates the registration session in both local & app memory.
 */
export function updateRegistrationSessionCallback(set: TSetRegistration) {
  return function updateRegistrationStore(registration: IActiveRegistration) {
    set(() => {
      const updatedRegistrationStoreFields: IPersistRegistrationStoreTargetState =
        {
          registrationSession: registration,
        };

      persistRegistrationStore(updatedRegistrationStoreFields);

      return updatedRegistrationStoreFields;
    });
  };
}
