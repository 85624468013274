import { createZustandStore } from '../../../zustand';

import { IBidDetailsState } from './types';
import {
  loadPersistedBidDetailStore,
  persistBidDetailsStore,
} from './bidDetailsLocalStorageUtils';

/**
 * This creates a local global search key state tracker and update the search
 * key using `setSearchKey`
 */
export const useBidDetailsStore = createZustandStore<IBidDetailsState>()(
  (set) => {
    const persistedAssetModel = loadPersistedBidDetailStore();
    return {
      activeAssetDetails: undefined,
      updateActiveAssetDetails: (assetDetails) => {
        set(() => ({ activeAssetDetails: assetDetails }));
      },
      activeAssetModel: persistedAssetModel?.activeAssetModel,
      updateActiveAssetModel: (asset) => {
        persistBidDetailsStore({ activeAssetModel: asset });

        set(() => ({ activeAssetModel: asset }));
      },
      bidUpdatedSuccessfully: false,
      updateBidUpdatedSuccessfully: (flag) =>
        set(() => ({ bidUpdatedSuccessfully: flag })),
    };
  }
);
