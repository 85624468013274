import {
  loadLocalData,
  saveLocalData,
  STORAGE_ACTIVE_ASSET_MODEL_STATE,
} from '@northladder/utilities';

import { IBidDetailsState } from './types';

export type IPersistBidDetailsState = Pick<
  IBidDetailsState,
  'activeAssetModel'
>;

/**
 * This persists the region store  to secure local storage.
 */
export const persistBidDetailsStore = (
  targetState: IPersistBidDetailsState | undefined
) => {
  saveLocalData(STORAGE_ACTIVE_ASSET_MODEL_STATE, targetState);
};

/**
 * This retrieves and decrypts the region store info from secure local storage.
 */
export const loadPersistedBidDetailStore = () =>
  loadLocalData<IPersistBidDetailsState>(STORAGE_ACTIVE_ASSET_MODEL_STATE);
