import Link from 'next/link';

import { Text } from '@northladder/i18n';

import { translations } from '../../../translations';

import { LogoNorthLadder } from '../../brand';

interface IPublicFooterProps {
  showFooterNav?: boolean;
}

/**
 * -----------------------------------------------------------------------------
 * The main footer of the public facing applications.
 */
export function PublicFooter({ showFooterNav = true }: IPublicFooterProps) {
  return (
    <footer className="overflow-hidden bg-purple-50">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        {showFooterNav ? (
          <div className="hidden py-16 sm:block">
            <Link
              aria-label="Home"
              className="
              hidden max-w-[80%] focus:ring-offset-4 focus:ring-offset-purple-100
              sm:flex
            "
              href="/"
            >
              <LogoNorthLadder
                className="
                mx-auto h-6 w-full  max-w-[80%] focus:outline-none
                focus-visible:outline-2 focus-visible:outline-offset-1
                focus-visible:outline-purple-600
              "
              />
            </Link>
            <nav
              aria-label="quick links"
              className="
              -my-1 mt-10 flex hidden flex-col justify-center gap-x-6 gap-y-4
              text-center sm:flex-row sm:text-sm
            "
            >
              <a
                className="
                  inline-block rounded-lg px-2 py-1 text-sm text-slate-700
                  hover:bg-slate-100 hover:text-slate-900
                "
                href="/app-features"
              >
                Features
              </a>
              <a
                className="
                  inline-block rounded-lg px-2 py-1 text-sm text-slate-700
                  hover:bg-slate-100 hover:text-slate-900
                "
                href="/faqs"
              >
                Testimonials
              </a>
              <a
                className="
                  inline-block rounded-lg px-2 py-1 text-sm text-slate-700
                hover:bg-slate-100 hover:text-slate-900
                "
                href="/how-it-works"
              >
                How it works
              </a>
            </nav>
          </div>
        ) : null}

        <div
          className="
            flex flex-col items-center border-t
            border-slate-400/10 py-10 sm:flex-row sm:justify-between
          "
        >
          <p className="mt-6 text-sm font-light text-purple-900 sm:mt-0">
            <span className="inline-flex">
              <Text text={translations.footer.copyright} />
            </span>
            {/* TODO: Remove this on prod builds */}
            <span className="mx-1 inline-flex text-gray-500"> - 4.0.0(19)</span>
            <a
              className="px-2"
              href="https://northladder.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              NorthLadder Trading LLC
            </a>
            {/* <Text text={translations.footer.rightsReserved} /> */}
          </p>
          <div className="sm:(flex py-0) flex hidden gap-x-6 py-8">
            <a
              aria-label="NorthLadder on Twitter"
              className="group"
              href="https://twitter.com/northladder"
              rel="noopener noreferrer"
              target="_blank"
            >
              <svg
                aria-hidden="true"
                className="h-6 w-6 fill-purple-800 group-hover:fill-purple-500"
              >
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0 0 22 5.92a8.19 8.19 0 0 1-2.357.646 4.118 4.118 0 0 0 1.804-2.27 8.224 8.224 0 0 1-2.605.996 4.107 4.107 0 0 0-6.993 3.743 11.65 11.65 0 0 1-8.457-4.287 4.106 4.106 0 0 0 1.27 5.477A4.073 4.073 0 0 1 2.8 9.713v.052a4.105 4.105 0 0 0 3.292 4.022 4.093 4.093 0 0 1-1.853.07 4.108 4.108 0 0 0 3.834 2.85A8.233 8.233 0 0 1 2 18.407a11.615 11.615 0 0 0 6.29 1.84" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
