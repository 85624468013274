import { createZustandStore } from '../../zustand';

import { IAuthState } from './types';

import {
  addNewSessionCallback,
  loadPersistedAuthStore,
  removeAllSessionsCallback,
  removeSessionCallback,
  updateActiveSessionCallback,
  updateActivityLogCallback,
  updateTokenInfoCallback,
  updateProfileDetailsCallback,
} from './callbacks';

/**
 * This creates a local session state tracker and update callbacks to track all
 * the concurrent active sessions, the activity logs & device information.
 *
 * TODO: For not trackable sessions, remove them on app mount when not set to
 * remember me. Also check if their session expired and then flush them out of
 * the session.
 */
export const useAuthStore = createZustandStore<IAuthState>((set) => {
  const localAuthData = loadPersistedAuthStore();

  return {
    activeSession: localAuthData?.activeSession || null,
    activityLog: localAuthData?.activityLog || null,
    allSessionsById: localAuthData?.allSessionsById || null,
    allSessionsByIndex: localAuthData?.allSessionsByIndex || null,
    allSessionsIndices: localAuthData?.allSessionsIndices || [],
    addNewSession: addNewSessionCallback(set),
    removeSession: removeSessionCallback(set),
    removeAllSessions: removeAllSessionsCallback(set),
    updateActiveSession: updateActiveSessionCallback(set),
    updateActivityLog: updateActivityLogCallback(set),
    updateTokenInfo: updateTokenInfoCallback(set),
    updateProfileDetails: updateProfileDetailsCallback(set),
  };
});
