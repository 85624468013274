import { getCompanyEnvironment } from '@northladder/utilities';

import { LogoDynamica } from '../LogoDynamica';
import LogoJumbo from '../LogoJumbo';
import LogoKSP from '../LogoKSP';
import LogoSpark from '../LogoSpark';

/**
 * This consists of all the supported country flag SVG icons to be used in the
 * app.
 */
export const companyLogos = {
  dynamica: LogoDynamica,
  jumbo: LogoJumbo,
  ksp: LogoKSP,
  spark: LogoSpark,
};

/**
 * Interface for CompanyLogo component which defines the props.
 */
interface ICompanyLogoProps {
  /**
   * className to apply on the SVG logo.
   */
  className?: string;
}

/**
 * -----------------------------------------------------------------------------
 * It exposes the svg props for overriding.
 *
 * @param props
 *
 * @returns JSX.Element
 */
export function CompanyLogo({ className = '' }: ICompanyLogoProps) {
  const companyEnvironment = getCompanyEnvironment();
  const Logo = companyLogos[companyEnvironment?.id];
  return <Logo className={className} />;
}
