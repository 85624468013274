import { useState } from 'react';

import { translations } from '../../../../../translations';

import { IModalAction, SimpleModal } from '../../../../modals';

import { IBaseGoogleMapLocationOptions, ICoordinate } from '../../types';

import { GoogleMap } from '../GoogleMap';
import { GoogleMapsProvider } from '../GoogleMapsProvider';

type TGoogleMapModalProps = Pick<
  IBaseGoogleMapLocationOptions,
  'initLocation' | 'onUpdateLocation' | 'regionId' | 'regionKey'
> & {
  isModalOpen: boolean;
  onCloseModal: () => void;
};

/**
 * -----------------------------------------------------------------------------
 * Renders the base modal map model that allows for drag and drop and search.
 */
export function GoogleMapModal({
  initLocation,
  isModalOpen,
  onCloseModal,
  onUpdateLocation,
  regionId,
  regionKey,
}: TGoogleMapModalProps) {
  const [selectedLocation, setSelectedLocation] =
    useState<ICoordinate | null>(initLocation);

  /**
   * Keep a copy of the selected location in the modal to allow for saving or
   * cancelling to the previous state.
   */
  const handleOnUpdateSelectedLocation = (newLocation: ICoordinate) => {
    setSelectedLocation(newLocation);
  };

  const handleCancel = () => {
    onUpdateLocation(initLocation);
    onCloseModal();
  };

  const handleSave = () => {
    onUpdateLocation(selectedLocation);
    onCloseModal();
  };

  const modalActions: IModalAction[] = [
    {
      id: 'cancel',
      label: translations.gpsField.cancelLocation,
      onClick: handleCancel,
      type: 'secondary',
    },
    {
      id: 'confirm',
      label: translations.gpsField.confirmLocation,
      onClick: handleSave,
      type: 'primary',
    },
  ];

  return (
    <GoogleMapsProvider
      mapZoom={initLocation?.zoom}
      regionId={regionId}
      regionKey={regionKey}
      renderMapContainer={(ref) => (
        <SimpleModal
          actions={modalActions}
          isOpen={isModalOpen}
          onCloseModal={onCloseModal}
        >
          <div
            className="
              flex h-[calc(100vh-2rem)] w-[calc(100vw-1rem)]
              sm:h-[calc(100vh-5rem)] sm:w-full
            "
          >
            <GoogleMap
              ref={ref}
              initLocation={selectedLocation}
              onUpdateLocation={handleOnUpdateSelectedLocation}
              regionId={regionId}
              regionKey={regionKey}
            />
          </div>
        </SimpleModal>
      )}
    />
  );
}
