export const STORAGE_KEY_REMEMBER_SESSION = 'version0.0.0.t';

/**
 * This key tracks the storage of API response `token` to encrypted local storage.
 */
export const STORAGE_KEY_TOKEN = 'version0.0.0.t';
/**
 * This key tracks the storage of API response `refreshToken` to encrypted local storage.
 */
export const STORAGE_KEY_REFRESH_TOKEN = 'version0.0.0.beta';

/**
 * This tracks persisted session state in local storage.
 * Covers (activeSession|activityLog|allSessionsById|allSessionsByIndex)
 * - See also `packages/services/IAuthState`
 */
export const STORAGE_KEY_AUTH_STATE = 'error-logs.0.0.t';

/**
 * This tracks persisted active region state in local storage.
 * Covers (region)
 * - See also `packages/services/IRegionState`
 */
export const STORAGE_REGION_STATE = 'error-logs.0.1.t';

/**
 * This tracks persisted session state in local storage.
 * Covers (activeSession|activityLog|allSessionsById|allSessionsByIndex)
 * - See also `packages/services/IAuthState`
 */
export const STORAGE_KEY_REGISTRATION_STATE = 'error-logs.0.0.reg.0.1.t';

/**
 * This tracks persisted active transactions state state in local storage.
 * Covers (transactions)
 * - See also `packages/services/ITransactionsState`
 */
export const STORAGE_KEY_TRANSACTIONS_STATE = 'error-actions.analytics.0.1.t';

/**
 * This tracks persisted active asset model state in local storage.
 */
export const STORAGE_ACTIVE_ASSET_MODEL_STATE = 'error-logs.element.0.1.t';
