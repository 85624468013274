import { TSvgComponentProps } from '../../types';

/**-----------------------------------------------------------------------------
 * IconInfo component takes params to configure the IconInfo SVG component
 */
export function IconInfo(props: TSvgComponentProps) {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 21 20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.49 0C4.97 0 0.5 4.48 0.5 10C0.5 15.52 4.97 20 10.49 20C16.02 20 20.5 15.52 20.5 10C20.5 4.48 16.02 0 10.49 0ZM10.5 18C6.08 18 2.5 14.42 2.5 10C2.5 5.58 6.08 2 10.5 2C14.92 2 18.5 5.58 18.5 10C18.5 14.42 14.92 18 10.5 18ZM9.5 13H11.5V15H9.5V13ZM9.5 5H11.5V11H9.5V5Z"
        fill="#9333EA"
      />
    </svg>
  );
}
