import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { Text } from '@northladder/i18n';

import { TUIError } from '../../../types';
import { translations } from '../../../translations';

import { Button } from '../../buttons';
import { FormFeedbackSection } from '../../forms';

import { OtpInputFieldDummy } from './OtpInputFieldDummy';

interface IOTPLoaderAndErrorProps {
  error: TUIError;
  onCloseModal: () => void;
  onSendOTP: () => void;
  phoneNumber: string;
  otpLength: number;
}

export function OTPLoaderAndError({
  error,
  onCloseModal,
  onSendOTP,
  phoneNumber,
  otpLength,
}: IOTPLoaderAndErrorProps) {
  const handleClose = () => {
    onCloseModal();
  };

  /**
   * UI to show if an error occurs while loading the GEnerating /Sending the OTP
   */
  if (error?.message) {
    return (
      <>
        <Dialog.Title
          as="h3"
          className="
            form-header flex justify-between text-center text-2xl
            leading-6 text-purple-900
          "
        >
          <div />
          <Text text={translations.otpModal.errorState.title} />
          <XMarkIcon className="h-6 w-6 cursor-pointer" onClick={handleClose} />
        </Dialog.Title>
        <div className="flex flex-col items-center">
          <FormFeedbackSection
            className="text-center"
            description={
              error?.description || translations.otpModal.errorState.description
            }
            isFormSubmitting={false}
            message={error.message || translations.otpModal.errorState.message}
            type="error"
          />
          <span className="py-8 text-center text-sm text-gray-500">
            <Text text={translations.otpModal.errorState.description2} />
            <span className="ml-1">{phoneNumber}</span>
          </span>
        </div>

        <Button
          isEnabled
          isLoading={false}
          label={translations.otpModal.errorState.pleaseRetry}
          loadingLabel={translations.otpModal.errorState.resendingOtp}
          onClick={onSendOTP}
          type="button"
        />
      </>
    );
  }

  /**
   * UI to show while the app loads app sends the OTP to the user's mobile.
   */
  return (
    <>
      <Dialog.Title
        as="h3"
        className="
          form-header flex justify-between py-4 text-center text-2xl
          font-light leading-6 text-purple-900
        "
      >
        <div />
        <Text text={translations.otpModal.loadingState.title} />
        <XMarkIcon className="h-6 w-6 cursor-pointer" onClick={handleClose} />
      </Dialog.Title>
      <div className="flex flex-col text-center">
        <FormFeedbackSection
          className="text-center"
          description=""
          isFormSubmitting={false}
          message={translations.otpModal.loadingState.message(phoneNumber)}
          type="info"
        />
        <OtpInputFieldDummy otpLength={otpLength} />
        <span className="pb-4 pt-2 text-sm text-gray-400">
          <Text text={translations.otpModal.loadingState.info} />
        </span>
      </div>

      <Button
        className="cursor-wait"
        isEnabled={false}
        isLoading
        label={translations.forms.pleaseWait}
        loadingLabel={translations.forms.pleaseWait}
        onClick={onSendOTP}
        type="button"
      />
    </>
  );
}
