import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

import { TRegionInstance, TTextLocalized } from '@northladder/services';

import { useI18n } from '../../hooks/useI18n';
import { translations } from '../../translations';

import { FlagIcon } from '../Flags/flagIcons';
import { Text } from '../TextLocalized';

import { ListBoxWrapper } from './styles';

interface IItemDetailsPreviewProps {
  /**
   * The item details, with the label and icon/flag to render
   */
  item: TRegionInstance;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the the flag and text content of the item
 *
 * @param {{ item }}
 * @returns
 */
function ItemDetailsPreview({ item }: IItemDetailsPreviewProps) {
  return (
    <>
      <FlagIcon className="shrink-0 rounded-md" id={item.icon} size={22} />
      <span
        className="
          ui-active:text-purple-900 mx-3 block max-w-[calc(100%-22px)]
          truncate text-xs text-gray-600 sm:text-sm
        "
      >
        <Text text={item.label} />
      </span>
    </>
  );
}

interface IListOptionItemProps {
  /**
   * The item details, with the label and icon/flag to render
   */
  item: TRegionInstance;
  /**
   * Getting this value from `IRegionSelectDropdownFieldProps`
   */
  disableRegion?: boolean;
}

/**
 * -----------------------------------------------------------------------------
 * This renders each individual item from the dropdown options list.
 * In this case, a country flag and its name with a checkmark to show selection.
 *
 *
 * @param TRegionInstance
 * @returns
 */
function ListOptionItem({ item, disableRegion }: IListOptionItemProps) {
  return (
    <Listbox.Option
      className="
        ui-active:bg-purple-50 ui-active:text-purple-700 ui-selected::text-purple-700 relative
        flex cursor-default select-none py-2 text-xs text-gray-700 sm:text-sm
      "
      disabled={disableRegion}
      value={item}
    >
      <span
        className="
          ui-selected:max-w-[calc(100%-64px)] mx-3 flex max-w-[calc(100%-22px)]
          grow items-center
        "
      >
        {item ? (
          <ItemDetailsPreview item={item} />
        ) : (
          <span className="font-light text-gray-500">
            <Text text={translations.selectARegion} />
          </span>
        )}
      </span>
      <CheckIcon
        aria-hidden="true"
        className="
            ui-selected:flex ui-active:text-purple-800
             mx-2.5 hidden
            h-5 w-5 shrink-0 items-center text-purple-600
          "
      />
    </Listbox.Option>
  );
}

interface IRegionSelectDropdownFieldProps {
  /**
   * This renders the default selected instance passed to this dropdown.
   */
  label?: TTextLocalized;
  /**
   * This renders the default selected instance passed to this dropdown.
   */
  initialRegion: TRegionInstance | null;
  /**
   * Callback supplied to the parent component to provide the selected country.
   */
  onSelectRegion: (selectedRegion: TRegionInstance) => void;
  /**
   * To disable the region dropdown based on the selected region in step-1.
   */
  hasActiveRegion?: boolean;
}

/**
 * -----------------------------------------------------------------------------
 * Dropdown provides UX for choosing a country/region from a list of supported
 * countries/regions/territories, rendering the country flag and name, alongside
 * a checkmark to show selected.
 *
 * TODO: 2. Get the Options from the Backend and Cache them during app session.
 * TODO: 3. Add Tests and Stories to the component
 *
 * @returns JSX.Element
 */
export function RegionSelectDropdownField({
  label,
  onSelectRegion,
  initialRegion,
  hasActiveRegion: disableRegion,
}: IRegionSelectDropdownFieldProps) {
  const { regionsConfig } = useI18n();

  const [selected, setSelected] =
    useState<TRegionInstance | null>(initialRegion);

  // const regions = regionsConfig.filter(
  //   (region: TRegionInstance) => region.id !== 'global'
  // );

  const handleSelectionChange = (value: TRegionInstance) => {
    onSelectRegion(value);
    setSelected(value);
  };

  return (
    <Listbox onChange={handleSelectionChange} value={selected}>
      {({ open }) => (
        <div className="ui-open:hidden">
          {disableRegion ? null : (
            <Listbox.Label className="form-label text-gray-700">
              <Text text={label || translations.selectARegion} />
            </Listbox.Label>
          )}
          <ListBoxWrapper>
            <Listbox.Button
              className={`form-input-field flex ${
                disableRegion && 'region-disable'
              }`}
            >
              <span className="flex max-w-[calc(100%-22px)] grow items-center">
                {selected ? (
                  <ItemDetailsPreview item={selected} />
                ) : (
                  <span className="font-light text-gray-500">
                    <Text text={translations.selectARegion} />
                  </span>
                )}
              </span>
              {disableRegion ? null : (
                <ChevronDownIcon
                  aria-hidden="true"
                  className="pointer-events-none h-5 w-5 shrink-0 text-gray-400"
                />
              )}
            </Listbox.Button>

            {/* Hide the dropdown options based on the active region set in
            step-1. */}
            {disableRegion ? null : (
              <Transition
                as={Fragment}
                enter="transition ease-in duration-150"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                show={open}
              >
                <Listbox.Options
                  className="
                    absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md
                    bg-white py-1 text-base shadow-lg ring-1 ring-black
                    ring-opacity-5 focus:outline-none sm:text-sm
                  "
                >
                  {regionsConfig.map((region) => (
                    <ListOptionItem
                      key={region.id}
                      disableRegion={disableRegion}
                      item={region}
                    />
                  ))}
                </Listbox.Options>
              </Transition>
            )}
          </ListBoxWrapper>
        </div>
      )}
    </Listbox>
  );
}
