const IS_PAAS_ENABLED =
  process.env.NEXT_PUBLIC_IS_PAAS_ENABLED &&
  process.env.NEXT_PUBLIC_IS_PAAS_ENABLED.toLowerCase() === 'true';

/**
 * -----------------------------------------------------------------------------
 * This hook determines the app should be shown as PAAS or other region.
 *
 * @returns {{ isPaasEnabled, disabledFeatures }}
 * - isPAAS is flag for pass web app.
 */
export const usePAAS = () => {
  const disabledFeatures = IS_PAAS_ENABLED ? ['COMPONENT_BID_RANK_FILTER'] : [];

  return { isPaasEnabled: IS_PAAS_ENABLED, disabledFeatures };
};
