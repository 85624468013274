import { useState, PropsWithChildren } from 'react';

interface ITooltipProps {
  /**
   * For showing tooltip message on `Tooltip`
   */
  message?: string;
}

/**
 * -----------------------------------------------------------------------------
 * This component renders its children with hover tooltip it accepts one message
 * parameter for showing tooltip.
 *
 * @param IPopOverProps -  expect `message`
 *
 * @return JSX.Element
 */
export function Tooltip({
  message,
  children,
}: PropsWithChildren<ITooltipProps>) {
  const [showPopup, setShowPopup] = useState(false);

  const handleMouseEnter = () => {
    setShowPopup(() => true);
  };

  const handleMouseLeave = () => {
    setShowPopup(() => false);
  };

  return (
    <div
      className="w-fit"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {message && showPopup ? (
        <p
          className="
            m-w-[100px] absolute z-10 mt-2 max-w-xs
            rounded bg-white p-2 text-sm
            font-normal drop-shadow-md
          "
        >
          {message}
        </p>
      ) : null}
    </div>
  );
}
