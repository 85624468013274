import ContentLoader from 'react-content-loader';

export function ReactLoader() {
  return (
    <ContentLoader
      backgroundColor="#f9eef972"
      className="absolute"
      foregroundColor="#ffffff"
      height={36}
      rtl
      speed={2}
      viewBox="0 0 20 20"
      width={36}
    >
      <rect
        className="flex h-full w-full"
        height="120"
        rx="2"
        ry="2"
        width="120"
        x="0"
        y="0"
      />
    </ContentLoader>
  );
}
