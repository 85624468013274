import { MouseEventHandler } from 'react';

interface IBidPriceTextLabelProps {
  /**
   * This is for showing the price inside `BidPriceTextLabel`
   */
  myPrice: string;
  /**
   * onClick mouse event callback supplied to the parent
   */
  onClick: MouseEventHandler<HTMLButtonElement>;
}

/**
 * For showing the default or dummy form field for showing the price value
 *
 * @param `BidPriceTextLabel`
 *
 * @returns Jsx.Element
 */
export function BidPriceTextLabel({
  myPrice,
  onClick,
}: IBidPriceTextLabelProps) {
  return (
    <span
      className="
     inline-block h-10
     w-28
   rounded-md
   bg-white
     shadow-none group-hover:border-2
   group-hover:border-purple-300
   group-hover:bg-white
   group-hover:text-gray-700
    "
      onClick={onClick}
      role="presentation"
    >
      <p className="ml-2 mt-2">{myPrice}</p>
    </span>
  );
}
