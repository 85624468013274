import { useEffect, useCallback, useRef } from 'react';

interface IUseNextPageIntersectionObserverOptions {
  /**
   * A condition that determines whether the intersection should trigger the
   * callback if there are more items to fetch/display.
   */
  hasNextPage?: boolean;
  /**
   * The callback called when the intersection is triggered.
   */
  onFetchNextPage: () => void;
  /**
   * How far from the observing component should the trigger be made.
   */
  threshold?: number;
}

/**
 * This hook provides reusable functionality for observing a scrollable page and
 * fetching the next page if the threshold is reached. Usually targeting some
 * dom element at the end of the page.
 *
 * This helps facilitate functionality like infinity scroll.
 */
export function useNextPageIntersectionObserver({
  hasNextPage = false,
  onFetchNextPage,
  threshold = 0,
}: IUseNextPageIntersectionObserverOptions) {
  const nextPageObserverRef = useRef(null);

  const handleNextPageIntersectionObserver = useCallback(
    (entries: any[]) => {
      const [target] = entries;

      if (target.isIntersecting && hasNextPage) {
        onFetchNextPage();
      }
    },
    [onFetchNextPage, hasNextPage]
  );

  useEffect(() => {
    const targetElement = nextPageObserverRef.current;

    const observer = new IntersectionObserver(
      handleNextPageIntersectionObserver,
      { threshold }
    );

    if (targetElement) {
      observer.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPage]);

  return {
    /**
     * The reference to be passed to the ref of the dom item to track.
     */
    nextPageObserverRef,
  };
}
