import {
  loadLocalData,
  saveLocalData,
  STORAGE_REGION_STATE,
} from '@northladder/utilities';

import { IRegionState } from '../../region/types';

export type IPersistRegionState = Pick<IRegionState, 'region' | 'appLanguage'>;

/**
 * This persists the region store  to secure local storage.
 */
export const persistRegionStore = (targetState: IPersistRegionState) => {
  saveLocalData(STORAGE_REGION_STATE, targetState);
};

/**
 * This retrieves and decrypts the region store info from secure local storage.
 */
export const loadPersistedRegionStore = () =>
  loadLocalData<IPersistRegionState>(STORAGE_REGION_STATE);
