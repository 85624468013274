import { IDealDetailsState } from './types';
import { createZustandStore } from '../../../zustand';

/**
 * This creates a global state tracker to track the active deal details.
 */
export const useDealDetailsStore = createZustandStore<IDealDetailsState>()(
  (set) => ({
    activeDealDetails: undefined,
    updateDealDetails: (dealDetails) => {
      set(() => ({ activeDealDetails: dealDetails }));
    },
  })
);
