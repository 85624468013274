import tw, { styled } from 'twin.macro';

export const PreviewContentContainer = styled.div`
  ${tw`
    relative
    flex
    flex-col
    h-36
    w-[calc(50%-4px)]
    items-center
    rounded-xl
    border
    border-gray-200
    bg-gray-100
    transition-all
    duration-300
    ease-in
    cursor-pointer
  `};
`;

export const PreviewThumbnailContainer = styled.div`
  ${tw`
    w-full
    h-full
    flex
    flex-1
    flex-col
    items-center
    justify-center
    overflow-hidden
    p-1
    rounded-xl

    outline-1
    outline-violet-400
    ring-0

    hover:bg-violet-200
    active:bg-violet-200
    focus:bg-violet-50
  `};

  img {
    ${tw`
      z-[1]
      h-full
      w-full
      rounded-lg
      object-cover
    `};
  }
`;

export const PreviewImageFullContainer = styled.div`
  ${tw`
    w-full
    h-full
    flex
    flex-1
    flex-col
    items-center
    justify-center
    overflow-hidden
    rounded-xl
  `};

  img {
    ${tw`
      z-[1]
      h-full
      w-full
      rounded-lg
      object-scale-down
    `};
  }
`;
