import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface IRenderContentOptions {
  onCloseModal: () => void;
}

interface IImagePickerModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  renderModalContent: ({ onCloseModal }: IRenderContentOptions) => JSX.Element;
}

export function ImagePickerModal({
  renderModalContent,
  isOpen,
  onCloseModal,
}: IImagePickerModalProps) {
  return (
    <Transition.Root as={Fragment} show={isOpen}>
      <Dialog as="div" className="relative z-[65]" onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div
            className="
              flex min-h-full w-full items-end justify-center p-4 text-center
              sm:items-center sm:p-0
            "
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="
                  relative min-h-fit min-w-full transform overflow-hidden
                  rounded-lg bg-white text-left shadow-xl transition-all
                  duration-500 sm:w-full sm:min-w-0 sm:max-w-sm md:max-w-xl
                "
              >
                {renderModalContent({ onCloseModal })}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
