import { useRouter } from 'next/router';

import {
  DEFAULT_LOCALE,
  languagesConfig,
  languagesConfigByLocale,
  regionsConfig,
  regionsConfigById,
  TLanguagesConfigItem,
  TLocale,
  TRegionInstance,
  TRegionInstanceById,
} from '@northladder/services';

type TUseI18nReturnType = {
  /**
   * The active language currently displayed by the UI.
   */
  language: TLanguagesConfigItem;
  /**
   * Presents the list of all supported languages.
   */
  languagesConfig: TLanguagesConfigItem[];
  /**
   * The locale as fetched from the route path
   */
  locale: TLocale;
  /**
   * Presents the list of all supported countries/region instances.
   */
  regionsConfig: TRegionInstance[];
  /**
   * Presents the list of all supported countries/region instances.
   */
  regionsConfigById: TRegionInstanceById;
};

/**
 * -----------------------------------------------------------------------------
 * This provides re-usable logic for the `locale` and language config.
 * It retrieves the current locale from the `next router` path locale options
 * and uses it to determine the direction of the layout and active language.
 *
 * @returns TUseI18nReturnType
 */
export const useI18n = (): TUseI18nReturnType => {
  const { locale: routeLocale } = useRouter();

  const locale = (routeLocale as TLocale) || DEFAULT_LOCALE;
  const language = languagesConfigByLocale[locale];

  return {
    language,
    languagesConfig,
    locale,
    regionsConfig,
    regionsConfigById,
  };
};
