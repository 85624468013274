import { TSvgComponentProps } from './types';

/**
 * -----------------------------------------------------------------------------
 * This renders a rectangular Icon/Flag that shows the Arabic language symbol in
 * focus. This is to be used by CTA to indicate a trigger to switch the locale
 * to the Arabic language
 *
 * @param {{size: number}}
 */
export function FlagArabic({ size = 32, ...rest }: TSvgComponentProps) {
  return (
    <svg
      height={size}
      id="FlagArabic"
      viewBox="0 0 512 340"
      width={size * 1.5}
      {...rest}
    >
      <path
        className={`fill-purple-700 ${rest?.className}`}
        d="M0 0h512v340H0z"
        id="background"
      />
      <g id="arabic">
        <g id="English" opacity={0.5}>
          <path
            className="fill-white"
            d="M194.1 278.8h48.7c-3 3.1-5.7 6-8.5 8.8-2.4 2.4-5 4.6-7.3 6.9-4.5 4.4-8.9 8.9-13.3 13.5-1.1 1.1-1.9 1.3-3.3.5-7.5-4.1-12.7-9.7-14-18.5-.6-3.7-1.5-7.3-2.3-11.2z"
            id="shadow"
          />
          <path
            className="fill-white"
            d="M396.8 89.6c-5.7-7.2-13.4-10.5-22.4-10.5-38-.1-76-.1-113.9 0-.3 0-.5.1-1 .2.6 4 1.3 7.9 1.8 11.8 1.3 9.7 2.4 19.4 3.7 29 1.3 9.8 2.6 19.5 4 29.3h26c2.7 0 4.7.8 6.1 2.4 1.3 1.5 2 3.5 2 5.9s-.7 4.4-2 5.9c-1.4 1.6-3.4 2.4-6.1 2.4h-23.6c.9 6.1 1.7 12.2 2.6 18.2h18.5c2.6 0 4.6.8 5.9 2.3s2 3.4 2 5.7-.6 4.3-1.9 5.8c-1.3 1.6-3.3 2.4-6 2.4h-16.2l3 21.6h16.9c2.6 0 4.7.8 6.1 2.4 1.4 1.6 2 3.6 2 6.1 0 2.4-.7 4.4-2 6-1.4 1.6-3.5 2.4-6.1 2.4h-14.6c.8 5.5 1.7 10.9 1.8 16.4.2 6.4-3.3 11.5-7.8 16-3.8 3.8-7.5 7.7-11.3 11.6-8.7 8.9-17.4 17.7-26.1 26.6-.6.6-1.1 1.3-1.8 2.2.9.1 1.6.2 2.2.2h134.9c9.1 0 17.1-2.9 23.1-10.1 2.9-3.5 4.8-7.5 5.5-12V101.7c-.6-4.5-2.5-8.5-5.3-12.1zm-23.3 137.9c0 10.7-4.9 12.9-9.1 12.9-1.5 0-2.8-.3-4-.8s-2.3-1.4-3.3-2.5c-.9-1.1-1.8-2.3-2.6-3.7-.8-1.3-1.5-2.7-2.3-4.1l-23.5-45v44.6c0 3.7-.7 6.4-2.1 8.4-1.5 2.1-3.5 3.2-5.9 3.2-2.5 0-4.5-1.1-6-3.2-1.4-2-2.1-4.7-2.1-8.3v-66.8c0-3 .3-5.3.8-7.1.7-2.1 1.9-3.8 3.5-5.1 1.7-1.3 3.5-2 5.4-2 1.5 0 2.9.3 4 1 1.1.6 2 1.4 2.8 2.4.7.9 1.5 2.1 2.2 3.6.7 1.4 1.4 2.8 2.2 4.3l24.1 45.6v-45.4c0-3.7.6-6.4 2-8.4 1.4-2.1 3.5-3.2 5.9-3.2 2.5 0 4.6 1.1 6 3.2 1.4 1.9 2 4.7 2 8.4v68z"
            id="English_Icon"
          />
        </g>
        <g id="Arabic">
          <path
            className="fill-white"
            d="M195.4 136.5c-5.5 2.3-12.1 12.4-12 18.5H208c.2-5.4.1-10.4-2.6-15-2.3-3.9-6.1-5.1-10-3.5z"
            id="character-space"
          />
          <path
            className="fill-white"
            d="M264.8 253.1c-1.4-11.4-2.7-22.9-4.2-34.3-1.6-11.9-3.3-23.8-5-35.7-1.9-13.4-3.7-26.7-5.5-40.1-1.8-13.4-3.7-26.7-5.5-40.1-1.7-11.9-3.3-23.8-5-35.7-.9-6.4-1.9-12.9-3.3-19.2-1.6-7.7-6.7-12.9-13.7-16-3.1-1.4-6.4-2.2-9.6-3.3H111.2c-3.7 1.2-7.5 2.2-11.2 3.7-3.9 1.6-6.8 4.5-9.3 7.8-3.7 4.9-4.5 10.4-4.5 16.3 0 59.4 0 118.9.1 178.3 0 3.5.4 7.3 1.6 10.5 2.2 5.7 6.3 10 11.8 13 5.8 3.1 11.8 3.7 18.2 3.7h138.8c4.7.1 8.7-4.3 8.1-8.9zM196.5 83.9c4.5 4.8 8.8 9.4 13.2 14.1-4.3 4-9 8.4-13.5 12.6-4.2-3.9-8.9-8.2-13.3-12.3 4.7-4.9 9.1-9.5 13.6-14.4zm28.1 80.3c-.2 2.7-.6 5.4-.9 8.4h-50.9c-2.3 0-3.2.3-3.6 3-1.6 12.4-11.8 21.6-25 22.7-6.2.5-12.4.3-18.1-2.5-8.3-4-12.1-11.3-13-19.9-.9-8.6-.6-17.3-.8-25.9-.1-4 0-7.9 0-12h17.4v2.7c0 8.8-.1 17.6 0 26.4 0 2.3.2 4.8.9 7 1.3 4.6 4.5 6.9 9.1 7.1 5.5.2 9.3-2 10.9-6.5 1.6-4.2 1.3-8.5.3-12.8-2.1-8.9-6.7-16.6-12.3-23.7-.5-.7-1.1-1.3-1.8-2.3 4.9-3.2 9.8-6.4 14.8-9.6 6.1 7.5 11.1 15.5 15 24.5 1.6-3.5 2.8-6.6 4.4-9.6 4.2-8.3 9.7-15.5 18.5-19.5 5.4-2.4 11.1-3.2 16.9-2 8.2 1.7 12.8 7.4 15.6 14.9 3.4 9.6 3.3 19.6 2.6 29.6z"
            id="swad"
          />
        </g>
      </g>
    </svg>
  );
}
