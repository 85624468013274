import { Text, TTextLocalized } from '@northladder/i18n';

import { ButtonLoader } from '../../loaders';

interface IBaseButtonOptions {
  /**
   * Additional class to override the button's defaults.
   */
  className?: string;
  /**
   * If the button should be enable or not. For example, it's by default disabled
   * if the region is not selected on the `LoginForm`.
   */
  isEnabled?: boolean;
  /**
   * If the form is currently in the loading state (Submitting to the API).
   * TODO: Show a loading spinner instead of please wait.
   */
  isLoading?: boolean;
  /**
   * The translatable text to show os the default button label.
   */
  label: TTextLocalized;
  /**
   * The alternative text to show when the button is loading. Translation ready.
   */
  loadingLabel?: TTextLocalized;
}

interface IButtonTypeSubmit extends IBaseButtonOptions {
  /**
   * This type should be a `submit`
   */
  type: 'submit';
  /**
   * Form `type='submit'`,  in this case the `onClick` prop is not required as
   * this is handled from the form submit.
   */
  onClick?: never;
}

interface IButtonTypeButton extends IBaseButtonOptions {
  /**
   * This type should be a `button` in this case the `onClick` is a must.
   */
  type: 'button';
  /**
   * Optional handler to handler form CTA click.
   * NOTE: This is optional if the form is indeed making the submission.
   */
  onClick: () => void;
}

/**
 * The interface for the base button element.
 * - When it's `type='submit'`, the `onClick` prop should not be provided unlike
 * the case for `type='button'`
 */
type IBaseButtonProps = IButtonTypeSubmit | IButtonTypeButton;

/**
 * -----------------------------------------------------------------------------
 * The is the building block of the button element, to be extendable by others.
 *
 * TODO: Add a loading indicator for loading states.
 */
export function Button({
  className = '',
  isEnabled = true,
  isLoading,
  label,
  loadingLabel = '',
  onClick,
  type,
}: IBaseButtonProps) {
  const btnLabel = isLoading ? loadingLabel : label;

  if (type === 'submit') {
    return (
      <button
        className={`form-btn ${className}`}
        disabled={!isEnabled}
        onClick={onClick}
        type="submit"
      >
        {isLoading ? (
          <ButtonLoader className="mx-4" isEnabled={isEnabled} />
        ) : null}
        <Text text={btnLabel} />
      </button>
    );
  }

  return (
    <button
      className={`form-btn ${className}`}
      disabled={!isEnabled}
      onClick={onClick}
      type="button"
    >
      {isLoading ? (
        <ButtonLoader className="mx-4" isEnabled={isEnabled} />
      ) : null}
      <Text text={btnLabel} />
    </button>
  );
}
