import { IDateRangeType } from '../../../types';

import {
  IActiveTxnListType,
  ITransactionFilter,
  TSetTransaction,
} from '../types';

import { persistTransactionsStore } from './transactionsStoreDeviceStorageUtils';

/**
 * This removes the provided transaction filter.
 * Note: if `null` is passe, it will clear the filters.
 */
export function onUpdateTxnActiveFiltersCallback(set: TSetTransaction) {
  return function onUpdateTxnActiveFilters(
    filters: ITransactionFilter[] | null
  ) {
    set(({ activeListType, dateRange, searchKey }) => {
      persistTransactionsStore({
        activeFilters: filters,
        activeListType,
        dateRange,
        pageNo: 1,
        searchKey,
      });

      return { activeFilters: filters, pageNo: 1 };
    });
  };
}

/**
 * This removes the provided transaction filter.
 * Note: if `null` is passe, it will clear the filters.
 */
export function onUpdateTxnActiveListTypeCallback(set: TSetTransaction) {
  return function onUpdateTxnActiveListType(type: IActiveTxnListType) {
    set(({ activeFilters, dateRange, searchKey }) => {
      persistTransactionsStore({
        activeFilters,
        activeListType: type,
        dateRange,
        pageNo: 1,
        searchKey,
      });

      return { activeListType: type, pageNo: 1 };
    });
  };
}

/**
 * This updates the transactions date range picker filters picked from the
 * calender
 */
export function onUpdateTxnDateRangeCallback(set: TSetTransaction) {
  return function onUpdateTxnDateRange(dateRange: IDateRangeType | null) {
    set(({ activeFilters, activeListType, searchKey }) => {
      persistTransactionsStore({
        activeFilters,
        activeListType,
        dateRange,
        pageNo: 1,
        searchKey,
      });

      return { dateRange, pageNo: 1 };
    });
  };
}

/**
 * This updates the transactions search key
 */
export function onUpdateTxnSearchKeyCallback(set: TSetTransaction) {
  return function onUpdateTxnSearchKey(searchKey: string) {
    set(({ activeFilters, activeListType, dateRange }) => {
      persistTransactionsStore({
        activeFilters,
        activeListType,
        dateRange,
        pageNo: 1,
        searchKey,
      });

      return { searchKey, pageNo: 1 };
    });
  };
}

/**
 * This updates the transactions page number
 */
export function onUpdateTxnPageNoCallback(set: TSetTransaction) {
  return function onUpdateTxnSearchKey(pageNo: number) {
    set(({ activeFilters, activeListType, dateRange, searchKey }) => {
      persistTransactionsStore({
        activeFilters,
        dateRange,
        pageNo,
        activeListType,
        searchKey,
      });

      return { pageNo };
    });
  };
}

/**
 * This updates the Credit Limit Exceeded date
 */
export function onUpdateCreditLimitExceedDateCallback(set: TSetTransaction) {
  return function onUpdateCreditLimitExceededDate(date?: number) {
    set(() => ({ creditLimitExceededDate: date }));
  };
}

/**
 * This clears the transactions store
 */
export function onClearTxnStoreCallback(set: TSetTransaction) {
  return function onUpdateTxnSearchKey() {
    set(() => ({
      activeFilters: null,
      activeListType: null,
      dateRange: null,
      pageNo: 1,
      searchKey: '',
    }));
  };
}
