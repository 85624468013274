import { TSvgComponentProps } from '../../types';

import { IconBank } from './IconBank';
import { IconBid } from './IconBid';

/**
 * -----------------------------------------------------------------------------
 * Icons base for StatsSummaryCard. It will help to access icons.
 * eg(summaryCardIcon[IconBank])
 */
const summaryCardIcons = {
  IconBank,
  IconBid,
};

export type TSummaryIcon = keyof typeof summaryCardIcons;

interface ISummaryIconProps extends TSvgComponentProps {
  icon: TSummaryIcon;
}

export function SummaryIcon({ icon, ...rest }: ISummaryIconProps) {
  const Icon = summaryCardIcons[icon];

  return <Icon {...rest} />;
}
