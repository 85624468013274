import { Text, TTextLocalized } from '@northladder/i18n';

import { translations } from '../../../../translations';

import { FormFeedbackSectionContainer } from './styles';

interface IFormFeedbackSectionProps {
  /**
   * Indicates whether to style the form message as an error or just info tip.
   */
  type: 'error' | 'info';
  /**
   * The tailwind css classes to augment the default class names.
   */
  className?: string;
  /**
   * The main message displayed below the title of the form.
   */
  message: TTextLocalized;
  /**
   * The alternative `message` displayed when the form is loading.
   */
  messageLoadingText?: TTextLocalized;
  /**
   * Additional information to be shown below the main message.
   */
  description?: TTextLocalized;
  /**
   * The alternative `description` displayed when the form is loading.
   */
  descriptionLoadingText?: TTextLocalized;
  /**
   * Whether the form is in loading state/currently submitting input via API or not.
   */
  isFormSubmitting: boolean;
}

/**
 * -----------------------------------------------------------------------------
 * This displays the form feedback at the top that renders the helpful tips and
 * form error messages returned from the server.
 */
export function FormFeedbackSection({
  type,
  className,
  message,
  messageLoadingText,
  description,
  descriptionLoadingText,
  isFormSubmitting,
}: IFormFeedbackSectionProps) {
  let msg = message;
  let desc = description;

  if (isFormSubmitting) {
    msg = messageLoadingText || translations.formSubmission.verifyingDetails;
    desc = descriptionLoadingText || translations.formSubmission.pleaseWait;
  }

  const wrapperClass = type === 'error' ? 'feedback-error' : 'feedback-info';

  return (
    <FormFeedbackSectionContainer
      className={`${className || ''} ${wrapperClass} `}
    >
      {msg ? (
        <span className="message">
          <Text text={msg} />
        </span>
      ) : null}
      {desc ? (
        <span className="description">
          <Text text={desc} />
        </span>
      ) : null}
    </FormFeedbackSectionContainer>
  );
}
