import { TSetBidFilters } from '../types';

/**
 * This clears the bids filter store
 */
export function onClearBidFilterStoreCallback(set: TSetBidFilters) {
  return function onUpdateTxnSearchKey() {
    set(() => ({
      bidSearchKey: '',
      categoryBidFilterList: [],
      selectedBrandBidFilterList: [],
      selectedCategoryBidFilter: null,
      hotDealsBidSearchKey: '',
      selectedBidRankFilter: undefined,
      bidListSortFilterIndex: 0,
      hotDealsBidListSortIndex: 1,
    }));
  };
}
