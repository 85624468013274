import { isToday, isYesterday, format, subDays } from 'date-fns';
import * as allLocale from 'date-fns/locale';

import { TLocale } from '../types';

export type TimeRangeType = 'TODAY' | 'YESTERDAY' | 'EARLIER';

export const getDateLabel = (date: number): TimeRangeType => {
  if (isToday(date)) {
    return 'TODAY';
  }

  if (isYesterday(date)) {
    return 'TODAY';
  }

  return 'EARLIER';
};

interface IGetHumanReadableTimeStampProps {
  timestamp: number;
  activeLocale?: TLocale;
  dateFormat?: string;
  showExactDate?: boolean;
}

/**
 * This function accepts timestamp and returns human readable time stamp according
 * to the difference between current time and given timestamp.
 */
export const getHumanReadableTimeStamp = ({
  activeLocale = 'en',
  dateFormat,
  showExactDate = false,
  timestamp,
}: IGetHumanReadableTimeStampProps): string => {
  const isDateInDaysAgoFormat = true;
  const locale =
    activeLocale === 'en' ? allLocale.enUS : allLocale[activeLocale];
  const dateOptions = { locale };

  const now = Date.now();
  const differenceInMins = Math.round((now - timestamp) / 60000);

  if (differenceInMins < 1) {
    const differenceInSec = Math.round((now - timestamp) / 1000);

    if (activeLocale === 'en') {
      return `${differenceInSec} second${differenceInSec > 1 ? 's' : ''} ago`;
    }

    if (differenceInSec === 1) {
      return 'منذ ثانية واحدة';
    }

    return `منذ ${differenceInSec} ثانية`;
  }

  if (differenceInMins < 60) {
    if (activeLocale === 'en') {
      return `${differenceInMins} minute${differenceInMins > 1 ? 's' : ''} ago`;
    }

    if (differenceInMins === 1) {
      return 'منذ 1 دقيقة';
    }

    return `منذ ${differenceInMins} دقيقة`;
  }

  if (differenceInMins < 360) {
    const differenceInHours = Math.round(differenceInMins / 60);

    if (activeLocale === 'en') {
      return `${differenceInHours} hour${differenceInHours > 1 ? 's' : ''} ago`;
    }

    if (differenceInHours === 1) {
      return 'منذ 1 ساعة';
    }

    return `منذ ${differenceInHours} دقيقة`;
  }

  if (differenceInMins < 1440 && isToday(timestamp)) {
    if (activeLocale === 'en') {
      return `Today, ${format(timestamp, 'h:mm a', dateOptions)}`;
    }

    return `${format(timestamp, 'h:mm aaa', dateOptions)} اليوم،`;
  }

  if (isYesterday(timestamp)) {
    if (activeLocale === 'en') {
      return `Yesterday, ${format(timestamp, 'h:mm a', dateOptions)}`;
    }

    return `${format(timestamp, 'h:mm aaa', dateOptions)} أمس`;
  }

  // if (differenceInMins < 10080) {
  //   const formattedDate = format(timestamp, 'EEE, h:mm aaa', dateOptions);

  //   return formattedDate;
  // }

  if (differenceInMins < 525600 && isDateInDaysAgoFormat) {
    /**
     * if `showExactDate` flag is true then return the exact date
     */
    if (showExactDate) {
      return format(timestamp, 'd MMM yyyy, h:mm a', dateOptions);
    }
    const differenceInDays = Math.round(differenceInMins / 1440);
    if (activeLocale === 'en') {
      return `${differenceInDays} day${differenceInDays > 1 ? 's' : ''} ago`;
    }

    return `منذ ${differenceInDays} أيام`;
  }

  const formattedDate = format(
    timestamp,
    dateFormat ?? 'd MMM yyyy',
    dateOptions
  );

  return formattedDate;
};

/**
 * This function converts Date to formatted string eg: "2022/12/13"
 *
 * @param date
 * @returns string
 */
export const getFormattedDate = (date: Date, formatString?: string): string =>
  format(date, formatString || 'yyyy-MM-dd');

/**
 * This function substract current date with given days count
 *
 * @param days
 * @returns string
 */
export const getSubDate = (days: number): string => {
  if (days === 0) return '';

  const subDate = subDays(new Date(), days);

  return getFormattedDate(subDate);
};

/**
 * Gets a human readable relative date from the timestamp.
 *
 * @param date number - The date in milliseconds
 * @returns string - The human relative date.
 */
export const convertToHumanReadableTimestamp = (date?: number) => {
  let timestamp = '';

  if (date) {
    timestamp = getHumanReadableTimeStamp({
      timestamp: date,
    });
  }

  return timestamp;
};

/**
 * Gets the current time zone of the user in "Region/City" format.
 *
 * @returns string
 */
export const getUserTimeZone = () => {
  const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const regionName = timezoneName.split('/')[0];
  const cityNames = timezoneName.split('/')[1].split('_').join(' ');
  const timezoneFormattedName = `${regionName}/${cityNames}`;

  return timezoneFormattedName;
};

export { isToday, isYesterday, format };

/**
 * This function calculates the number of days left from the given date.
 */
export const getDaysFromDate = (date: number): number => {
  // Set your target date here
  const targetDate = new Date(date);

  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const differenceMs = targetDate.getTime() - currentDate.getTime();

  // Convert milliseconds to days
  const daysLeft = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

  return daysLeft;
};
