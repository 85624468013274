import { Text } from '@northladder/i18n';
import { getTextFromI18n } from '@northladder/utilities';
import { ChangeEvent } from 'react';

import { IBaseInputCheckItem } from '../../types';

import { CheckInputContainer } from './styles';

interface ICheckInputProps extends IBaseInputCheckItem {
  // Add addition TextInput specific props here.
}

/**
 * -----------------------------------------------------------------------------
 * Creates a theme-able CheckInput component that wraps around s primitive
 * checkbox box with additional self contained labels.
 *
 * TODO; Add tooltips and popovers later.
 */
export function CheckInput({
  checked,
  label,
  name,
  onChange,
  required = false,
  title,
  locale,
}: ICheckInputProps) {
  const handleToggleCheck = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  const tTitle = getTextFromI18n({ locale, text: title || label });

  return (
    <CheckInputContainer title={tTitle}>
      <div className="check-wrapper">
        <input
          aria-describedby={name}
          checked={checked}
          id={name}
          onChange={handleToggleCheck}
          required={required}
          type="checkbox"
        />
      </div>
      <div className="label-wrapper">
        <label className="form-label" htmlFor={name} title={tTitle}>
          <Text text={label} />
        </label>
      </div>
    </CheckInputContainer>
  );
}
