import { createZustandStore } from '../../zustand';

import {
  onClearTxnStoreCallback,
  onUpdateCreditLimitExceedDateCallback,
} from './callbacks';
import { ITransactionsState } from './types';

/**
 * This creates a local transactions state tracker and update callbacks to track
 * the actively all transactions app side and server side state.
 *
 * TODO: Utilize callback functions to store these store values in local storage later on.
 */
export const useTransactionStore = createZustandStore<ITransactionsState>(
  (set) => ({
    activeFilters: null,
    activeListType: null,
    creditLimitExceededDate: undefined,
    dateRange: null,
    pageNo: 1,
    searchKey: '',
    selectedBulkPurchaseModel: undefined,
    onUpdateTxnActiveFilters: (filters) =>
      set(() => ({ activeFilters: filters })),
    onUpdateTxnActiveListType: (type) => set(() => ({ activeListType: type })),
    onUpdateCreditLimitExceededDate: onUpdateCreditLimitExceedDateCallback(set),
    onUpdateTxnDateRange: (dateRange) => set(() => ({ dateRange })),
    onUpdateTxnPageNo: (pageNo) => set(() => ({ pageNo })),
    onUpdateTxnSearchKey: (newSearch) => set(() => ({ searchKey: newSearch })),
    onUpdateSelectedBulkPurchaseModel(selectedBulkPurchaseModel) {
      set(() => ({ selectedBulkPurchaseModel }));
    },
    clearTxnStore: onClearTxnStoreCallback(set),
  })
);
