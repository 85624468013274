import tw, { styled } from 'twin.macro';

/**
 * `InputFieldWrapper` adds styling to the OtpInputField.
 *
 * @returns JSX.Element
 */
export const InputFieldWrapper = styled.div`
  ${tw`
    py-3
    w-full
    justify-evenly
    flex
    gap-x-2
    grid-cols-4
    sm:gap-x-4
  `}

  .otp-input {
    ${tw`
      w-10
      h-10
      border-2
      border-purple-200
      ring-purple-400
      ring-1
      rounded-md
      text-purple-800
      text-lg
      text-center
    `};

    &.otp-loading {
      ${tw`
        border-purple-50
        ring-purple-100
      `}
    }
  }
`;
