import tw, { styled } from 'twin.macro';

/**
 * -----------------------------------------------------------------------------
 * This styles the password visibility toggle icons.
 */
export const PasswordToggleBtnContainer = styled.button`
  ${tw`
    z-20
    h-5
    w-5
    mx-1
    shrink-0
    cursor-pointer
    rounded-lg
    text-gray-400
    transition-all
    duration-500

    focus:(border-purple-100 text-purple-900 outline-none ring-1 ring-purple-100 ring-offset-4)
    active:(scale-50 text-purple-900)
    rtl:(left-2 right-auto)
  `};
`;
