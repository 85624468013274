import { TSvgComponentProps } from '../../../../types';

/**
 * -----------------------------------------------------------------------------
 * This is a JSX Svg icon. It denotes `Rank one`
 *
 * @param TSvgComponentProps
 *
 * @returns JSX.Element
 */
function IconFirstRank(props: TSvgComponentProps) {
  return (
    <svg
      fill="none"
      height="25px"
      role="figure"
      width="40px"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M11.5 2.417a5.75 5.75 0 1 0 3.038 10.633.953.953 0 0 1 .154-.1A5.75 5.75 0 0 0 11.5 2.416Zm4.66 11.839a7.667 7.667 0 1 0-9.317 0l-1.084 8.16a.958.958 0 0 0 1.443.947l4.298-2.579 4.299 2.58a.958.958 0 0 0 1.443-.948l-1.083-8.16Zm-1.798 1.025a7.645 7.645 0 0 1-2.862.552 7.644 7.644 0 0 1-2.86-.551l-.72 5.415 3.087-1.852a.958.958 0 0 1 .986 0l3.087 1.852-.718-5.416Z"
        fill="#459B39"
        fillRule="evenodd"
      />
      <path
        d="M12.598 4.908v6.862H11.01V6.183H9.639V4.908h2.96Z"
        fill="#459B39"
      />
    </svg>
  );
}

export default IconFirstRank;
