import { IBaseInputCheckItem } from '../../types';
import { InputCheckItem } from '../../form-inputs';

type TProductSourceMeCheckFieldProps = Pick<
  IBaseInputCheckItem,
  'checked' | 'onChange' | 'title' | 'name' | 'label'
>;

/**
 * -----------------------------------------------------------------------------
 * This toggles the checkboxes field in `RegistrationStep3Form`.
 */
export function ProductSourceCheckField({
  name,
  title,
  label,
  checked,
  onChange,
}: TProductSourceMeCheckFieldProps) {
  return (
    <InputCheckItem
      checked={checked}
      label={label}
      name={name}
      onChange={onChange}
      required={false}
      title={title}
    />
  );
}
