import { TSvgComponentProps } from './types';

/**
 * -----------------------------------------------------------------------------
 * This renders a rectangular UAE National Flag SVG component so it can easily be
 * customized at runtime.
 *
 * @param {{size: number}}
 */
export function FlagUAE({ size = 32, ...rest }: TSvgComponentProps) {
  return (
    <svg
      height={size}
      id="FlagUAE"
      viewBox="0 0 512 340"
      width={size * 1.5}
      {...rest}
    >
      <g id="uae">
        <path
          className={`fill-green-700 ${rest?.className}`}
          d="M0 0h512v340H0z"
          id="top-green"
        />
        <path
          className="fill-white"
          d="M0 113.3h512V340H0z"
          id="white-middle"
        />
        <path
          className="fill-black"
          d="M0 226.7h512V340H0z"
          id="bottom-black"
        />
        <path className="fill-red-600" d="M0 0h128v340H0z" id="left-red" />
      </g>
    </svg>
  );
}
