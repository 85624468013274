import { IAddRegistrationOptions, TSetRegistration } from '../types';

import { persistRegistrationStore } from './registrationStoreLocalStorageUtils';

/**
 * This adds a registration instance to track across the app.
 */
export function addRegistrationSessionCallback(set: TSetRegistration) {
  return function updateRegistrationStore({
    user,
    token,
    refreshToken,
  }: IAddRegistrationOptions) {
    set(() => {
      const updatedRegistrationSession = {
        // Don't save the fresh token if the user does not want to remember session.
        refreshToken,
        token,
        user,
      };

      const updatedRegistrationStoreFields = {
        registrationSession: updatedRegistrationSession,
      };

      persistRegistrationStore(updatedRegistrationStoreFields);

      return updatedRegistrationStoreFields;
    });
  };
}
