import { TCompanyIds } from './types';
import { companiesEnvironment } from './environment';

/**
 * This indexes the companiesEnvironment object to retrieve the config based on the
 * company id.
 *
 * @returns env TCompaniesEnvironment - The company configuration.
 */
export const getCompanyEnvironment = () => {
  const companyId = process.env.NEXT_PUBLIC_PAAS_COMPANY_ID as TCompanyIds;
  return companiesEnvironment[companyId];
};
