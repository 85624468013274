/* global google */

import { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  useAutocomplete,
  useGoogleMap,
} from '@ubilabs/google-maps-react-hooks';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

import { ICoordinate, IGooglePlace } from '../../types';
import { MapSearchInputWrapper } from '../../styles';

interface IMapPlacesAutocompleteWidgetProps {
  onUpdateLocation: (location: ICoordinate) => void;
}

/**
 * -----------------------------------------------------------------------------
 * This allows for the user to search through the map auto-complete feature
 * for cleaner locations labels
 */
export function MapPlacesAutocompleteWidget({
  onUpdateLocation,
}: IMapPlacesAutocompleteWidgetProps) {
  const map = useGoogleMap();

  const [activeMap, setActiveMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (map) {
      setActiveMap(map);
    }
  }, [map]);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [inputValue, setInputValue] = useState('');

  const [_, setFormattedSelectedLocation] = useState<ICoordinate | null>(null);

  const handelOnPlaceChanged = (place: IGooglePlace) => {
    const geoLoc = place.geometry?.location;

    if (geoLoc) {
      const { name, formatted_address: formattedAddress } = place;

      const newLocation: ICoordinate = {
        lat: geoLoc.lat(),
        lng: geoLoc.lng(),
        zoom: activeMap?.getZoom() || 15,
        title: formattedAddress || name,
      };

      setFormattedSelectedLocation(newLocation);

      if (!formattedAddress || !name) return;

      setInputValue(formattedAddress || name);

      // Keep focus on input element
      inputRef.current?.focus();

      /**
       * Push this to the calling parent to update.
       */
      onUpdateLocation(newLocation);
    }
  };

  /**
   * Use the useAutocomplete hook and pass the input field ref and the
   * onPlaceChanged function to it
   */
  useAutocomplete({
    inputField: inputRef && inputRef.current,
    onPlaceChanged: handelOnPlaceChanged,
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event?.target?.value);
  };

  return (
    <MapSearchInputWrapper>
      <input
        ref={inputRef}
        className="form-input-field"
        onChange={handleInputChange}
        value={inputValue}
      />
      <MagnifyingGlassIcon className="search-icon" />
    </MapSearchInputWrapper>
  );
}
