import { forwardRef } from 'react';

import { IBaseGoogleMapLocationOptions, ICoordinate } from '../../types';
import { MAP_ORIGINS } from '../../constants';
import {
  LocateMeOnMap,
  MapCanvas,
  MapMarker,
  MapPlacesAutocompleteWidget,
} from '../../components';

type TGoogleMapProps = Pick<
  IBaseGoogleMapLocationOptions,
  'regionId' | 'regionKey'
> & {
  initLocation: ICoordinate | null;
  onUpdateLocation: (location: ICoordinate) => void;
};

/**
 * -----------------------------------------------------------------------------
 * This is a wrapper around the base map provider component that abstract away
 * map setup logic.
 */
export const GoogleMap = forwardRef<HTMLDivElement, TGoogleMapProps>(
  ({ initLocation, regionId, onUpdateLocation }, ref) => {
    const location = initLocation || MAP_ORIGINS[regionId];

    return (
      <>
        <MapCanvas ref={ref} />
        <MapMarker location={location} onUpdateLocation={onUpdateLocation} />
        <MapPlacesAutocompleteWidget onUpdateLocation={onUpdateLocation} />
        <LocateMeOnMap
          location={location}
          onUpdateLocation={onUpdateLocation}
        />
      </>
    );
  }
);

GoogleMap.displayName = 'GoogleMap';
