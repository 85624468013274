import { UrlObject } from 'url';
import Link from 'next/link';

import { TLanguagesConfigItem } from '@northladder/services';

import { FlagIcon } from '../../Flags/flagIcons';

interface ISetLocaleCTAProps {
  /**
   * The path as shown in the browser including the search params and respecting
   * the `trailingSlash` configuration. `basePath` and `locale` are not included.
   */
  // asPath?: string;
  /**
   * The final path to be passed to the underlying `<a>` link tag
   */
  href: UrlObject | string;
  /**
   * A list of items for the dropdown.
   */
  language: TLanguagesConfigItem;
  /**
   * className for css configuration
   */
  className?: string;
}

/**
 * -----------------------------------------------------------------------------
 * This provides a CTA that switches the language to the target one.
 * Note that this will only be present if just two locales are present.
 *
 * - It displays the country flag alongside the locale name.
 * - See also `LocaleMenu`, shown when more than 2 locales are active.
 *
 * @param ILocaleMenuProps
 * @returns JSX.Element
 */
export function SetLocaleCTA({
  // asPath,
  href,
  language,
  className,
}: ISetLocaleCTAProps) {
  return (
    <Link
      // as={asPath}
      className={`
        z-index-20 inline-flex w-full items-center justify-between
        rounded-3xl border border-gray-200 bg-white p-2 text-sm font-normal
        hover:bg-purple-50 focus:outline-none focus:ring-2 focus:ring-purple-100
        focus:ring-offset-2 focus:ring-offset-gray-100 focus:hover:bg-purple-200
        rtl:text-xs 2xl:rounded-b-2xl 2xl:rounded-t-none ${className}
      `}
      href={href}
      locale={language.locale}
    >
      <FlagIcon className="shrink-0 rounded-lg" id={language.icon} size={22} />
      {language.label}
    </Link>
  );
}
