/* eslint-disable import/no-import-module-exports */
import { createInstance, Types } from '@amplitude/analytics-react-native';

import { getMobileRegionEnvironment } from '@northladder/utilities';

import { useAuthStore } from '../stores';

import {
  IAnalyticsOptions,
  ICustomUserId,
  IParamDefaults,
  IUserProperties,
} from './types';

/**
 * Class for `Amplitude Analytics` to create only a single instance using singleton
 * pattern.
 */
export class AmplitudeAnalytics {
  private static classInstance: AmplitudeAnalytics;

  static defaults: IParamDefaults = {
    ANY: 'Any',
    DEFAULT: 'Default',
    MISSING: 'Missing',
    NONE: 'None',
  };

  private amplitudeInstance: Types.ReactNativeClient;

  private activeUserProperty: IUserProperties | null = null;

  private constructor() {
    this.amplitudeInstance = createInstance();

    const { DEALER_MOBILE_AMPLITUDE_ANALYTICS_API_KEY } =
      getMobileRegionEnvironment() ?? {};

    /**
     * Exit early to prevent logging these events while the app in in the developer
     * debug mode. Please run a release build for you to trigger this.
     */
    if (__DEV__) return;

    if (DEALER_MOBILE_AMPLITUDE_ANALYTICS_API_KEY) {
      this.amplitudeInstance.init(DEALER_MOBILE_AMPLITUDE_ANALYTICS_API_KEY);
    }
  }

  public static getClientInstance = () => {
    if (!this.classInstance) {
      this.classInstance = new AmplitudeAnalytics();
    }

    return this.classInstance;
  };

  /**
   * This fires an `Amplitude` event tracking user interaction.
   */
  public trackEvent({ eventName, params }: IAnalyticsOptions) {
    /**
     * Exit early, don't bother triggering events that are not going to get
     * logged.
     */
    if (__DEV__) return;

    const { DEALER_MOBILE_AMPLITUDE_ANALYTICS_VERSION } =
      getMobileRegionEnvironment() ?? {};

    /**
     * This is a temporary gate that will be removed later when the events are
     * stable.
     * Replace this with `NODE_ENV !== 'production'` when prod ready.
     */
    const shouldPrefixProdEvents = true;

    let firedEventName = eventName;

    // if (NODE_ENV !== 'production') {
    if (shouldPrefixProdEvents) {
      firedEventName = `${DEALER_MOBILE_AMPLITUDE_ANALYTICS_VERSION}_${eventName}`;
    }

    const userAgent = this.activeUserProperty;
    const globalParams = { ...params, ...userAgent };

    this.amplitudeInstance.track(firedEventName, globalParams);
  }

  /**
   * This will be used to set `userId` for amplitude analytics.
   */
  public setCustomUserId({ userId }: ICustomUserId) {
    /**
     * Exit early, don't bother triggering events that are not going to get
     * logged.
     */
    if (__DEV__) return;

    this.amplitudeInstance.setUserId(userId);
  }

  /**
   * This will be used to set `userProperties` for amplitude analytics.
   */
  public setUserProperties() {
    /**
     * Exit early, don't bother triggering events that are not going to get
     * logged.
     */
    if (__DEV__) return;

    const userInfo = useAuthStore.getState().activeSession?.user;
    const { courierPaymentType, deviceInfo, userType, currency } =
      userInfo ?? {};

    this.activeUserProperty = {
      courierPaymentType,
      deviceInfo,
      userType,
      currency,
    };
  }
}
