import { forwardRef } from 'react';

import { MapContainer } from '../../styles';

interface IMapCanvasProps {
  /**
   * The styles classes to map around the map canvas container
   */
  className?: string;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the component that will be used to display the underlying map.
 */
export const MapCanvas = forwardRef<HTMLDivElement, IMapCanvasProps>(
  ({ className }, ref) => <MapContainer ref={ref} className={className} />
);

MapCanvas.displayName = 'MapCanvas';
