import { TSvgComponentProps } from '../../../../types';

/**
 * IconArrowDown is a svg for the download icon.
 *
 * @param TSvgComponentProps
 *
 * @returns JSX.Element
 */
function IconArrowDown(props: TSvgComponentProps) {
  return (
    <svg
      fill="none"
      viewBox="0 0 13 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.75 15L6.75 0.999999M6.75 15L12 9.75M6.75 15L1.5 9.75"
        stroke="#F59E0B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
}

export default IconArrowDown;
