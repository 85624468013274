import { Text } from '@northladder/i18n';

import { TUIError } from '../../../types';
import { translations } from '../../../translations';

import { FormFeedbackSection } from '../../forms';

interface IOTPModalTriggerProps {
  error: TUIError;
  isEnabled: boolean;
  isLoading: boolean;
  isOTPValidated: boolean;
  onOpenModal: () => void;
  onSendOTP: () => void;
  otpTriggerClassName: string;
}

export function OTPModalTrigger({
  error,
  isEnabled,
  isLoading,
  isOTPValidated,
  onOpenModal,
  onSendOTP,
  otpTriggerClassName,
}: IOTPModalTriggerProps) {
  const handleOnClick = () => {
    if (isEnabled) {
      onOpenModal();
      onSendOTP();
    }
  };

  return !isOTPValidated ? (
    <div>
      <button
        className={`
          absolute hidden border-none p-2 text-sm font-normal
          ${otpTriggerClassName} rtl:right-52 lg:flex
          ${isEnabled ? 'text-purple-700 ' : 'text-gray-400 '}
        `}
        disabled={!isEnabled}
        onClick={handleOnClick}
        type="button"
      >
        {isLoading ? (
          <Text text={translations.forms.sendingOtp} />
        ) : (
          <Text text={translations.forms.verify} />
        )}
      </button>

      <button
        className={`
          form-btn mt-8 flex justify-center lg:hidden
          ${isEnabled ? 'text-white' : 'text-gray-400'}
        `}
        disabled={!isEnabled}
        onClick={handleOnClick}
        type="button"
      >
        {isLoading ? (
          <Text text={translations.forms.sendingOtp} />
        ) : (
          <Text text={translations.forms.verifyNumber} />
        )}
      </button>

      {error?.message ? (
        <FormFeedbackSection
          description={error.description || ''}
          isFormSubmitting={false}
          message={error.message}
          type="error"
        />
      ) : null}
    </div>
  ) : null;
}
