/**
 * The type of the value stored in MMKV.
 * Read more about `Uint8Array` here - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Uint8Array
 */
type TStoredValue = string | number | boolean | Uint8Array;

/**
 * This is being conditionally loaded to not break the web application.
 */
export const configureMMKVStorage = () => {
  try {
    const { MMKV } = require('react-native-mmkv');

    /**
     * This creates a reference to the `MMKV` storage engine, providing a fast &
     * synchronous storage utility.
     *
     * - [Read MORE:](https://github.com/mrousavy/react-native-mmkv)
     */
    const storage = new MMKV({
      id: 'app-storage', // Get from ENV.
      // path: 'USER_DIRECTORY/storage', // Make this dynamic to create user specific storage.
      encryptionKey: 'dealer-app', // app encryption key. Get from ENV.
    });

    return {
      /**
       * Saves a `string`, `number`, `boolean` or `Uint8Array` to storage.
       * If you want to store an object, use `saveData`.
       *
       * @param key The key to fetch.
       * @param value The value to store.
       */
      save(key: string, value: TStoredValue) {
        storage.set(key, value);
      },

      /**
       * Loads a string from mmkv storage.
       *
       * @param key The key to fetch.
       */
      loadString(key: string) {
        return storage.getString(key);
      },

      /**
       * Loads a number from mmkv storage.
       *
       * @param key The key to fetch.
       */
      loadNumber(key: string) {
        return storage.getNumber(key);
      },

      /**
       * Loads a boolean from mmkv storage.
       *
       * @param key The key to fetch.
       */
      loadBoolean(key: string) {
        return storage.getBoolean(key);
      },

      /**
       * Saves an object to storage or unknown value. If you want to store a `string`,
       * `number`, `boolean` or `Uint8Array`, use `save` instead.
       *
       * - Note: This will return `true` if the save was successful, and `false` if not.
       *
       * @param key The key to fetch.
       * @param value The value to store.
       */
      saveData(key: string, value: unknown) {
        try {
          storage.set(key, JSON.stringify(value));

          return true;
        } catch {
          return false;
        }
      },

      /**
       * Loads something from storage and runs it thru JSON.parse.
       * This is useful if what was saved was an object and not of type `TStoredValue`.
       *
       * - Note: This will return `null` if the data could not be parsed.
       *
       * @param key The key to fetch.
       */
      loadData<T = TStoredValue>(key: string): T | null {
        const stringifiedData = storage.getString(key);

        try {
          if (stringifiedData) {
            return JSON.parse(stringifiedData);
          }

          return null;
        } catch {
          return null;
        }
      },

      /**
       * Delete a specific key + value from mmkv storage.
       *
       * REF - https://github.com/mrousavy/react-native-mmkv#keys
       *
       * @param key - The key to delete.
       */
      removeData(key: string) {
        storage.delete(key);
      },

      /**
       * Burn it all to the ground.
       *
       * REF - https://github.com/mrousavy/react-native-mmkv#keys
       */
      clearData() {
        storage.clearAll();
      },
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to resolve react-native-mmkv:', error);

    return null;
  }
};
