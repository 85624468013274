import { createZustandStore } from '../../../zustand';

import { ISearchState } from './types';

/**
 * This creates a local global search key state tracker and update the search
 * key using `setSearchKey`
 */
export const useSearchStore = createZustandStore<ISearchState>()((set) => ({
  searchKey: '',
  updateSearchKey: (newKey) => set(() => ({ searchKey: newKey })),
}));
