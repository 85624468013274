import { TSetDealsFilters } from '../types';

/**
 * This clears the deals filter store
 */
export function onClearDealsFilterStoreCallback(set: TSetDealsFilters) {
  return function onUpdateTxnSearchKey() {
    set(() => ({ selectedDealsFilters: [] }));
  };
}
