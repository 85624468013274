import Link from 'next/link';
import { useRouter } from 'next/router';
import { CheckIcon } from '@heroicons/react/20/solid';

import { Text, TTextLocalized, TLocale } from '@northladder/i18n';
import { getTextFromI18n } from '@northladder/utilities';

import { StepContainer } from './styles';

export interface IStep {
  /**
   * The description of the step. Passed down to the hover title and aria readers.
   */
  description: TTextLocalized;
  /**
   * The count number to be displayed within the circle of the step.
   * Translation ready.
   */
  name: TTextLocalized;
  /**
   * The URL to visit to jump between steps.
   * NOTE: Could be blocked on the current step.
   */
  href: string;
  /**
   * Displays the status of the step, for example if it's the current one or complete.
   */
  status: 'current' | 'complete' | 'upcoming';
  /**
   * Whether the step renders an icon such as a tick/checkmark or not.
   * If not, the step number is used instead.
   */
  usesIcon?: boolean;
}

type IStepVariantProps = Pick<IStep, 'name' | 'href' | 'usesIcon'> & {
  /**
   * This is computed from the description based on the active locale and passed
   * down to the title attribute as a string.
   */
  title: string;
};

/**
 * -----------------------------------------------------------------------------
 * This renders an previously completed step component.
 */
function StepComplete({ href, name, title, usesIcon }: IStepVariantProps) {
  return (
    <>
      <div aria-hidden="true" className="absolute inset-0 flex items-center">
        <div className="h-0.5 w-full bg-purple-600" />
      </div>
      <Link className="step-indicator-complete" href={href} title={title}>
        {usesIcon ? (
          <CheckIcon aria-hidden="true" className="h-4 w-4 text-white" />
        ) : (
          <span className="flex h-4 w-4 items-center justify-center text-sm text-white">
            <Text text={name} />
          </span>
        )}
        <span className="sr-only">
          <Text text={name} />
        </span>
      </Link>
    </>
  );
}

type TStepCurrentProps = Omit<IStepVariantProps, 'href'>;

/**
 * -----------------------------------------------------------------------------
 * This renders an current step component.
 * Styled to indicate the current page context.
 */
function StepCurrent({ name, title, usesIcon }: TStepCurrentProps) {
  return (
    <>
      <div aria-hidden="true" className="absolute inset-0 flex items-center">
        <div className="h-0.5 w-full bg-gray-200" />
      </div>
      <div aria-current="step" className="step-indicator-current" title={title}>
        {usesIcon ? (
          <span
            aria-hidden="true"
            className="h-2 w-2 rounded-full bg-purple-600"
          />
        ) : (
          <span
            className="
              flex h-4 w-4 items-center justify-center text-sm text-purple-700
            "
          >
            <Text text={name} />
          </span>
        )}
        <span className="sr-only">
          <Text text={name} />
        </span>
      </div>
    </>
  );
}

/**
 * -----------------------------------------------------------------------------
 * This renders an upcoming step component, that has not been completed.
 * Note: If not status is provided, this is rendered as the default.
 */
function StepUpcoming({ href, name, title, usesIcon }: IStepVariantProps) {
  return (
    <>
      <div aria-hidden="true" className="absolute inset-0 flex items-center">
        <div className="h-0.5 w-full bg-gray-200" />
      </div>
      <Link className="step-indicator-upcoming group" href={href} title={title}>
        {usesIcon ? (
          <span
            aria-hidden="true"
            className="h-2 w-2 rounded-full bg-purple-600"
          />
        ) : (
          <span
            className="
              flex h-4 w-4 items-center justify-center rounded-full text-sm
              text-gray-400 transition-all duration-500
              group-hover:bg-purple-700
              group-hover:text-white
            "
          >
            <Text text={name} />
          </span>
        )}
        <span className="sr-only">
          <Text text={name} />
        </span>
      </Link>
    </>
  );
}

interface IStepItemProps {
  step: IStep;
}

/**
 * -----------------------------------------------------------------------------
 * This renders the respective step item based on the status of the step,
 * Whether it's `complete`, `current` or `upcoming` (the default).
 */
function StepItem({ step }: IStepItemProps) {
  const { locale } = useRouter();
  const textLocale = locale || 'en';

  const { description, href, name, usesIcon, status } = step;
  const title = getTextFromI18n({
    text: description,
    locale: textLocale as TLocale,
  });

  if (status === 'complete') {
    return (
      <StepComplete href={href} name={name} title={title} usesIcon={usesIcon} />
    );
  }

  if (status === 'current') {
    return <StepCurrent name={name} title={title} usesIcon={usesIcon} />;
  }

  return (
    <StepUpcoming href={href} name={name} title={title} usesIcon={usesIcon} />
  );
}

interface IStepProps {
  step: IStep;
  isNotLastItem: boolean;
}

/**
 * -----------------------------------------------------------------------------
 * The wrapper around the step item providing styles for the step item component,
 * renders the rounded step indicator and the horizontal line between steps.
 */
function Step({ step, isNotLastItem: isLastItem }: IStepProps) {
  return (
    <StepContainer className={isLastItem ? 'is-last-item' : ''}>
      <StepItem step={step} />
    </StepContainer>
  );
}

interface IStepsProps {
  ariaLabel?: string;
  className?: string;
  steps: IStep[];
}

/**
 * -----------------------------------------------------------------------------
 * This renders navigable numbered steps to show current progress of a multi-step
 * UI journey.
 */
export function Steps({
  ariaLabel = 'Progress',
  className,
  steps,
}: IStepsProps) {
  return (
    <nav aria-label={ariaLabel} className={`flex justify-center ${className}`}>
      <ol className="flex items-center">
        {steps.map((step, stepIndex) => {
          const isNotLastItem = stepIndex !== steps.length - 1;

          return (
            <Step key={step.href} isNotLastItem={isNotLastItem} step={step} />
          );
        })}
      </ol>
    </nav>
  );
}
