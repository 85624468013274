import { useState } from 'react';
import Image from 'next/image';
import { PhotoIcon } from '@heroicons/react/20/solid';

import { CircularLoader } from '../../loaders';

import { PreviewImageFullContainer } from './styles';

interface IPreviewImageFullProps {
  /**
   * The url segment that resolved the image file.
   */
  url: string;
}

/**
 * -----------------------------------------------------------------------------
 * This shows a large preview of an image loaded from the path provided.
 * TODO: Move the image preview component to a separate/images folder.
 */
export function PreviewImageFull({ url }: IPreviewImageFullProps) {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleOnLoadImage = () => {
    setIsImageLoaded(true);
  };

  return (
    <PreviewImageFullContainer>
      {!isImageLoaded ? (
        <>
          <PhotoIcon className="absolute h-16 w-16 text-gray-200" />
          <CircularLoader className="absolute h-8 w-8 text-gray-200 opacity-20" />
        </>
      ) : null}

      <Image
        alt=""
        height={1200}
        onLoad={handleOnLoadImage}
        src={url}
        width={1200}
      />
    </PreviewImageFullContainer>
  );
}
