import { IActiveSession, TSetAuthState } from '../types';

import {
  IPersistAuthStoreTargetState,
  persistAuthStore,
} from './authStoreLocalStorageUtils';

/**
 * This updates the active session in both local & app memory.
 */
export function updateActiveSessionCallback(set: TSetAuthState) {
  return function updateAuthStore(session: IActiveSession) {
    set(
      ({
        activityLog,
        allSessionsById,
        allSessionsByIndex,
        allSessionsIndices,
      }) => {
        const updatedStoreFields: IPersistAuthStoreTargetState = {
          activeSession: session,
          activityLog,
          allSessionsById,
          allSessionsByIndex,
          allSessionsIndices,
        };

        persistAuthStore(updatedStoreFields);

        return updatedStoreFields;
      }
    );
  };
}
