import { useEffect } from 'react';

import { IDeviceInfo } from '../../types';

import { useAuthStore } from './useAuthStore';

interface IUseAuthenticationOptions {
  accountId: number;
  deviceInfo?: IDeviceInfo;
  locale: 'en' | 'ar';
}

/**
 * -----------------------------------------------------------------------------
 * This provides logic to retrieve auth info from the persisted storage and
 * configures it at app startup.
 *
 * - NOTE: Manages the local state with `authStore` -> `zustand` store.
 */
export const useAuthentication = ({
  accountId,
  deviceInfo,
  locale,
}: IUseAuthenticationOptions) => {
  const { activeSession, activityLog, updateActivityLog } = useAuthStore();

  useEffect(() => {
    if (accountId !== activeSession?.index) {
      updateActivityLog({
        ...(activityLog || {}),
        locale,
        deviceInfo,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
