import { styled } from 'twin.macro';
import { TSvgComponentProps } from '../../types';

/**
 * Custom Svg Circle icon for loader. it draw a circle radius of 45
 *
 * @param `TSvgComponentProps`
 *
 * @returns Jsx.Element
 */
function LoadingIcon(props: TSvgComponentProps) {
  return (
    <svg
      height="1em"
      viewBox="0 0 100 100"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={50} cy={50} r={45} />
    </svg>
  );
}

/**
 * This is a Circular Progress indicator for loading.
 * It will add Styles and animations for `LoaderIcon` it will give a circular
 * animation with 2s delay.
 *
 * @param `strokeColor` for changing the circular progress indicator color
 *
 * @returns Jsx.Element
 */
export const CircularProgressLoader = styled(LoadingIcon)<{
  strokecolor?: string;
  strokeWidth?: number | string;
}>`
  animation: 2s linear infinite svg-animation;
  max-width: 100px;

  @keyframes svg-animation {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }

  circle {
    animation: 1.4s ease-in-out infinite both circle-animation;
    display: block;
    fill: transparent;
    stroke: ${(p) => p.strokecolor || '#3b1e85'};
    stroke-linecap: round;
    stroke-dasharray: 283;
    stroke-dashoffset: 280;
    stroke-width: ${(p) => p.strokeWidth || '10px'};
    transform-origin: 50% 50%;
  }

  @keyframes circle-animation {
    0%,
    25% {
      stroke-dashoffset: 280;
      transform: rotate(0);
    }

    50%,
    75% {
      stroke-dashoffset: 75;
      transform: rotate(45deg);
    }

    100% {
      stroke-dashoffset: 280;
      transform: rotate(360deg);
    }
  }
`;
