/**
 * This provides a local translation layer for all items int he design-system.
 *
 * - NOTE: These are the base and are exposed for overwriting within the calling
 * parent components.
 * - NOTE 2: The translations are kept here to provide for `storybook` internationalization.
 */
export const translations = {
  formFields: {
    rememberMe: {
      en: 'Remember Me',
      ar: 'تذكر حسابي',
    },
    rememberMeTitle: {
      en: "If selected, you won't have to login in again next time even when you close your browser.",
      ar: 'إذا تم تحديده ، فلن تضطر إلى تسجيل الدخول مرة أخرى في المرة القادمة حتى عندما تغلق المتصفح.',
    },
  },
  formSubmission: {
    pleaseWait: {
      en: 'Please Wait',
      ar: 'ارجوك انتظر',
    },
    verifyingDetails: {
      en: 'Verifying your details. ',
      ar: ' التحقق من التفاصيل الخاصة بك.',
    },
  },
  documentUpload: {
    uploadCTA: {
      en: 'Click to Upload',
      ar: 'انقر للتحميل',
    },
    addAnother: {
      en: 'Add another',
      ar: 'أضف آخر',
    },
    uploadDone: {
      en: 'Upload successful',
      ar: 'تحميل ناجح',
    },
    modalCTA: {
      en: 'Drop the Files here',
      ar: 'أسقط الملفات هنا',
    },
    modalDesc: {
      en: 'Drag and drop the files here or Click to select a file',
      ar: 'اسحب الملفات وأفلتها هنا أو انقر لتحديد ملف',
    },
    modalAcceptedFileType: {
      en: 'Supports .jpeg, .jpg, .png with max size of 15 MB',
      ar: 'يدعم .jpeg ، .jpg ، .png بحد أقصى 15 ميجا بايت',
    },
    maxUploadSizeError: {
      en: 'File size is too large. Files cannot be larger than 15 MB',
      ar: 'حجم الملف كبير جدًا. لا يمكن أن يزيد حجم الملفات عن 15 ميغا بايت',
    },
    uploadFailed: {
      en: 'Upload Failed',
      ar: 'التحميل فشل',
    },
    uploadButton: {
      en: 'Upload',
      ar: 'رفع',
    },
    closeButton: {
      en: 'Close',
      ar: 'يغلق',
    },
  },
  camera: {
    capture: {
      en: 'Capture',
      ar: 'يأسر',
    },
    capturing: {
      en: 'Processing',
      ar: 'جاري المعالجة',
    },
    refreshingCamera: {
      en: 'Refreshing the Camera',
      ar: 'تحديث الكاميرا',
    },
    refreshPleaseWait: {
      en: 'please wait',
      ar: 'أرجو الإنتظار',
    },
  },
  cropping: {
    back: {
      en: 'Back',
      ar: 'خبز',
    },
    cancel: {
      en: 'Cancel',
      ar: 'يلغي',
    },
    preview: {
      en: 'Preview',
      ar: 'معاينة',
    },
    upload: {
      en: 'Upload',
      ar: 'رفع',
    },
  },
  footer: {
    copyright: {
      en: 'Copyright © 2023',
      ar: 'حقوق النشر © 2023',
    },
    rightsReserved: {
      en: 'All rights reserved.',
      ar: 'كل الحقوق محفوظة.',
    },
  },
  forms: {
    validateOtp: {
      en: 'Validate OTP',
      ar: 'التحقق من صحة كلمة المرور لمرة واحدة',
    },
    pleaseWait: {
      en: 'Please Wait',
      ar: 'ارجوك انتظر',
    },
    verify: {
      en: 'Verify Code',
      ar: 'التحقق من كود',
    },
    verifyNumber: {
      en: 'Verify Number',
      ar: 'تحقق من الرقم',
    },
    sendingOtp: {
      en: 'Sending OTP, Please wait',
      ar: 'إرسال OTP ، من فضلك انتظر',
    },
  },
  otpModal: {
    inputCapture: {
      title: {
        en: 'OTP Verification',
        ar: 'التحقق من OTP',
      },
      message: {
        en: 'Enter the OTP sent to your mobile number',
        ar: 'أدخل OTP المرسل إلى رقم هاتفك المحمول',
      },
      description: (phoneNumber: string) => ({
        en: `The OTP was sent to ${phoneNumber}`,
        ar: `${phoneNumber} تم إرسال OTP إلى`,
      }),
      resendCodeMessage: {
        en: 'Did not receive the Code?',
        ar: 'لم تحصل على المدونة؟',
      },
      resendCode: {
        en: 'Resend Code',
        ar: 'أعد إرسال الرمز',
      },
    },
    loadingState: {
      title: {
        en: 'Sending OTP',
        ar: 'إرسال OTP',
      },
      message: (phoneNumber: string) => ({
        en: `We are sending a one time password to your mobile ${phoneNumber}`,
        ar: `نرسل كلمة مرور لمرة واحدة إلى هاتفك المحمول ${phoneNumber}`,
      }),
      info: {
        en: 'Please check your text messages for the OTP code.',
        ar: 'يرجى التحقق من الرسائل النصية الخاصة بك للحصول على رمز OTP.',
      },
    },
    errorState: {
      title: {
        en: 'OTP Error',
        ar: 'خطأ في OTP',
      },
      message: {
        en: 'Sorry, something went wrong',
        ar: 'عذرا، هناك خطأ ما',
      },
      description: {
        en: 'OTP not sent.',
        ar: 'لم يتم إرسال OTP.',
      },
      description2: {
        en: 'The OTP could not be sent to the mobile number',
        ar: 'تعذر إرسال كلمة المرور لمرة واحدة إلى رقم الهاتف المحمول',
      },
      pleaseRetry: {
        en: 'Please Retry',
        ar: 'الرجاء اعادة المحاولة',
      },
      resendingOtp: {
        en: 'Resending OTP',
        ar: 'إعادة إرسال OTP',
      },
    },
  },
  /**
   * -------------------------------------------------------------------------
   * GPS Location TRANSLATIONS
   */
  gpsField: {
    gpsLocation: {
      en: 'Store Location',
      ar: 'موقع المتجر',
    },
    gpsPlaceholder: {
      en: 'Please select a store location(GPS)',
      ar: 'نظام تحديد المواقع',
    },
    theGPS: {
      en: 'The location (GPS)',
      ar: 'الموقع (GPS)',
    },
    confirmLocation: {
      en: 'Confirm',
      ar: 'يتأكد',
    },
    cancelLocation: {
      en: 'Cancel',
      ar: 'يلغي',
    },
    latitude: {
      en: 'Latitude',
      ar: 'خط العرض',
    },
    longitude: {
      en: 'Longitude',
      ar: 'خط الطول',
    },
    locateMeButton: {
      en: 'Locate Me',
      ar: 'تحديد موقع لي',
    },
  },
  tradeInDeviceDropdown: {
    title: {
      en: 'Select Device Categories',
      ar: 'حدد فئات الأجهزة',
    },
    subtitle: {
      en: 'Devices',
      ar: 'الأجهزة',
    },
  },
};
