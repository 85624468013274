import { useState, FormEvent } from 'react';

import { TError } from '@northladder/utilities';

import { IFormFieldError, IInputChanged } from '../../types';

type TTouched<T> = Record<keyof T, boolean>;

type TErrors<T> = Record<keyof T, TError>;

interface UseFormProps<T> {
  initialValues: T;
  onSubmitCb(values: T, errors?: TErrors<T>): void;
}

interface UseFormReturnType<T> {
  errors: TErrors<T>;
  handleOnBlur(options: IInputChanged<T>): void;
  handleOnInputChange(options: IInputChanged<T>): void;
  handleOnError(errorObj: IFormFieldError<T>): void;
  handleOnSubmitForm(event: FormEvent<HTMLFormElement>): void;
  handleOnResetAllErrors(): void;
  handleOnResetForm?(overrides?: Partial<T>): void;
  touched: TTouched<T>;
  values: T;
}

/**
 * -----------------------------------------------------------------------------
 * Custom hook that receives a from fields, validates each on change, blur and
 * focus provides the final payload for submission.
 */
export const useForm = <T extends {}>({
  initialValues,
  onSubmitCb,
}: UseFormProps<T>): UseFormReturnType<T> => {
  const [values, setValues] = useState(initialValues);
  const [touched, setTouched] = useState<TTouched<T>>({} as TTouched<T>);
  const [errors, setErrors] = useState<TErrors<T>>({} as TErrors<T>);

  const updateFieldValues = ({ name, value }: IInputChanged<T>) => {
    setValues(
      (prevValues): T => ({
        ...prevValues,
        [name]: value,
      })
    );
  };

  const updateTouchedValues = (name: keyof T) => {
    setTouched((prevTouched): TTouched<T> => {
      const tempTouched = prevTouched ? touched : {};
      return {
        ...(tempTouched as TTouched<T>),
        [name]: true,
      };
    });
  };

  const handleOnInputChange = ({ name, value }: IInputChanged<T>): void => {
    updateFieldValues({ name, value });
  };

  const handleOnBlur = ({ name, value }: IInputChanged<T>): void => {
    updateFieldValues({ name, value });
    updateTouchedValues(name);
  };

  const handleOnError = ({ name, error }: IFormFieldError<T>): void => {
    setErrors(() => ({
      ...errors,
      [name]: error,
    }));
  };

  const handleOnResetAllErrors = () => {
    setErrors((): TErrors<T> => ({} as TErrors<T>));
  };

  const handleOnSubmitForm = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (errors) {
      const hasError = Object.values(errors).some((field) => field);
      if (hasError) return;
    }
    onSubmitCb(values);
  };

  const handleOnResetForm = (overrides?: Partial<T>): void => {
    const newValues = {
      ...initialValues,
      ...overrides,
    };

    setTouched((): TTouched<T> => ({} as TTouched<T>));
    setErrors((): TErrors<T> => ({} as TErrors<T>));
    setValues((): T => newValues);
  };

  return {
    errors,
    handleOnBlur,
    handleOnError,
    handleOnInputChange,
    handleOnResetAllErrors,
    handleOnResetForm,
    handleOnSubmitForm,
    touched,
    values,
  };
};
