import tw, { styled } from 'twin.macro';

export const DropzoneWrapper = styled.div`
  ${tw`p-4`};
  .dropzone {
    ${tw`
      text-center
      p-10 mb-7
      border
      border-dashed
      border-gray-400
      rounded
      text-gray-400
      cursor-pointer
    `};
  }
`;

export const ModalButton = styled.button`
  ${tw`
    rounded-md
    border
    border-transparent
    bg-purple-100
    px-6
    py-2
    mr-4
    text-sm
    font-medium
    text-purple-900
    hover:bg-purple-200
    focus:outline-none
    focus-visible:(ring-2 ring-purple-500 ring-offset-2)
  `};
`;
