import { useEffect, ChangeEvent, useState, MouseEvent } from 'react';
import { Popover } from '@headlessui/react';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/20/solid';

import { TTextLocalized, Text } from '@northladder/i18n';

import { TUIError } from '../../../types';

import { CircularLoader } from '../../loaders';

import { ListBoxOptionsWrapper, MultiSelectDropdownWrapper } from '../styles';
import { IPopoverItem } from '../types';

/**
 * It defines the structure of selected items props.
 */
interface IPopoverSelectedItemsProps {
  /**
   * Refers to `IPopoverItem`
   */
  selectedItems: IPopoverItem[];
  /**
   * Callback function for removing the selected items.
   */
  removeSelectedItem: (item: IPopoverItem) => void;
  /**
   * Disabled prop for the selected items button.
   */
  isDisabled: boolean;
}

/**
 * `PopoverSelectedItems` using headless UI to render the selected dropdown
 *  items inside the button and manages the state of the dropdown.
 *
 * @param IPopoverSelectedItemsProps - Refer the type for the props description.
 *
 * @returns JSX.Element
 */
export function PopoverSelectedItems({
  selectedItems,
  removeSelectedItem,
  isDisabled,
}: IPopoverSelectedItemsProps): JSX.Element {
  return (
    <div className="flex flex-grow flex-row flex-wrap">
      {selectedItems.map((item: IPopoverItem) => {
        const { title, id } = item;

        const handleClick = (e: MouseEvent) => {
          e.preventDefault();
          removeSelectedItem(item);
        };

        return (
          <div key={id} className={id === '0' ? 'w-full' : ''}>
            {id === '0' ? (
              <div
                className={`
                  list-box-label hide-button-outline
                  ${isDisabled ? 'disable-label-class' : ''}
                `}
              >
                <Text className="placeholder-class" text={title} />
              </div>
            ) : (
              <label
                className="selected-item group"
                htmlFor={id}
                onClick={handleClick}
                onKeyDown={() => null}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                role="button"
                tabIndex={0}
              >
                <Text text={title} />
                <XMarkIcon aria-hidden="true" className="cross-icon" id={id} />
              </label>
            )}
          </div>
        );
      })}
    </div>
  );
}

/**
 * `IMultiSelectCheckboxProps` refers to the props of `MultiSelectCheckbox`.
 */
interface IMultiSelectCheckboxProps {
  /**
   * Selected checkbox item.
   */
  item: IPopoverItem;
  /**
   * On change event handler for checkbox.
   */
  onCheckBoxSelect: (item: IPopoverItem, checked: boolean) => void;
}

/**
 * @Juned, please add doc comments here
 */
function MultiSelectCheckbox({
  item,
  onCheckBoxSelect,
}: IMultiSelectCheckboxProps) {
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    onCheckBoxSelect(item, event?.target?.checked);
  };

  const { title, checked, id } = item;
  const labelId = `checkbox_${id}`;

  return (
    <div className="dropdown-checkbox-wrapper">
      <input
        aria-describedby="remember"
        checked={checked}
        id={labelId}
        onChange={handleCheckboxChange}
        required
        type="checkbox"
      />
      <label htmlFor={labelId}>
        <Text text={title} />
      </label>
    </div>
  );
}

/**
 * Interface that defines the props for `PopoverMultiSelect`.
 */
export interface IPopoverMultiSelectProps {
  /**
   * The `id` of the underlying component to help accessibility features like
   * focus on pressing/clicking on labels.
   */
  id?: string;
  /**
   * Title representing the items of the multi select dropdown.
   */
  title: string | TTextLocalized;
  /**
   * Array of items of `IPopoverItem`.
   */
  items: IPopoverItem[];
  /**
   * Callback function to handle the functional calls on higher level.
   */
  onPopoverItemSelect: (item: IPopoverItem[]) => void;
  /**
   * For checking if the model items are being fetched from the API.
   */
  isModelsLoading?: boolean;
  /**
   * For capturing the error in populating the dropdown.
   */
  error: TUIError;
  /**
   * For styling the options list in the dropdown.
   */
  className?: string;
  /**
   * To disable the dropdown if category and brandId isn't selected.
   */
  disabled?: boolean;
  /**
   * To display a custom placeholder for the the dropdown.
   */
  placeholder?: TTextLocalized;
  /**
   * To add error styles to the dropdown when no option is selected in the
   * dropdown. (Currently using it in `TradeInDeviceDropdown`).
   */
  errorClassName?: string;
}

/**
 * Customized Simple Multi Select Dropdown using headless UI to render the
 * dynamic values and additional styling attributes
 *
 * @param IPopoverMultiSelectProps - Refer the type for the props description.
 *
 * @returns JSX.Element
 */
export default function PopoverMultiSelect({
  id,
  items,
  onPopoverItemSelect,
  title,
  isModelsLoading,
  error,
  className,
  disabled = true,
  placeholder,
  errorClassName = '',
}: IPopoverMultiSelectProps): JSX.Element {
  const [initialSelectState] = useState({
    id: '0',
    title: placeholder || `Select ${title}`,
    checked: false,
  });
  const [selectedItems, setSelectedItems] = useState<IPopoverItem[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(disabled);

  useEffect(() => {
    if (items && items.length > 0) {
      setIsDisabled(false);
      const filteredItems = items.filter((item: IPopoverItem) => item.checked);
      if (filteredItems.length) {
        setSelectedItems(filteredItems);
      } else {
        setSelectedItems([initialSelectState]);
      }
      return;
    }
    setIsDisabled(true);
    setSelectedItems([initialSelectState]);
  }, [initialSelectState, items]);

  const handleCheckBoxSelect = (item: IPopoverItem, checked: boolean) => {
    const updatedItems = items.map((selectedItem: IPopoverItem) => {
      if (selectedItem.id === item.id) {
        return { ...item, checked };
      }
      return selectedItem;
    });
    const updateSelected = updatedItems.filter((checkbox) => checkbox.checked);
    if (updateSelected.length) {
      setSelectedItems(updateSelected);
    } else {
      setSelectedItems([initialSelectState]);
    }
    onPopoverItemSelect(updatedItems);
  };

  const removeSelectedItem = (item: IPopoverItem) => {
    const filteredList = selectedItems.filter(
      (selected) => selected.id !== item.id
    );
    if (filteredList.length === 0) {
      filteredList.push(initialSelectState);
    }
    setSelectedItems(filteredList);
    const updatedRemovedItems = items.map((listItem) => {
      if (item.id === listItem.id) {
        return { ...item, checked: false };
      }
      return listItem;
    });
    onPopoverItemSelect(updatedRemovedItems);
  };

  return (
    <MultiSelectDropdownWrapper
      className={`popover-wrapper ${errorClassName}`}
      id={id}
    >
      <div className="multi-select-dropdown">
        <Popover className="relative">
          <Popover.Button
            className={`
              multi-select-button border-class
              ${isDisabled && 'select-disabled'}
            `}
            disabled={isDisabled}
          >
            <PopoverSelectedItems
              isDisabled={isDisabled}
              removeSelectedItem={removeSelectedItem}
              selectedItems={selectedItems}
            />
            <div className="hide-button-outline">
              {isModelsLoading ? (
                <CircularLoader className="h-5 w-5" />
              ) : (
                <ChevronDownIcon aria-hidden="true" className="arrow-down" />
              )}
            </div>
          </Popover.Button>
          {error ? (
            <div>
              <Text text={error.message} />
              {error.description ? <Text text={error.description} /> : null}
            </div>
          ) : (
            <Popover.Panel>
              <ListBoxOptionsWrapper className="popover-list">
                <h3 className="popover-label">
                  <Text text={title} />
                </h3>
                <div className={className}>
                  {items.map((item) => (
                    <MultiSelectCheckbox
                      key={item.id}
                      item={item}
                      onCheckBoxSelect={handleCheckBoxSelect}
                    />
                  ))}
                </div>
              </ListBoxOptionsWrapper>
            </Popover.Panel>
          )}
        </Popover>
      </div>
    </MultiSelectDropdownWrapper>
  );
}
