import { TRegionId } from '../environments';

/**
 * Generates a simple Image Data URL for a blurred image to be used as the
 * fallback placeholder.
 *
 * @param `endpoint`
 */
export function getPlaceholderDataImageURL(): string {
  return 'data:image/webp;base64,UklGRvIBAABXRUJQVlA4IOYBAABwDwCdASrIAJYAPp1On04iEZEQJHhYCcS0t3Aam/BRx8UspIDwrbF4a+B/0JoAkdX3YadKoop2uAbV/c5bwpZMRDa3c05NT0L41HWUzAkf8EJOYnLAWEkTUO3VXnKcvV6klxOQqVU2OtAa9hlZTlYVEMovD9MaDq/pTfo5J/Qk1GwMnNAA/vLblD8ptxUnoKpYEZY+9KGKkUSkJ9CHq46zmTkgwvvgKbzb+ZXmlbMOUi4GMGkqPZj0v0f7FIhuRB5BzWfSyLAJHb/r7mGJqQuOPtXGgTmjTw57JzBOhNnC8qbiQz9xOrECGili5EYlxmy1+XAMjybZE3OWZXQK5Dapj3REPOWsp9HmABeVtbv0FM+kJAHEbnjE30vZD5KDzmDX042VWGWMQFumGvt46P/Bboi1mMkaAieSeCEgnOn8BjMCxm0Pd+OiYVKU2G68TW4MYiF2gi7eJGv+Y6JEif+cGcGJ803hgOxp5rLWTi/StX2leh2TCS1+jUjYOvGjg7YdXVcoVq3CS53aRL96eTng4eMMujLvMGN1iOPFrOe0O7WNHRLZEql56yArkdEf9JwBfNPP0SNAKd/E4aTU7yS8qjXM3K6lhGDKXlRXd/I/503VPUEtc8wAzcrIaaxGNQ21incQAAA=';
}

/**
 * This takes in a country code and return the respective base URL to the `S3`
 * buckets where all the country's/region's images are saved.
 *
 * @param `countryCode` - The country code to be used in resolving the base url.
 */
export const getImageAssetsCDNBaseUrl = (countryCode: TRegionId) => {
  switch (countryCode) {
    case 'uae':
      return process?.env?.NEXT_PUBLIC_CDN_S3_BUCKET_URL_UAE;
    case 'ksa':
      return process?.env?.NEXT_PUBLIC_CDN_S3_BUCKET_URL_KSA;
    case 'global':
    default:
      return process?.env?.NEXT_PUBLIC_CDN_S3_BUCKET_URL_GLB;
  }
};
