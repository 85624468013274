import { createZustandStore } from '../../zustand';

import { IRegionState } from '../region/types';

import {
  loadPersistedRegionStore,
  updateActiveRegionCallback,
  updateAppLanguageCallback,
} from './callbacks';

/**
 * This creates a local region state tracker and update callbacks to track the
 * actively selected region.
 *
 * This acts as a fallback when there is no current `activeSession` whose region
 * can be used.
 */
export const useRegionStore = createZustandStore<IRegionState>((set) => {
  const localRegionData = loadPersistedRegionStore();

  return {
    region: localRegionData?.region || null,
    updateActiveRegion: updateActiveRegionCallback(set),
    appLanguage: localRegionData?.appLanguage || 'en',
    updateAppLanguage: updateAppLanguageCallback(set),
  };
});
