import { Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';

/**
 * // TODO: @Rhea Add a callToActions for further interaction and should give
 * user the option to bail out. Create unit tests and storybook stories.
 */
interface IAppModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  title: string;
  description?: string;
  buttonLabel?: string;
  footerComponent?: JSX.Element | null;
  modalChildComponent: JSX.Element;
  /**
   * onClose is used to close the modal on outside events.
   */
  onClose?: () => void;
}

/**
 * -----------------------------------------------------------------------------
 * Reusable Headless UI App Modal.
 *
 * TODO: This is still a WIP so not exposing it to the rest of the DS.
 */
export function AppModal({
  title,
  description = '',
  isOpen,
  setIsOpen,
  onClose,
  buttonLabel,
  footerComponent,
  modalChildComponent,
}: IAppModalProps) {
  const disableOutsideEvents = () => null;
  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={onClose || disableOutsideEvents}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className="w-full max-w-lg transform overflow-hidden rounded-2xl
                  bg-white p-6 text-left align-middle shadow-xl transition-all"
              >
                <Dialog.Title
                  as="h3"
                  className="flex justify-between text-lg font-medium leading-6
                    text-gray-900"
                >
                  {title}
                  <XMarkIcon
                    className="h-6 w-6"
                    onClick={() => setIsOpen(false)}
                  />
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{description}</p>
                </div>

                <div>{modalChildComponent}</div>

                {footerComponent ? <div>{footerComponent}</div> : null}

                {buttonLabel ? (
                  <button
                    className="mt-4 inline-flex justify-center rounded-md
                      border border-transparent bg-purple-100 px-4 py-2 text-sm
                      font-medium text-purple-900 hover:bg-purple-200
                      focus:outline-none focus-visible:ring-2
                      focus-visible:ring-purple-500 focus-visible:ring-offset-2"
                    onClick={() => setIsOpen(false)}
                    type="button"
                  >
                    {buttonLabel}
                  </button>
                ) : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
