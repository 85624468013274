/* global google */

import { useState, useEffect } from 'react';
import {
  useGeocodingService,
  useGoogleMap,
} from '@ubilabs/google-maps-react-hooks';

import { ICoordinate } from '../../types';
import {
  getMapMarkerOptions,
  getLocationInfoFromGeocode,
  IGetDecodedLocationData,
} from '../../utilities';

interface IMapMarkerProps {
  /**
   * The list of locations for the markers to plot on the map.
   */
  location: ICoordinate;
  onUpdateLocation: (location: ICoordinate) => void;
}

/**
 * -----------------------------------------------------------------------------
 * This renders a single marker on the map, allowing support for drag and drop,
 * click and pin and reverse geolocation lookup.
 */
export function MapMarker({ location, onUpdateLocation }: IMapMarkerProps) {
  // Get the global map instance with the useGoogleMap hook
  const map = useGoogleMap();

  // Get the geocoder from the useGeocoder hook
  const geocoder = useGeocodingService();

  const google = window?.google;

  const [marker, setMarker] = useState<google.maps.Marker | null>();
  const [infoWindow, setInfoWindow] =
    useState<google.maps.InfoWindow | null>(null);

  /**
   * This handler is called when google has successfully geodecoded the nearest
   * map location search results from the latitude and longitude provided.
   */
  const handleOnGeodecodeSuccess = ({
    geoCord,
    formattedAddress,
    position,
  }: IGetDecodedLocationData) => {
    if (!map || !marker || !infoWindow) return;
    if (!position || !formattedAddress) return;

    marker.setPosition(position);
    infoWindow.setPosition(position);
    infoWindow.setContent(formattedAddress);
    map.setCenter(position);

    onUpdateLocation({
      ...location,
      ...geoCord,
      title: formattedAddress,
    });
  };

  /**
   * Track events when the map is dragged and dropped.
   * This will get the coordinates of the final drag position point and generate
   * geo info for the popup tooltip.
   */
  const handleOnDragMarker = (event: google.maps.MapMouseEvent) => {
    if (!geocoder || !event.latLng) return;

    getLocationInfoFromGeocode({
      geocoder,
      location: event.latLng,
      onSuccessCb: handleOnGeodecodeSuccess,
    });
    // }
  };

  /**
   * Track events when someone clicks anywhere on the map.
   * This will get the coordinates of the clicked point and generate geo info
   * for the popup tooltip.
   */
  const handleOMapClick = (event: google.maps.MapMouseEvent) => {
    if (!geocoder || !event.latLng) return;

    getLocationInfoFromGeocode({
      geocoder,
      location: event.latLng,
      onSuccessCb: handleOnGeodecodeSuccess,
    });
  };

  /**
   * This effect plots the markers on the map every time the `map` object & the
   * `location` point changes.
   */
  useEffect(() => {
    if (!map) {
      return () => {
        // Do nothing here if there is no map.
      };
    }

    const initialBounds = new google.maps.LatLngBounds();

    const mapMakerOptions = getMapMarkerOptions({ location, map });
    const { position } = mapMakerOptions;

    initialBounds.extend(position);

    const newMarker = new google.maps.Marker(mapMakerOptions);

    setMarker(newMarker);

    /**
     * Add the infoWindow to show the locaiton tooltip above the map.
     */
    const newInfoWindow = new google.maps.InfoWindow({
      ariaLabel: 'Store Location',
      content: mapMakerOptions.title,
      position,
    });

    setInfoWindow(newInfoWindow);

    newInfoWindow.open(map, newMarker);

    // Set the center of the map to fit markers
    map.setCenter(initialBounds.getCenter());

    // Clean up marker if added.
    return () => {
      newInfoWindow?.close();
      newMarker?.setMap(null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, location]);

  /**
   * This effects allows listening to drag and click events on the map, fetches
   * the geo location info for that point and plots it.
   */
  useEffect(() => {
    if (!map || !marker || !infoWindow || !geocoder) {
      return () => {
        // Exit early if any of this is not ready yet.
      };
    }

    /**
     * Track events when the map is dragged and dropped.
     */
    const dragListener = marker.addListener('dragend', handleOnDragMarker);

    /**
     * Tracks clicks on the map and open an infowindow with the reversed
     * geocoded address.
     */
    const clickListener = map.addListener('click', handleOMapClick);

    // Clean up click listener and drag listeners
    return () => {
      google.maps.event.removeListener(dragListener);
      google.maps.event.removeListener(clickListener);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, infoWindow, marker, geocoder]);

  return null;
}
