import { IAddNewSessionOptions, TSetAuthState } from '../types';

import { persistAuthStore } from './authStoreLocalStorageUtils';

/**
 * This adds a new session instance to track across the app.
 */
export function addNewSessionCallback(set: TSetAuthState) {
  let sessionIndex = 0;

  return function updateAuthStore({
    expoNotificationToken,
    identifier,
    initialEnableNotificationModalSeen,
    locale,
    token,
    refreshToken,
    rememberMe,
    regionId,
    regionIconId,
    user,
  }: IAddNewSessionOptions) {
    set(
      ({
        activityLog,
        allSessionsById,
        allSessionsByIndex,
        allSessionsIndices,
      }) => {
        const isSessionExisting = allSessionsById
          ? allSessionsById[user.userId]
          : null;

        const sessionsCount: number = allSessionsIndices.length || 0;
        sessionIndex =
          sessionsCount === 0 ? 1 : allSessionsIndices[sessionsCount - 1] + 1;

        if (isSessionExisting) {
          sessionIndex = isSessionExisting.index;
        }

        const updatedActiveSession = {
          expoNotificationToken,
          initialEnableNotificationModalSeen,
          index: sessionIndex,
          locale,
          // Don't save the fresh token if the user does not want to remember session.
          refreshToken: rememberMe ? refreshToken : '',
          regionIconId,
          regionId,
          rememberMe,
          token,
          user,
          userId: user.userId,
          isExpired: false,
          identifier,
        };

        const updatedAllSessionsIndices = isSessionExisting
          ? [...allSessionsIndices]
          : [...allSessionsIndices, sessionIndex];

        const updatedActivityLog = {
          ...(activityLog || {}),
          regionId,
          lastLoginDate: new Date().getTime(),
        };

        const updatedAllSessionsByIndex = {
          ...(allSessionsByIndex || {}),
          [sessionIndex]: updatedActiveSession,
        };
        const updatedAllSessionsById = {
          ...(allSessionsById || {}),
          [user.userId]: updatedActiveSession,
        };

        const updatedStoreFields = {
          activeSession: updatedActiveSession,
          activityLog: updatedActivityLog,
          allSessionsById: updatedAllSessionsById,
          allSessionsByIndex: updatedAllSessionsByIndex,
          allSessionsIndices: updatedAllSessionsIndices,
        };

        persistAuthStore(updatedStoreFields);

        return updatedStoreFields;
      }
    );

    return sessionIndex;
  };
}
