/**
 * NOTE: This uses repetitive indexing of process.env instead of destructing
 * them out because NextJs looks for the whole thread on the client side.
 * Meaning, dynamic look ups and assigning to variables won't work.
 */

import { isWeb } from '../common';

import { IEnv, TNodeEnv, TInstance, TInstanceName } from './types';

/**
 * This loads the mobile app's environment variables (`DEALER-MOBILE` in this case)
 * and extracts out the regions environments config from the ENV and makes it
 * ready for other parts of the app.
 *
 * It loads the keys from the env and unifies them for region management.
 */
export const getMobileRegionEnvironment = (): IEnv | null => {
  /**
   * For web applications that might accidentally try to load this file, exit
   * early to avoid doing so.
   */
  if (isWeb) return null;

  const Config = require('react-native-config').default;

  const {
    NODE_ENV,
    INSTANCE,
    INSTANCE_NAME,
    MAPBOX_TOKEN,
    GOOGLE_MAPS_API_KEY,
    DEALER_MOBILE_AMPLITUDE_ANALYTICS_API_KEY,
    DEALER_MOBILE_AMPLITUDE_ANALYTICS_VERSION,
    DEALER_MOBILE_DEEPLINK_BASE_URL,

    ADMIN_SERVICE_BASE_URL_UAE,
    ADMIN_SERVICE_BASE_URL_KSA,
    ADMIN_SERVICE_BASE_URL_GLB,
    ADMIN_SERVICE_BASE_URL_PAAS,

    CUSTOMER_SERVICE_BASE_URL_UAE,
    CUSTOMER_SERVICE_BASE_URL_KSA,
    CUSTOMER_SERVICE_BASE_URL_GLB,
    CUSTOMER_SERVICE_BASE_URL_PAAS,

    IDENTITY_SERVICE_BASE_URL_UAE,
    IDENTITY_SERVICE_BASE_URL_KSA,
    IDENTITY_SERVICE_BASE_URL_GLB,
    IDENTITY_SERVICE_BASE_URL_PAAS,

    MEMBER_SERVICE_BASE_URL_UAE,
    MEMBER_SERVICE_BASE_URL_KSA,
    MEMBER_SERVICE_BASE_URL_GLB,
    MEMBER_SERVICE_BASE_URL_PAAS,

    CDN_S3_BUCKET_URL_UAE,
    CDN_S3_BUCKET_URL_KSA,
    CDN_S3_BUCKET_URL_GLB,
    CDN_S3_BUCKET_URL_PAAS,

    ACCOUNT_SERVICE_BASE_URL_UAE,
    ACCOUNT_SERVICE_BASE_URL_KSA,
    ACCOUNT_SERVICE_BASE_URL_GLB,
    ACCOUNT_SERVICE_BASE_URL_PAAS,

    B2B_SERVICE_BASE_URL_UAE,
    B2B_SERVICE_BASE_URL_KSA,
    B2B_SERVICE_BASE_URL_GLB,
    B2B_SERVICE_BASE_URL_PAAS,
  } = Config || {};

  return {
    NODE_ENV: (NODE_ENV as TNodeEnv) || 'development',
    INSTANCE: (INSTANCE as TInstance) || 'ALPHA',
    INSTANCE_NAME: (INSTANCE_NAME as TInstanceName) || 'alpha',
    MAPBOX_TOKEN: MAPBOX_TOKEN || '',
    GOOGLE_MAPS_API_KEY: GOOGLE_MAPS_API_KEY || '',
    DEALER_MOBILE_AMPLITUDE_ANALYTICS_API_KEY:
      DEALER_MOBILE_AMPLITUDE_ANALYTICS_API_KEY || '',
    DEALER_MOBILE_AMPLITUDE_ANALYTICS_VERSION:
      DEALER_MOBILE_AMPLITUDE_ANALYTICS_VERSION || '',
    DEALER_MOBILE_DEEPLINK_BASE_URL: DEALER_MOBILE_DEEPLINK_BASE_URL || '',

    paas: {
      ADMIN_SERVICE_BASE_URL: ADMIN_SERVICE_BASE_URL_PAAS || '',
      CUSTOMER_SERVICE_BASE_URL: CUSTOMER_SERVICE_BASE_URL_PAAS || '',
      IDENTITY_SERVICE_BASE_URL: IDENTITY_SERVICE_BASE_URL_PAAS || '',
      MEMBER_SERVICE_BASE_URL: MEMBER_SERVICE_BASE_URL_PAAS || '',
      CDN_S3_BUCKET_URL: CDN_S3_BUCKET_URL_PAAS || '',
      ACCOUNT_SERVICE_BASE_URL: ACCOUNT_SERVICE_BASE_URL_PAAS || '',
      B2B_SERVICE_BASE_URL: B2B_SERVICE_BASE_URL_PAAS || '',
    },
    ksa: {
      ADMIN_SERVICE_BASE_URL: ADMIN_SERVICE_BASE_URL_KSA || '',
      CUSTOMER_SERVICE_BASE_URL: CUSTOMER_SERVICE_BASE_URL_KSA || '',
      IDENTITY_SERVICE_BASE_URL: IDENTITY_SERVICE_BASE_URL_KSA || '',
      MEMBER_SERVICE_BASE_URL: MEMBER_SERVICE_BASE_URL_KSA || '',
      CDN_S3_BUCKET_URL: CDN_S3_BUCKET_URL_KSA || '',
      ACCOUNT_SERVICE_BASE_URL: ACCOUNT_SERVICE_BASE_URL_KSA || '',
      B2B_SERVICE_BASE_URL: B2B_SERVICE_BASE_URL_KSA || '',
    },
    uae: {
      ADMIN_SERVICE_BASE_URL: ADMIN_SERVICE_BASE_URL_UAE || '',
      CUSTOMER_SERVICE_BASE_URL: CUSTOMER_SERVICE_BASE_URL_UAE || '',
      IDENTITY_SERVICE_BASE_URL: IDENTITY_SERVICE_BASE_URL_UAE || '',
      MEMBER_SERVICE_BASE_URL: MEMBER_SERVICE_BASE_URL_UAE || '',
      CDN_S3_BUCKET_URL: CDN_S3_BUCKET_URL_UAE || '',
      ACCOUNT_SERVICE_BASE_URL: ACCOUNT_SERVICE_BASE_URL_UAE || '',
      B2B_SERVICE_BASE_URL: B2B_SERVICE_BASE_URL_UAE || '',
    },
    global: {
      ADMIN_SERVICE_BASE_URL: ADMIN_SERVICE_BASE_URL_GLB || '',
      CUSTOMER_SERVICE_BASE_URL: CUSTOMER_SERVICE_BASE_URL_GLB || '',
      IDENTITY_SERVICE_BASE_URL: IDENTITY_SERVICE_BASE_URL_GLB || '',
      MEMBER_SERVICE_BASE_URL: MEMBER_SERVICE_BASE_URL_GLB || '',
      CDN_S3_BUCKET_URL: CDN_S3_BUCKET_URL_GLB || '',
      ACCOUNT_SERVICE_BASE_URL: ACCOUNT_SERVICE_BASE_URL_GLB || '',
      B2B_SERVICE_BASE_URL: B2B_SERVICE_BASE_URL_GLB || '',
    },
    iq: {
      ADMIN_SERVICE_BASE_URL: ADMIN_SERVICE_BASE_URL_GLB || '',
      CUSTOMER_SERVICE_BASE_URL: CUSTOMER_SERVICE_BASE_URL_GLB || '',
      IDENTITY_SERVICE_BASE_URL: IDENTITY_SERVICE_BASE_URL_GLB || '',
      MEMBER_SERVICE_BASE_URL: MEMBER_SERVICE_BASE_URL_GLB || '',
      CDN_S3_BUCKET_URL: CDN_S3_BUCKET_URL_GLB || '',
      ACCOUNT_SERVICE_BASE_URL: ACCOUNT_SERVICE_BASE_URL_GLB || '',
      B2B_SERVICE_BASE_URL: B2B_SERVICE_BASE_URL_GLB || '',
    },
  };
};
