import { Text, TTextLocalized } from '@northladder/i18n';

import { ToggleButton, IToggleButtonProps } from '../../form-inputs';

interface IToggleFormFieldProps extends IToggleButtonProps {
  className?: string;
  label: TTextLocalized;
}

/**
 * -----------------------------------------------------------------------------
 * This customizes the the underlying ToggleButton to add additional items
 * for the parent form, like labels.
 */
export function ToggleFormField({
  className = '',
  isActive: toggleStatus,
  label,
  onHandleToggle,
}: IToggleFormFieldProps) {
  return (
    <div className={`flex justify-between text-sm text-gray-500 ${className}`}>
      <Text className="block text-sm text-gray-700" text={label} />
      <span>
        <ToggleButton isActive={toggleStatus} onHandleToggle={onHandleToggle} />
      </span>
    </div>
  );
}
