import { Fragment, useState, useEffect, useCallback } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { Text } from '@northladder/i18n';

import { TUIError } from '../../../types';
import { CircularProgressLoader } from '../../bid-book/components/BidPriceTextField';

import { TMenuItem, IBaseDropdownMenuOptions } from '../types';

import { DropdownWrapper, DropDownMenuItemsWrapper } from '../styles';

/**
 * Type definition for `TDropdownItem` defines the props for `DropDownMenuItem`.
 */
interface IDropDownMenuItemProps {
  /**
   * Item can be referred from `TMenuItem`.
   */
  item: TMenuItem;
  /**
   * This function takes item as prop and works as a callback function for
   * menu item click.
   */
  onDropDownItemSelect: (item: TMenuItem) => void;
}

/**
 *  `DropDownMenuItem` takes item and a handler function to render the menu item
 *  and trigger callback on click of menu item.
 *
 * @param IDropDownMenuItemProps
 *
 * @returns JSX.Element
 */
function DropDownMenuItem({
  item,
  onDropDownItemSelect,
}: IDropDownMenuItemProps): JSX.Element {
  const handleItemSelect = () => onDropDownItemSelect(item);

  return (
    <div className="px-1 py-1">
      <Menu.Item>
        <button
          className="menu-item group"
          onClick={handleItemSelect}
          type="button"
        >
          <Text text={item.title} />
        </button>
      </Menu.Item>
    </div>
  );
}

/**
 * Type definition for `TMenuItems` defines the props for `DropDownMenuItems`.
 */
interface IDropDownMenuItemsProps extends IBaseDropdownMenuOptions {
  // Add more options here
  menuItemsClass?: string;
}

/**
 *  `DropDownMenuItems` takes items and a handler function to render the menu item
 *  list and attach a callback function for each item. It leverages Transition
 *  to add the transition animation effect.
 *
 * @param IDropDownMenuItemsProps
 *
 * @returns JSX.Element
 */
function DropDownMenuItems({
  items,
  menuItemsClass,
  onDropDownItemSelect,
}: IDropDownMenuItemsProps): JSX.Element {
  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <DropDownMenuItemsWrapper>
        <Menu.Items className={menuItemsClass}>
          {items && items.length > 0 ? (
            items.map((item) => (
              <DropDownMenuItem
                key={item.id}
                item={item}
                onDropDownItemSelect={onDropDownItemSelect}
              />
            ))
          ) : (
            <div className="px-1 py-1">
              <Menu.Item>No data available</Menu.Item>
            </div>
          )}
        </Menu.Items>
      </DropDownMenuItemsWrapper>
    </Transition>
  );
}

/**
 * Interface that defines the props for `Dropdown`.
 */
export interface ISimpleDropdownProps extends IBaseDropdownMenuOptions {
  /**
   * Customizable class add to the wrapper.
   */
  className?: string;
  /**
   * Customizable class for the menu items in the dropdown
   */
  menuItemsClassName?: string;
  /**
   * Default title for disabled state dropdown.
   */
  defaultTitle?: string | number;
  /**
   * An optional prop to display the circular loader icon (mainly used in the
   * bid quantity dropdown menu in the bid list)
   */
  loadingState?: boolean;
  /**
   * for disable the dropdown
   */
  isEnabled?: boolean;
  /**
   * Error in the api call.
   */
  error: TUIError;
}

/**
 * Customized SimpleDropdown using headless UI to render the dynamic values and
 * additional styling attributes
 *
 * @param ISimpleDropdownProps - Refer the type for the props description.
 *
 * @returns JSX.Element
 */
export default function SimpleDropdown({
  items,
  onDropDownItemSelect,
  className,
  menuItemsClassName,
  defaultTitle,
  loadingState,
  error,
  isEnabled = true,
}: ISimpleDropdownProps): JSX.Element {
  const [selectedItem, setSelectedItem] = useState<TMenuItem | null>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (items && items.length > 0) {
      setIsDisabled(false);
      setSelectedItem(items[0]);
    }
  }, [items]);

  const handleDropdownItemSelect = useCallback(
    (item: TMenuItem) => {
      onDropDownItemSelect(item);
      setSelectedItem(item);
    },
    [onDropDownItemSelect]
  );

  return (
    <DropdownWrapper className={className}>
      <Menu as="div" className="dropdown-menu">
        <div>
          <Menu.Button
            className="dropdown-button"
            disabled={!isEnabled || isDisabled}
          >
            {selectedItem?.title ? (
              <p>
                <Text text={selectedItem.title} />
              </p>
            ) : (
              <p>{defaultTitle}</p>
            )}
            {loadingState ? (
              <CircularProgressLoader strokecolor="#F59E0B" />
            ) : (
              <ChevronDownIcon aria-hidden="true" className="arrow-down" />
            )}
          </Menu.Button>
        </div>
        {error ? (
          <div>
            <Text text={error.message} />
            {error.description ? <Text text={error.description} /> : null}
          </div>
        ) : (
          <DropDownMenuItems
            items={items}
            menuItemsClass={menuItemsClassName}
            onDropDownItemSelect={handleDropdownItemSelect}
          />
        )}
      </Menu>
    </DropdownWrapper>
  );
}
