import { createZustandStore } from '../../../zustand';

import { IGradeReviewListFilterState } from './types';
import { onClearGradeReviewFilterStoreCallback } from './callbacks';

/**
 * This creates a global grade review filter state tracker and update the filter
 * values.
 */
export const useGradeReviewListFilterStore = createZustandStore<IGradeReviewListFilterState>()(
  (set) => ({
    selectedGradeFilters: [],
    updateSelectedGradeFilters: (filters) => set(() => ({ selectedGradeFilters: filters })),
    clearGradeReviewListFilterStore: onClearGradeReviewFilterStoreCallback(set),
  })
);
