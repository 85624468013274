import { TSvgComponentProps } from './types';

/**
 * -----------------------------------------------------------------------------
 * This renders a rectangular Iraq National Flag SVG component so it can easily be
 * customized at runtime.
 *
 * @param {{size: number}}
 */
export function FlagIraq({ size = 32, ...rest }: TSvgComponentProps) {
  return (
    <svg
      height={size}
      id="FlagIraq"
      viewBox="0 0 512 340"
      width={size * 1.5}
      {...rest}
    >
      <g id="iraq">
        <path
          className="fill-white"
          d="M0 0h512v340H0V0z"
          id="background-white"
        />
        <path
          className="fill-black"
          d="M0 226.7h512V340H0V226.7z"
          id="bottom-black"
        />
        <path
          className={`fill-red-600 ${rest?.className}`}
          d="M0 0h512v113.3H0V0z"
          id="top-red"
        />
        <path
          className="fill-green-700"
          d="M252.7 193.8h-83.4c-.7 5.9-6.7 10.1-13.5 9.6-.7-.1-1.5-.2-2.2-.4 11.7-5.7 10.9-11.5 3.6-24.4 4.1-1.5 4.7-1.8 8.9-4.6-2.9 8.8 7.6 7.8 15.3 7.8 0-3.1.3-6.6-2-7.1 3-1.1 3.3-1.4 8.1-5.5v11.9H245V173c0-.9-.8-1.7-1.7-1.7-1 0-1.7.8-1.7 1.7v4.7c0 .7-.6 1.3-1.3 1.3h-44v-7.7l33-31.8c-.2 1.6 3.2 6 4.6 6.7-1.1.2-2.3 0-3.1.7l-27 26h30c0-6.9 6.5-6.9 9.5-9.4 3 2.4 9.5 2.4 9.5 9.4v20.9zm6.3 0v-54.3c3.1 1.7 5.4 3.6 9.2 4.6-.2 2.1-2.1 2.8-2.1 4.3v33.4c4.2.9 5.2-1.5 7.2-2.7.5 5.3 3.9 10.6 3.8 14.8H259zm56.9-36.2 6.7-5.6v29.2h4.7V148c2.3-1.9 5.3-4 6.7-6.5v52.3h-42c-.6-10.8-.6-21.9 12.1-19.5v-4.4c0-1-1.6-.2-1.6-1.2l8.7-7.2v19.7h4.7v-23.6zm-2.1-15c-.8 0-2.1-4.4-1.8-5.3.3-1 1.4-1 1.9-.5.8.7.7 5.8-.1 5.8zm-7.8 6.1c-2.4-1.4-2-1.9.1-1.3 3.6 1.1 5.4.2 8-2.4l1.9 1c2.5 1.3 4.1.7 5-2.4.3-.9 1-.7 1.2.4.8 4.3-2.5 5.6-5.8 4.4-1.8-.6-2.1-.6-3 .1-2 1.5-4.8 1.7-7.4.2zm34.3 45.1v-54.3c3.1 1.7 5.4 3.6 9.2 4.6-.2 2.1-2.1 2.8-2.1 4.3v33.4c4.2.9 5.2-1.5 7.2-2.7.5 5.3 3.9 10.6 3.8 14.8h-18.1zm-163.4 6c0-1.4 1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5c-1.3.1-2.5-1.1-2.5-2.5zm123.3-19.7c0 .8.9 1.5 1.9 1.5s1.9-.7 1.9-1.5-.9-1.5-1.9-1.5-1.9.7-1.9 1.5z"
          id="text_1_"
        />
      </g>
    </svg>
  );
}
