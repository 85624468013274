import { TSvgComponentProps } from '../../../../types';

/**
 * -----------------------------------------------------------------------------
 * This renders the Flag icon to represent a bid that's in summit.
 *
 * @param TSvgComponentProps
 *
 * @returns JSX.Element
 */
function IconFlag(props: TSvgComponentProps) {
  return (
    <svg
      fill="none"
      height="20px"
      width="40px"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.829 20.768a.756.756 0 0 1-.754-.753V5.697c0-.203.083-.392.219-.535.166-.158 1.1-.972 3.549-.972 1.273 0 2.298.407 3.293.806.904.362 1.748.701 2.735.701 1.84 0 2.495-.543 2.502-.55a.77.77 0 0 1 .814-.128.757.757 0 0 1 .452.678v9.043a.778.778 0 0 1-.218.535c-.166.158-1.1.972-3.55.972-1.273 0-2.298-.407-3.293-.806-.896-.362-1.748-.701-2.735-.701-1.228 0-1.93.24-2.26.407v4.868c0 .414-.34.753-.754.753Zm3.014-7.535c1.273 0 2.298.406 3.293.806.904.362 1.748.7 2.735.7 1.229 0 1.93-.24 2.261-.406V6.91c-.543.166-1.281.294-2.26.294-1.274 0-2.299-.407-3.294-.806-.896-.362-1.748-.7-2.735-.7-1.228-.001-1.93.24-2.26.406v7.423c.542-.166 1.28-.294 2.26-.294Z"
        fill="#F59E0B"
      />
      <path
        clipRule="evenodd"
        d="M3.174 5.036c.199-.19 1.176-1.02 3.67-1.02 1.31 0 2.364.42 3.358.818.908.364 1.722.689 2.67.689 1.654 0 2.302-.447 2.373-.496.007-.005.009-.006.006-.003l.004-.005.005-.004a.944.944 0 0 1 1-.155l.002.001a.931.931 0 0 1 .552.836v9.043a.953.953 0 0 1-.266.655l-.006.006c-.199.19-1.177 1.02-3.67 1.02-1.311 0-2.365-.42-3.358-.818-.9-.364-1.722-.689-2.67-.689-1.091 0-1.747.194-2.087.344v4.757a.93.93 0 0 1-.928.928.93.93 0 0 1-.928-.928V5.697c0-.252.103-.482.267-.655l.006-.006Zm1.409 10.11c.332-.165 1.032-.406 2.26-.406.988 0 1.84.339 2.736.7.995.4 2.02.807 3.293.807 2.45 0 3.384-.814 3.55-.972a.778.778 0 0 0 .218-.535V5.697a.756.756 0 0 0-.452-.678.77.77 0 0 0-.814.128c-.008.008-.663.55-2.502.55-.983 0-1.825-.336-2.725-.696l-.01-.005c-.995-.399-2.02-.806-3.293-.806-2.45 0-3.384.814-3.55.972a.778.778 0 0 0-.218.535v14.318c0 .414.339.753.753.753a.756.756 0 0 0 .754-.753v-4.868Zm4.93-8.586c-.9-.363-1.721-.688-2.67-.688-1.09 0-1.746.194-2.086.343v7.081c.534-.139 1.22-.238 2.087-.238 1.31 0 2.364.42 3.357.82.91.363 1.723.687 2.671.687 1.091 0 1.746-.194 2.086-.343v-7.08c-.533.138-1.219.237-2.086.237-1.311 0-2.365-.42-3.358-.82Zm5.445.4c-.523.141-1.207.244-2.086.244-1.273 0-2.298-.407-3.293-.806-.897-.362-1.748-.7-2.735-.7-1.229 0-1.93.24-2.261.406v7.423a3.61 3.61 0 0 1 .174-.05c.524-.141 1.208-.244 2.087-.244 1.273 0 2.298.407 3.293.806l.01.004c.9.36 1.742.697 2.725.697 1.228 0 1.93-.241 2.26-.407V6.91a5.33 5.33 0 0 1-.174.05Z"
        fill="#F59E0B"
      />
    </svg>
  );
}

export default IconFlag;
