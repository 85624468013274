import { Text, TTextLocalized } from '@northladder/i18n';

export interface IItem {
  label: TTextLocalized;
  value: string | boolean;
}

interface IFieldsSectionProps {
  title?: TTextLocalized;
  items: IItem[];
  className?: string;
}

export function FieldsSection({
  title,
  items,
  className,
}: IFieldsSectionProps) {
  return (
    <div className={`mx-auto mt-6  px-4 sm:px-6 lg:px-8 ${className}`}>
      {title ? (
        <h3
          className="
            truncate border-t border-t-gray-100 py-8 text-xl font-medium
            text-gray-900
          "
          id="store-info"
        >
          <Text text={title} />
        </h3>
      ) : null}

      {items.length > 0 ? (
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          {items.map(({ label, value }) => (
            <div key={label.toString()} className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                <Text text={label} />
              </dt>
              <dd className="mt-1 text-sm text-gray-900">{value}</dd>
            </div>
          ))}
        </dl>
      ) : (
        'No data available!'
      )}
    </div>
  );
}
