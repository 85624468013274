/* global google */

import { ICoordinate, IPosition } from '../types';

interface IGetMapMarkerPlotDetailsProps {
  /**
   * The `latitude`, `longitude`, `zoom` and title/`label` to display on the marker.
   */
  location: ICoordinate;
  /**
   * The map on which to plot the location coordinates.
   */
  map: google.maps.Map;
}

/**
 * This utility provides reusable logic for plotting a marker on the map.
 */
export const getMapMarkerOptions = ({
  location,
  map,
}: IGetMapMarkerPlotDetailsProps) => {
  const { lat, lng, title } = location;

  return {
    map,
    position: { lat, lng },
    title,
    clickable: true,
    draggable: true,
    animation: google.maps.Animation.DROP,
  };
};

export interface IGetDecodedLocationData {
  geoCord: IPosition;
  formattedAddress: string;
  position: google.maps.LatLng;
}

interface IGetLocationInfoFromGeocodeOptions {
  /**
   * The google maps geocoder.
   */
  geocoder: google.maps.Geocoder;
  /**
   * The location info `(Lat/Lng)` to decode from.
   */
  location: google.maps.LatLng | google.maps.LatLngLiteral;
  onErrorCb?: (error: string) => void;
  /**
   * Function called when the geocoding is successful. Provides the position,
   * formatted address/name, and simple latitude and longitude from the first
   * location point that matches.
   */
  onSuccessCb: (decodedLocation: IGetDecodedLocationData) => void;
}

/**
 * This utility decodes/fetches details from a map pin/marker location.
 * It use the geocoder to reverse geocode the position from the map
 * and adds the address as content of the infowindow
 */
export const getLocationInfoFromGeocode = ({
  geocoder,
  location,
  onErrorCb,
  onSuccessCb,
}: IGetLocationInfoFromGeocodeOptions) => {
  geocoder.geocode(
    { location },
    (
      results: google.maps.GeocoderResult[] | null,
      status: google.maps.GeocoderStatus
    ) => {
      if (status !== 'OK' || !results) {
        onErrorCb?.(`Sorry, Geocoding was not successful. \n${status}`);

        return;
      }

      const geoLocationResult = results[0];

      const formattedAddress = geoLocationResult.formatted_address;
      const position = geoLocationResult.geometry.location;
      const geoCord = {
        lat: position?.lat() as number,
        lng: position?.lng() as number,
      };

      onSuccessCb({
        position,
        geoCord,
        formattedAddress,
      });
    }
  );
};
